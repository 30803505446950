"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Telephone = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../base-service");
var utils_1 = require("../utils");
var Telephone = /** @class */ (function (_super) {
    tslib_1.__extends(Telephone, _super);
    function Telephone() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getToken = function (payload) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = '/capability_tokens';
                        return [4 /*yield*/, this.api.post(url, payload)];
                    case 1:
                        response = _a.sent();
                        if (response.ok) {
                            return [2 /*return*/, response.data];
                        }
                        else {
                            throw new utils_1.ApiRequestError(response.status, response.problem, url, response.data);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    return Telephone;
}(base_service_1.BaseService));
exports.Telephone = Telephone;
