"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Emergency Room
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MembersApiAxiosParamCreator = exports.LiveblocksApi = exports.LiveblocksApiFactory = exports.LiveblocksApiFp = exports.LiveblocksApiAxiosParamCreator = exports.EventApi = exports.EventApiFactory = exports.EventApiFp = exports.EventApiAxiosParamCreator = exports.EpisodeApi = exports.EpisodeApiFactory = exports.EpisodeApiFp = exports.EpisodeApiAxiosParamCreator = exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = exports.AppDataApi = exports.AppDataApiFactory = exports.AppDataApiFp = exports.AppDataApiAxiosParamCreator = exports.TaskStatus = exports.TaskPriority = exports.SubscriptionStatus = exports.SortOrderEnum = exports.Sex = exports.ReminderType = exports.ReminderTagType = exports.ReminderStatus = exports.QuestionnaireType = exports.QuestionnaireLanguage = exports.QuestionnairePropertyTypeEnum = exports.ProfileSetupStatus = exports.PractitionerStatus = exports.PharmacyType = exports.MemberPropertyType = exports.MedicationsPropertyPropertyTypeEnum = exports.MedicationStatus = exports.MedicalHistoryPropertyPropertyTypeEnum = exports.MattermostRetractionMessagePropsSenderEnum = exports.MattermostNewEnrichedMessageEventTypeEnum = exports.LocationUpdateEventTypeEnum = exports.GenderIdentity = exports.EpisodeState = exports.EncounterStatus = exports.CommunicationMethod = exports.CommentingStatus = exports.BotStatus = exports.AllergiesPropertyPropertyTypeEnum = exports.ActivityType = void 0;
exports.WebhookApi = exports.WebhookApiFactory = exports.WebhookApiFp = exports.WebhookApiAxiosParamCreator = exports.TeamApi = exports.TeamApiFactory = exports.TeamApiFp = exports.TeamApiAxiosParamCreator = exports.TasksApi = exports.TasksApiFactory = exports.TasksApiFp = exports.TasksApiAxiosParamCreator = exports.ReminderApi = exports.ReminderApiFactory = exports.ReminderApiFp = exports.ReminderApiAxiosParamCreator = exports.PractitionerApi = exports.PractitionerApiFactory = exports.PractitionerApiFp = exports.PractitionerApiAxiosParamCreator = exports.PharmaciesApi = exports.PharmaciesApiFactory = exports.PharmaciesApiFp = exports.PharmaciesApiAxiosParamCreator = exports.PatientApi = exports.PatientApiFactory = exports.PatientApiFp = exports.PatientApiAxiosParamCreator = exports.NoteApi = exports.NoteApiFactory = exports.NoteApiFp = exports.NoteApiAxiosParamCreator = exports.MembersApi = exports.MembersApiFactory = exports.MembersApiFp = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.ActivityType = {
    CREATED: 'CREATED',
    UPDATED: 'UPDATED',
    MARKED_AS_COMPLETED: 'MARKED_AS_COMPLETED',
    MARKED_AS_TO_DO: 'MARKED_AS_TO_DO'
};
exports.AllergiesPropertyPropertyTypeEnum = {
    allergies: 'allergies'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.BotStatus = {
    active: 'active',
    paused: 'paused',
    inactive: 'inactive'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.CommentingStatus = {
    open: 'open',
    closed: 'closed'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.CommunicationMethod = {
    chat: 'chat',
    audio: 'audio',
    video: 'video',
    ccq: 'ccq'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.EncounterStatus = {
    planned: 'planned',
    created: 'created',
    requested: 'requested',
    inProgress: 'inProgress',
    review: 'review',
    completed: 'completed',
    cancelled: 'cancelled'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.EpisodeState = {
    active: 'active',
    pending: 'pending',
    resolved: 'resolved',
    snoozed: 'snoozed',
    archived: 'archived'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.GenderIdentity = {
    female: 'female',
    i_dont_know: 'i_dont_know',
    male: 'male',
    non_binary: 'non_binary',
    prefer_not_to_disclose: 'prefer_not_to_disclose',
    prefer_to_self_identify: 'prefer_to_self_identify',
    two_spirit: 'two_spirit'
};
exports.LocationUpdateEventTypeEnum = {
    patient_location_updated: 'patient-location-updated'
};
exports.MattermostNewEnrichedMessageEventTypeEnum = {
    mattermost_new_enriched_message: 'mattermost-new-enriched-message'
};
exports.MattermostRetractionMessagePropsSenderEnum = {
    message_retraction: 'message-retraction'
};
exports.MedicalHistoryPropertyPropertyTypeEnum = {
    medical_history: 'medical_history'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.MedicationStatus = {
    active: 'active',
    inactive: 'inactive',
    stopped: 'stopped',
    cancelled: 'cancelled'
};
exports.MedicationsPropertyPropertyTypeEnum = {
    medications: 'medications'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.MemberPropertyType = {
    allergies: 'allergies',
    medical_history: 'medical_history',
    medications: 'medications',
    questionnaires: 'questionnaires'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.PharmacyType = {
    pharmacy: 'pharmacy',
    epharmacy: 'epharmacy'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.PractitionerStatus = {
    no_status: 'no_status',
    ready: 'ready',
    break: 'break',
    admin: 'admin',
    on_audio_video_call: 'on_audio_video_call',
    on_chat: 'on_chat'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.ProfileSetupStatus = {
    complete: 'complete',
    prefilled: 'prefilled'
};
exports.QuestionnairePropertyTypeEnum = {
    questionnaires: 'questionnaires'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.QuestionnaireLanguage = {
    en: 'en',
    fr: 'fr'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.QuestionnaireType = {
    PHQ9: 'PHQ9',
    PHQ4: 'PHQ4',
    GAD7: 'GAD7',
    WBI: 'WBI',
    CAGE_AID: 'CAGE-AID',
    WSAS: 'WSAS',
    STRESS_ASSESSMENT: 'STRESS_ASSESSMENT',
    SMOKING_HABITS: 'SMOKING_HABITS'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.ReminderStatus = {
    completed: 'completed',
    incomplete: 'incomplete',
    cancelled: 'cancelled'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.ReminderTagType = {
    practitioner: 'practitioner',
    automated: 'automated'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.ReminderType = {
    practitioner: 'practitioner',
    patient: 'patient'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.Sex = {
    F: 'F',
    M: 'M'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.SortOrderEnum = {
    asc: 'asc',
    desc: 'desc'
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.SubscriptionStatus = {
    watched: 'watched',
    unwatched: 'unwatched'
};
/**
 * An enumeration.
 * @export
 * @enum {number}
 */
exports.TaskPriority = {
    URGENT: 1,
    FLAGGED: 2,
    NONE: 3
};
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.TaskStatus = {
    TO_DO: 'TO_DO',
    DONE: 'DONE'
};
/**
 * AppDataApi - axios parameter creator
 * @export
 */
var AppDataApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Get allowed values for episode properties for a given tenant.
         * @summary Get Episode Properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeProperties: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/app-data/episode-properties";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get allowed values for episode properties for a given tenant.
         * @summary Get Episode Properties
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getEpisodeProperties: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/episode-properties";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get all practitioner specialization options for a given tenant.
         * @summary Get Practitioner Specialization Options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitionerSpecializationOptions: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/app-data/practitioner-specializations";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get allowed values for practitioner status.  Note: this data is currently not tenantized.
         * @summary Get Practitioner Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitionerStatuses: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/app-data/practitioner-status";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AppDataApiAxiosParamCreator = AppDataApiAxiosParamCreator;
/**
 * AppDataApi - functional programming interface
 * @export
 */
var AppDataApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AppDataApiAxiosParamCreator)(configuration);
    return {
        /**
         * Get allowed values for episode properties for a given tenant.
         * @summary Get Episode Properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeProperties: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.episodeProperties(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['AppDataApi.episodeProperties']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get allowed values for episode properties for a given tenant.
         * @summary Get Episode Properties
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getEpisodeProperties: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getEpisodeProperties(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['AppDataApi.getEpisodeProperties']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get all practitioner specialization options for a given tenant.
         * @summary Get Practitioner Specialization Options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitionerSpecializationOptions: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPractitionerSpecializationOptions(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['AppDataApi.getPractitionerSpecializationOptions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get allowed values for practitioner status.  Note: this data is currently not tenantized.
         * @summary Get Practitioner Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitionerStatuses: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPractitionerStatuses(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['AppDataApi.getPractitionerStatuses']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.AppDataApiFp = AppDataApiFp;
/**
 * AppDataApi - factory interface
 * @export
 */
var AppDataApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AppDataApiFp)(configuration);
    return {
        /**
         * Get allowed values for episode properties for a given tenant.
         * @summary Get Episode Properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeProperties: function (options) {
            return localVarFp.episodeProperties(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get allowed values for episode properties for a given tenant.
         * @summary Get Episode Properties
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getEpisodeProperties: function (options) {
            return localVarFp.getEpisodeProperties(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get all practitioner specialization options for a given tenant.
         * @summary Get Practitioner Specialization Options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitionerSpecializationOptions: function (options) {
            return localVarFp.getPractitionerSpecializationOptions(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get allowed values for practitioner status.  Note: this data is currently not tenantized.
         * @summary Get Practitioner Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitionerStatuses: function (options) {
            return localVarFp.getPractitionerStatuses(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AppDataApiFactory = AppDataApiFactory;
/**
 * AppDataApi - object-oriented interface
 * @export
 * @class AppDataApi
 * @extends {BaseAPI}
 */
var AppDataApi = /** @class */ (function (_super) {
    __extends(AppDataApi, _super);
    function AppDataApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get allowed values for episode properties for a given tenant.
     * @summary Get Episode Properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppDataApi
     */
    AppDataApi.prototype.episodeProperties = function (options) {
        var _this = this;
        return (0, exports.AppDataApiFp)(this.configuration).episodeProperties(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get allowed values for episode properties for a given tenant.
     * @summary Get Episode Properties
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AppDataApi
     */
    AppDataApi.prototype.getEpisodeProperties = function (options) {
        var _this = this;
        return (0, exports.AppDataApiFp)(this.configuration).getEpisodeProperties(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get all practitioner specialization options for a given tenant.
     * @summary Get Practitioner Specialization Options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppDataApi
     */
    AppDataApi.prototype.getPractitionerSpecializationOptions = function (options) {
        var _this = this;
        return (0, exports.AppDataApiFp)(this.configuration).getPractitionerSpecializationOptions(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get allowed values for practitioner status.  Note: this data is currently not tenantized.
     * @summary Get Practitioner Statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppDataApi
     */
    AppDataApi.prototype.getPractitionerStatuses = function (options) {
        var _this = this;
        return (0, exports.AppDataApiFp)(this.configuration).getPractitionerStatuses(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AppDataApi;
}(base_1.BaseAPI));
exports.AppDataApi = AppDataApi;
/**
 * DefaultApi - axios parameter creator
 * @export
 */
var DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/health";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.DefaultApiAxiosParamCreator = DefaultApiAxiosParamCreator;
/**
 * DefaultApi - functional programming interface
 * @export
 */
var DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DefaultApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.health(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['DefaultApi.health']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.DefaultApiFp = DefaultApiFp;
/**
 * DefaultApi - factory interface
 * @export
 */
var DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DefaultApiFp)(configuration);
    return {
        /**
         *
         * @summary Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: function (options) {
            return localVarFp.health(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DefaultApiFactory = DefaultApiFactory;
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.health = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).health(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
/**
 * EpisodeApi - axios parameter creator
 * @export
 */
var EpisodeApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Archive an episode.  This attempts to delete the episode\'s Mattermost channel. If successful, the episode is archived; otherwise, this raises the error.
         * @summary Archive Episode
         * @param {string} episodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveEpisode: function (episodeId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1], args_1, true), void 0, function (episodeId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('archiveEpisode', 'episodeId', episodeId);
                            localVarPath = "/v1/episodes/{episode_id}/archive"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Assign an episode to a practitioner.  If the assignment is successful: - the episode\'s state is changed to `active` - the practitioner\'s status is changed to `assigned` - the practitioner is granted access to the member records     of 1) the episode owner and 2) the episode subject (if different)
         * @summary Assign Episode
         * @param {string} episodeId
         * @param {BodyAssignEpisode} bodyAssignEpisode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEpisode: function (episodeId_1, bodyAssignEpisode_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1, bodyAssignEpisode_1], args_1, true), void 0, function (episodeId, bodyAssignEpisode, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('assignEpisode', 'episodeId', episodeId);
                            // verify required parameter 'bodyAssignEpisode' is not null or undefined
                            (0, common_1.assertParamExists)('assignEpisode', 'bodyAssignEpisode', bodyAssignEpisode);
                            localVarPath = "/v1/episodes/{episode_id}/assignee"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(bodyAssignEpisode, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Dispatches an episode to a queue and (optional) next action.  On successful dispatch: - the episode\'s status is set to `active` - the episode is unassigned (only if it has changed queues)
         * @summary Dispatch Episode To Queue
         * @param {string} episodeId
         * @param {DispatchEpisodeBody} dispatchEpisodeBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dispatchEpisodeToQueue: function (episodeId_1, dispatchEpisodeBody_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1, dispatchEpisodeBody_1], args_1, true), void 0, function (episodeId, dispatchEpisodeBody, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('dispatchEpisodeToQueue', 'episodeId', episodeId);
                            // verify required parameter 'dispatchEpisodeBody' is not null or undefined
                            (0, common_1.assertParamExists)('dispatchEpisodeToQueue', 'dispatchEpisodeBody', dispatchEpisodeBody);
                            localVarPath = "/v1/episodes/{episode_id}/team"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(dispatchEpisodeBody, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get allowed values for episode properties for a given tenant.
         * @summary Get Episode Properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeProperties: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/app-data/episode-properties";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get an episode.
         * @summary Get Episode
         * @param {string} episodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisode: function (episodeId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1], args_1, true), void 0, function (episodeId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('getEpisode', 'episodeId', episodeId);
                            localVarPath = "/v1/episodes/{episode_id}"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List reminders for an episode.  Reminders with `\"cancelled\"` status or `\"practitioner\"` type are excluded.  The reminders in the response are ordered by due date (oldest first).
         * @summary Get Episode Reminders
         * @param {string} episodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodeReminders: function (episodeId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1], args_1, true), void 0, function (episodeId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('getEpisodeReminders', 'episodeId', episodeId);
                            localVarPath = "/v1/episodes/{episode_id}/reminders"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get episodes for a specified state.  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get Episodes
         * @param {EpisodeState} state
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {boolean} [resolvedByMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodes: function (state_1, offset_1, limit_1, resolvedByMe_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([state_1, offset_1, limit_1, resolvedByMe_1], args_1, true), void 0, function (state, offset, limit, resolvedByMe, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'state' is not null or undefined
                            (0, common_1.assertParamExists)('getEpisodes', 'state', state);
                            localVarPath = "/v1/episodes";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (state !== undefined) {
                                localVarQueryParameter['state'] = state;
                            }
                            if (resolvedByMe !== undefined) {
                                localVarQueryParameter['resolved_by_me'] = resolvedByMe;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Add the requestor-physician to the episode\'s Mattermost channel.
         * @summary Join Episode
         * @param {string} episodeId
         * @param {string} authorization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinEpisode: function (episodeId_1, authorization_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1, authorization_1], args_1, true), void 0, function (episodeId, authorization, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('joinEpisode', 'episodeId', episodeId);
                            // verify required parameter 'authorization' is not null or undefined
                            (0, common_1.assertParamExists)('joinEpisode', 'authorization', authorization);
                            localVarPath = "/v1/episodes/{episode_id}/join"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Send an access request for an episode for the requestor-physician.
         * @summary Request Episode Access
         * @param {BodyRequestEpisodeAccess} bodyRequestEpisodeAccess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEpisodeAccess: function (bodyRequestEpisodeAccess_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([bodyRequestEpisodeAccess_1], args_1, true), void 0, function (bodyRequestEpisodeAccess, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'bodyRequestEpisodeAccess' is not null or undefined
                            (0, common_1.assertParamExists)('requestEpisodeAccess', 'bodyRequestEpisodeAccess', bodyRequestEpisodeAccess);
                            localVarPath = "/v1/episodes/request_access";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(bodyRequestEpisodeAccess, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Unassign an episode.
         * @summary Unassign Episode
         * @param {string} episodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignEpisode: function (episodeId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1], args_1, true), void 0, function (episodeId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('unassignEpisode', 'episodeId', episodeId);
                            localVarPath = "/v1/episodes/{episode_id}/assignee"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update the bot status for an episode.
         * @summary Update Episode Bot Status
         * @param {string} episodeId
         * @param {BodyUpdateEpisodeBotStatus} bodyUpdateEpisodeBotStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeBotStatus: function (episodeId_1, bodyUpdateEpisodeBotStatus_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1, bodyUpdateEpisodeBotStatus_1], args_1, true), void 0, function (episodeId, bodyUpdateEpisodeBotStatus, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('updateEpisodeBotStatus', 'episodeId', episodeId);
                            // verify required parameter 'bodyUpdateEpisodeBotStatus' is not null or undefined
                            (0, common_1.assertParamExists)('updateEpisodeBotStatus', 'bodyUpdateEpisodeBotStatus', bodyUpdateEpisodeBotStatus);
                            localVarPath = "/v1/episodes/{episode_id}/bot_status"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(bodyUpdateEpisodeBotStatus, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update the communication channel for an episode.
         * @summary Update Episode Communication Channel
         * @param {string} episodeId
         * @param {BodyUpdateEpisodeCommunicationChannel} bodyUpdateEpisodeCommunicationChannel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeCommunicationChannel: function (episodeId_1, bodyUpdateEpisodeCommunicationChannel_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1, bodyUpdateEpisodeCommunicationChannel_1], args_1, true), void 0, function (episodeId, bodyUpdateEpisodeCommunicationChannel, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('updateEpisodeCommunicationChannel', 'episodeId', episodeId);
                            // verify required parameter 'bodyUpdateEpisodeCommunicationChannel' is not null or undefined
                            (0, common_1.assertParamExists)('updateEpisodeCommunicationChannel', 'bodyUpdateEpisodeCommunicationChannel', bodyUpdateEpisodeCommunicationChannel);
                            localVarPath = "/v1/episodes/{episode_id}/communication_channel"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(bodyUpdateEpisodeCommunicationChannel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update properties for an episode.
         * @summary Update Episode Properties
         * @param {string} episodeId
         * @param {UpdateEpisodeProperties} updateEpisodeProperties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeProperties: function (episodeId_1, updateEpisodeProperties_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1, updateEpisodeProperties_1], args_1, true), void 0, function (episodeId, updateEpisodeProperties, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('updateEpisodeProperties', 'episodeId', episodeId);
                            // verify required parameter 'updateEpisodeProperties' is not null or undefined
                            (0, common_1.assertParamExists)('updateEpisodeProperties', 'updateEpisodeProperties', updateEpisodeProperties);
                            localVarPath = "/v1/episodes/{episode_id}/properties"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateEpisodeProperties, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update the state of an episode. An optional outcome_id can be provided.
         * @summary Update Episode State
         * @param {string} episodeId
         * @param {BodyUpdateEpisodeState} bodyUpdateEpisodeState
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeState: function (episodeId_1, bodyUpdateEpisodeState_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1, bodyUpdateEpisodeState_1], args_1, true), void 0, function (episodeId, bodyUpdateEpisodeState, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('updateEpisodeState', 'episodeId', episodeId);
                            // verify required parameter 'bodyUpdateEpisodeState' is not null or undefined
                            (0, common_1.assertParamExists)('updateEpisodeState', 'bodyUpdateEpisodeState', bodyUpdateEpisodeState);
                            localVarPath = "/v1/episodes/{episode_id}/state"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(bodyUpdateEpisodeState, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update the subject ID for an episode.
         * @summary Update Episode Subject
         * @param {string} episodeId
         * @param {BodyUpdateEpisodeSubject} bodyUpdateEpisodeSubject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeSubject: function (episodeId_1, bodyUpdateEpisodeSubject_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1, bodyUpdateEpisodeSubject_1], args_1, true), void 0, function (episodeId, bodyUpdateEpisodeSubject, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('updateEpisodeSubject', 'episodeId', episodeId);
                            // verify required parameter 'bodyUpdateEpisodeSubject' is not null or undefined
                            (0, common_1.assertParamExists)('updateEpisodeSubject', 'bodyUpdateEpisodeSubject', bodyUpdateEpisodeSubject);
                            localVarPath = "/v1/episodes/{episode_id}/subject"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(bodyUpdateEpisodeSubject, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update the title of an episode.
         * @summary Update Episode Title
         * @param {string} episodeId
         * @param {BodyUpdateEpisodeTitle} bodyUpdateEpisodeTitle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeTitle: function (episodeId_1, bodyUpdateEpisodeTitle_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1, bodyUpdateEpisodeTitle_1], args_1, true), void 0, function (episodeId, bodyUpdateEpisodeTitle, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('updateEpisodeTitle', 'episodeId', episodeId);
                            // verify required parameter 'bodyUpdateEpisodeTitle' is not null or undefined
                            (0, common_1.assertParamExists)('updateEpisodeTitle', 'bodyUpdateEpisodeTitle', bodyUpdateEpisodeTitle);
                            localVarPath = "/v1/episodes/{episode_id}/title"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(bodyUpdateEpisodeTitle, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.EpisodeApiAxiosParamCreator = EpisodeApiAxiosParamCreator;
/**
 * EpisodeApi - functional programming interface
 * @export
 */
var EpisodeApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.EpisodeApiAxiosParamCreator)(configuration);
    return {
        /**
         * Archive an episode.  This attempts to delete the episode\'s Mattermost channel. If successful, the episode is archived; otherwise, this raises the error.
         * @summary Archive Episode
         * @param {string} episodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveEpisode: function (episodeId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.archiveEpisode(episodeId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.archiveEpisode']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Assign an episode to a practitioner.  If the assignment is successful: - the episode\'s state is changed to `active` - the practitioner\'s status is changed to `assigned` - the practitioner is granted access to the member records     of 1) the episode owner and 2) the episode subject (if different)
         * @summary Assign Episode
         * @param {string} episodeId
         * @param {BodyAssignEpisode} bodyAssignEpisode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEpisode: function (episodeId, bodyAssignEpisode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.assignEpisode(episodeId, bodyAssignEpisode, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.assignEpisode']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Dispatches an episode to a queue and (optional) next action.  On successful dispatch: - the episode\'s status is set to `active` - the episode is unassigned (only if it has changed queues)
         * @summary Dispatch Episode To Queue
         * @param {string} episodeId
         * @param {DispatchEpisodeBody} dispatchEpisodeBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dispatchEpisodeToQueue: function (episodeId, dispatchEpisodeBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.dispatchEpisodeToQueue(episodeId, dispatchEpisodeBody, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.dispatchEpisodeToQueue']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get allowed values for episode properties for a given tenant.
         * @summary Get Episode Properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeProperties: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.episodeProperties(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.episodeProperties']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get an episode.
         * @summary Get Episode
         * @param {string} episodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisode: function (episodeId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getEpisode(episodeId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.getEpisode']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List reminders for an episode.  Reminders with `\"cancelled\"` status or `\"practitioner\"` type are excluded.  The reminders in the response are ordered by due date (oldest first).
         * @summary Get Episode Reminders
         * @param {string} episodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodeReminders: function (episodeId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getEpisodeReminders(episodeId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.getEpisodeReminders']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get episodes for a specified state.  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get Episodes
         * @param {EpisodeState} state
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {boolean} [resolvedByMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodes: function (state, offset, limit, resolvedByMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getEpisodes(state, offset, limit, resolvedByMe, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.getEpisodes']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Add the requestor-physician to the episode\'s Mattermost channel.
         * @summary Join Episode
         * @param {string} episodeId
         * @param {string} authorization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinEpisode: function (episodeId, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.joinEpisode(episodeId, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.joinEpisode']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Send an access request for an episode for the requestor-physician.
         * @summary Request Episode Access
         * @param {BodyRequestEpisodeAccess} bodyRequestEpisodeAccess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEpisodeAccess: function (bodyRequestEpisodeAccess, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.requestEpisodeAccess(bodyRequestEpisodeAccess, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.requestEpisodeAccess']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Unassign an episode.
         * @summary Unassign Episode
         * @param {string} episodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignEpisode: function (episodeId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.unassignEpisode(episodeId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.unassignEpisode']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update the bot status for an episode.
         * @summary Update Episode Bot Status
         * @param {string} episodeId
         * @param {BodyUpdateEpisodeBotStatus} bodyUpdateEpisodeBotStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeBotStatus: function (episodeId, bodyUpdateEpisodeBotStatus, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEpisodeBotStatus(episodeId, bodyUpdateEpisodeBotStatus, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.updateEpisodeBotStatus']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update the communication channel for an episode.
         * @summary Update Episode Communication Channel
         * @param {string} episodeId
         * @param {BodyUpdateEpisodeCommunicationChannel} bodyUpdateEpisodeCommunicationChannel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeCommunicationChannel: function (episodeId, bodyUpdateEpisodeCommunicationChannel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEpisodeCommunicationChannel(episodeId, bodyUpdateEpisodeCommunicationChannel, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.updateEpisodeCommunicationChannel']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update properties for an episode.
         * @summary Update Episode Properties
         * @param {string} episodeId
         * @param {UpdateEpisodeProperties} updateEpisodeProperties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeProperties: function (episodeId, updateEpisodeProperties, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEpisodeProperties(episodeId, updateEpisodeProperties, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.updateEpisodeProperties']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update the state of an episode. An optional outcome_id can be provided.
         * @summary Update Episode State
         * @param {string} episodeId
         * @param {BodyUpdateEpisodeState} bodyUpdateEpisodeState
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeState: function (episodeId, bodyUpdateEpisodeState, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEpisodeState(episodeId, bodyUpdateEpisodeState, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.updateEpisodeState']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update the subject ID for an episode.
         * @summary Update Episode Subject
         * @param {string} episodeId
         * @param {BodyUpdateEpisodeSubject} bodyUpdateEpisodeSubject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeSubject: function (episodeId, bodyUpdateEpisodeSubject, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEpisodeSubject(episodeId, bodyUpdateEpisodeSubject, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.updateEpisodeSubject']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update the title of an episode.
         * @summary Update Episode Title
         * @param {string} episodeId
         * @param {BodyUpdateEpisodeTitle} bodyUpdateEpisodeTitle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeTitle: function (episodeId, bodyUpdateEpisodeTitle, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEpisodeTitle(episodeId, bodyUpdateEpisodeTitle, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EpisodeApi.updateEpisodeTitle']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.EpisodeApiFp = EpisodeApiFp;
/**
 * EpisodeApi - factory interface
 * @export
 */
var EpisodeApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.EpisodeApiFp)(configuration);
    return {
        /**
         * Archive an episode.  This attempts to delete the episode\'s Mattermost channel. If successful, the episode is archived; otherwise, this raises the error.
         * @summary Archive Episode
         * @param {EpisodeApiArchiveEpisodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveEpisode: function (requestParameters, options) {
            return localVarFp.archiveEpisode(requestParameters.episodeId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Assign an episode to a practitioner.  If the assignment is successful: - the episode\'s state is changed to `active` - the practitioner\'s status is changed to `assigned` - the practitioner is granted access to the member records     of 1) the episode owner and 2) the episode subject (if different)
         * @summary Assign Episode
         * @param {EpisodeApiAssignEpisodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEpisode: function (requestParameters, options) {
            return localVarFp.assignEpisode(requestParameters.episodeId, requestParameters.bodyAssignEpisode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Dispatches an episode to a queue and (optional) next action.  On successful dispatch: - the episode\'s status is set to `active` - the episode is unassigned (only if it has changed queues)
         * @summary Dispatch Episode To Queue
         * @param {EpisodeApiDispatchEpisodeToQueueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dispatchEpisodeToQueue: function (requestParameters, options) {
            return localVarFp.dispatchEpisodeToQueue(requestParameters.episodeId, requestParameters.dispatchEpisodeBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get allowed values for episode properties for a given tenant.
         * @summary Get Episode Properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeProperties: function (options) {
            return localVarFp.episodeProperties(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get an episode.
         * @summary Get Episode
         * @param {EpisodeApiGetEpisodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisode: function (requestParameters, options) {
            return localVarFp.getEpisode(requestParameters.episodeId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List reminders for an episode.  Reminders with `\"cancelled\"` status or `\"practitioner\"` type are excluded.  The reminders in the response are ordered by due date (oldest first).
         * @summary Get Episode Reminders
         * @param {EpisodeApiGetEpisodeRemindersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodeReminders: function (requestParameters, options) {
            return localVarFp.getEpisodeReminders(requestParameters.episodeId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get episodes for a specified state.  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get Episodes
         * @param {EpisodeApiGetEpisodesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodes: function (requestParameters, options) {
            return localVarFp.getEpisodes(requestParameters.state, requestParameters.offset, requestParameters.limit, requestParameters.resolvedByMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Add the requestor-physician to the episode\'s Mattermost channel.
         * @summary Join Episode
         * @param {EpisodeApiJoinEpisodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinEpisode: function (requestParameters, options) {
            return localVarFp.joinEpisode(requestParameters.episodeId, requestParameters.authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Send an access request for an episode for the requestor-physician.
         * @summary Request Episode Access
         * @param {EpisodeApiRequestEpisodeAccessRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEpisodeAccess: function (requestParameters, options) {
            return localVarFp.requestEpisodeAccess(requestParameters.bodyRequestEpisodeAccess, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Unassign an episode.
         * @summary Unassign Episode
         * @param {EpisodeApiUnassignEpisodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignEpisode: function (requestParameters, options) {
            return localVarFp.unassignEpisode(requestParameters.episodeId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update the bot status for an episode.
         * @summary Update Episode Bot Status
         * @param {EpisodeApiUpdateEpisodeBotStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeBotStatus: function (requestParameters, options) {
            return localVarFp.updateEpisodeBotStatus(requestParameters.episodeId, requestParameters.bodyUpdateEpisodeBotStatus, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update the communication channel for an episode.
         * @summary Update Episode Communication Channel
         * @param {EpisodeApiUpdateEpisodeCommunicationChannelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeCommunicationChannel: function (requestParameters, options) {
            return localVarFp.updateEpisodeCommunicationChannel(requestParameters.episodeId, requestParameters.bodyUpdateEpisodeCommunicationChannel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update properties for an episode.
         * @summary Update Episode Properties
         * @param {EpisodeApiUpdateEpisodePropertiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeProperties: function (requestParameters, options) {
            return localVarFp.updateEpisodeProperties(requestParameters.episodeId, requestParameters.updateEpisodeProperties, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update the state of an episode. An optional outcome_id can be provided.
         * @summary Update Episode State
         * @param {EpisodeApiUpdateEpisodeStateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeState: function (requestParameters, options) {
            return localVarFp.updateEpisodeState(requestParameters.episodeId, requestParameters.bodyUpdateEpisodeState, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update the subject ID for an episode.
         * @summary Update Episode Subject
         * @param {EpisodeApiUpdateEpisodeSubjectRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeSubject: function (requestParameters, options) {
            return localVarFp.updateEpisodeSubject(requestParameters.episodeId, requestParameters.bodyUpdateEpisodeSubject, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update the title of an episode.
         * @summary Update Episode Title
         * @param {EpisodeApiUpdateEpisodeTitleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpisodeTitle: function (requestParameters, options) {
            return localVarFp.updateEpisodeTitle(requestParameters.episodeId, requestParameters.bodyUpdateEpisodeTitle, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.EpisodeApiFactory = EpisodeApiFactory;
/**
 * EpisodeApi - object-oriented interface
 * @export
 * @class EpisodeApi
 * @extends {BaseAPI}
 */
var EpisodeApi = /** @class */ (function (_super) {
    __extends(EpisodeApi, _super);
    function EpisodeApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Archive an episode.  This attempts to delete the episode\'s Mattermost channel. If successful, the episode is archived; otherwise, this raises the error.
     * @summary Archive Episode
     * @param {EpisodeApiArchiveEpisodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.archiveEpisode = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).archiveEpisode(requestParameters.episodeId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Assign an episode to a practitioner.  If the assignment is successful: - the episode\'s state is changed to `active` - the practitioner\'s status is changed to `assigned` - the practitioner is granted access to the member records     of 1) the episode owner and 2) the episode subject (if different)
     * @summary Assign Episode
     * @param {EpisodeApiAssignEpisodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.assignEpisode = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).assignEpisode(requestParameters.episodeId, requestParameters.bodyAssignEpisode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Dispatches an episode to a queue and (optional) next action.  On successful dispatch: - the episode\'s status is set to `active` - the episode is unassigned (only if it has changed queues)
     * @summary Dispatch Episode To Queue
     * @param {EpisodeApiDispatchEpisodeToQueueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.dispatchEpisodeToQueue = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).dispatchEpisodeToQueue(requestParameters.episodeId, requestParameters.dispatchEpisodeBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get allowed values for episode properties for a given tenant.
     * @summary Get Episode Properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.episodeProperties = function (options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).episodeProperties(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get an episode.
     * @summary Get Episode
     * @param {EpisodeApiGetEpisodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.getEpisode = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).getEpisode(requestParameters.episodeId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List reminders for an episode.  Reminders with `\"cancelled\"` status or `\"practitioner\"` type are excluded.  The reminders in the response are ordered by due date (oldest first).
     * @summary Get Episode Reminders
     * @param {EpisodeApiGetEpisodeRemindersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.getEpisodeReminders = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).getEpisodeReminders(requestParameters.episodeId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get episodes for a specified state.  Paging is available through the query parameters \'offset\' and \'limit\'.
     * @summary Get Episodes
     * @param {EpisodeApiGetEpisodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.getEpisodes = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).getEpisodes(requestParameters.state, requestParameters.offset, requestParameters.limit, requestParameters.resolvedByMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Add the requestor-physician to the episode\'s Mattermost channel.
     * @summary Join Episode
     * @param {EpisodeApiJoinEpisodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.joinEpisode = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).joinEpisode(requestParameters.episodeId, requestParameters.authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Send an access request for an episode for the requestor-physician.
     * @summary Request Episode Access
     * @param {EpisodeApiRequestEpisodeAccessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.requestEpisodeAccess = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).requestEpisodeAccess(requestParameters.bodyRequestEpisodeAccess, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Unassign an episode.
     * @summary Unassign Episode
     * @param {EpisodeApiUnassignEpisodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.unassignEpisode = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).unassignEpisode(requestParameters.episodeId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update the bot status for an episode.
     * @summary Update Episode Bot Status
     * @param {EpisodeApiUpdateEpisodeBotStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.updateEpisodeBotStatus = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).updateEpisodeBotStatus(requestParameters.episodeId, requestParameters.bodyUpdateEpisodeBotStatus, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update the communication channel for an episode.
     * @summary Update Episode Communication Channel
     * @param {EpisodeApiUpdateEpisodeCommunicationChannelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.updateEpisodeCommunicationChannel = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).updateEpisodeCommunicationChannel(requestParameters.episodeId, requestParameters.bodyUpdateEpisodeCommunicationChannel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update properties for an episode.
     * @summary Update Episode Properties
     * @param {EpisodeApiUpdateEpisodePropertiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.updateEpisodeProperties = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).updateEpisodeProperties(requestParameters.episodeId, requestParameters.updateEpisodeProperties, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update the state of an episode. An optional outcome_id can be provided.
     * @summary Update Episode State
     * @param {EpisodeApiUpdateEpisodeStateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.updateEpisodeState = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).updateEpisodeState(requestParameters.episodeId, requestParameters.bodyUpdateEpisodeState, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update the subject ID for an episode.
     * @summary Update Episode Subject
     * @param {EpisodeApiUpdateEpisodeSubjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.updateEpisodeSubject = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).updateEpisodeSubject(requestParameters.episodeId, requestParameters.bodyUpdateEpisodeSubject, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update the title of an episode.
     * @summary Update Episode Title
     * @param {EpisodeApiUpdateEpisodeTitleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    EpisodeApi.prototype.updateEpisodeTitle = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EpisodeApiFp)(this.configuration).updateEpisodeTitle(requestParameters.episodeId, requestParameters.bodyUpdateEpisodeTitle, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return EpisodeApi;
}(base_1.BaseAPI));
exports.EpisodeApi = EpisodeApi;
/**
 * EventApi - axios parameter creator
 * @export
 */
var EventApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Webhook that receives and processes events.
         * @summary Process Event
         * @param {Event} event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processEvent: function (event_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([event_1], args_1, true), void 0, function (event, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'event' is not null or undefined
                            (0, common_1.assertParamExists)('processEvent', 'event', event);
                            localVarPath = "/v1/events";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(event, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.EventApiAxiosParamCreator = EventApiAxiosParamCreator;
/**
 * EventApi - functional programming interface
 * @export
 */
var EventApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.EventApiAxiosParamCreator)(configuration);
    return {
        /**
         * Webhook that receives and processes events.
         * @summary Process Event
         * @param {Event} event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processEvent: function (event, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.processEvent(event, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['EventApi.processEvent']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.EventApiFp = EventApiFp;
/**
 * EventApi - factory interface
 * @export
 */
var EventApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.EventApiFp)(configuration);
    return {
        /**
         * Webhook that receives and processes events.
         * @summary Process Event
         * @param {EventApiProcessEventRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processEvent: function (requestParameters, options) {
            return localVarFp.processEvent(requestParameters.event, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.EventApiFactory = EventApiFactory;
/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
var EventApi = /** @class */ (function (_super) {
    __extends(EventApi, _super);
    function EventApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Webhook that receives and processes events.
     * @summary Process Event
     * @param {EventApiProcessEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    EventApi.prototype.processEvent = function (requestParameters, options) {
        var _this = this;
        return (0, exports.EventApiFp)(this.configuration).processEvent(requestParameters.event, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return EventApi;
}(base_1.BaseAPI));
exports.EventApi = EventApi;
/**
 * LiveblocksApi - axios parameter creator
 * @export
 */
var LiveblocksApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create a Liveblocks token for a room.
         * @summary Create Liveblocks Token
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLiveblocksToken: function (roomId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([roomId_1], args_1, true), void 0, function (roomId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'roomId' is not null or undefined
                            (0, common_1.assertParamExists)('createLiveblocksToken', 'roomId', roomId);
                            localVarPath = "/v1/liveblocks/token/{room_id}"
                                .replace("{".concat("room_id", "}"), encodeURIComponent(String(roomId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.LiveblocksApiAxiosParamCreator = LiveblocksApiAxiosParamCreator;
/**
 * LiveblocksApi - functional programming interface
 * @export
 */
var LiveblocksApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.LiveblocksApiAxiosParamCreator)(configuration);
    return {
        /**
         * Create a Liveblocks token for a room.
         * @summary Create Liveblocks Token
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLiveblocksToken: function (roomId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createLiveblocksToken(roomId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['LiveblocksApi.createLiveblocksToken']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.LiveblocksApiFp = LiveblocksApiFp;
/**
 * LiveblocksApi - factory interface
 * @export
 */
var LiveblocksApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.LiveblocksApiFp)(configuration);
    return {
        /**
         * Create a Liveblocks token for a room.
         * @summary Create Liveblocks Token
         * @param {LiveblocksApiCreateLiveblocksTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLiveblocksToken: function (requestParameters, options) {
            return localVarFp.createLiveblocksToken(requestParameters.roomId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.LiveblocksApiFactory = LiveblocksApiFactory;
/**
 * LiveblocksApi - object-oriented interface
 * @export
 * @class LiveblocksApi
 * @extends {BaseAPI}
 */
var LiveblocksApi = /** @class */ (function (_super) {
    __extends(LiveblocksApi, _super);
    function LiveblocksApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create a Liveblocks token for a room.
     * @summary Create Liveblocks Token
     * @param {LiveblocksApiCreateLiveblocksTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveblocksApi
     */
    LiveblocksApi.prototype.createLiveblocksToken = function (requestParameters, options) {
        var _this = this;
        return (0, exports.LiveblocksApiFp)(this.configuration).createLiveblocksToken(requestParameters.roomId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return LiveblocksApi;
}(base_1.BaseAPI));
exports.LiveblocksApi = LiveblocksApi;
/**
 * MembersApi - axios parameter creator
 * @export
 */
var MembersApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create a new admin note for a member.
         * @summary Add Admin Note
         * @param {number} patientId
         * @param {NewAdminNote} newAdminNote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminNote: function (patientId_1, newAdminNote_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, newAdminNote_1], args_1, true), void 0, function (patientId, newAdminNote, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('addAdminNote', 'patientId', patientId);
                            // verify required parameter 'newAdminNote' is not null or undefined
                            (0, common_1.assertParamExists)('addAdminNote', 'newAdminNote', newAdminNote);
                            localVarPath = "/v1/members/{patient_id}/member_notes"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newAdminNote, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create multiple admin notes for a member.
         * @summary Add Admin Notes
         * @param {number} patientId
         * @param {Array<NewAdminNote>} newAdminNote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminNotes: function (patientId_1, newAdminNote_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, newAdminNote_1], args_1, true), void 0, function (patientId, newAdminNote, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('addAdminNotes', 'patientId', patientId);
                            // verify required parameter 'newAdminNote' is not null or undefined
                            (0, common_1.assertParamExists)('addAdminNotes', 'newAdminNote', newAdminNote);
                            localVarPath = "/v1/members/{patient_id}/member_notes/batch"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newAdminNote, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a new allergies property.
         * @summary Add Allergies Property
         * @param {number} patientId
         * @param {NewAllergiesProperty} newAllergiesProperty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAllergiesProperty: function (patientId_1, newAllergiesProperty_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, newAllergiesProperty_1], args_1, true), void 0, function (patientId, newAllergiesProperty, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('addAllergiesProperty', 'patientId', patientId);
                            // verify required parameter 'newAllergiesProperty' is not null or undefined
                            (0, common_1.assertParamExists)('addAllergiesProperty', 'newAllergiesProperty', newAllergiesProperty);
                            localVarPath = "/v1/members/{patient_id}/properties/allergies"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newAllergiesProperty, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create new health profile attributes.
         * @summary Add Health Profile Attributes
         * @param {number} patientId
         * @param {NewHealthProfileAttributes} newHealthProfileAttributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHealthProfileAttributes: function (patientId_1, newHealthProfileAttributes_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, newHealthProfileAttributes_1], args_1, true), void 0, function (patientId, newHealthProfileAttributes, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('addHealthProfileAttributes', 'patientId', patientId);
                            // verify required parameter 'newHealthProfileAttributes' is not null or undefined
                            (0, common_1.assertParamExists)('addHealthProfileAttributes', 'newHealthProfileAttributes', newHealthProfileAttributes);
                            localVarPath = "/v1/members/{patient_id}/health_profile/attributes"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newHealthProfileAttributes, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a new medical history property.
         * @summary Add Medical History Property
         * @param {number} patientId
         * @param {NewMedicalHistoryProperty} newMedicalHistoryProperty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedicalHistoryProperty: function (patientId_1, newMedicalHistoryProperty_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, newMedicalHistoryProperty_1], args_1, true), void 0, function (patientId, newMedicalHistoryProperty, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('addMedicalHistoryProperty', 'patientId', patientId);
                            // verify required parameter 'newMedicalHistoryProperty' is not null or undefined
                            (0, common_1.assertParamExists)('addMedicalHistoryProperty', 'newMedicalHistoryProperty', newMedicalHistoryProperty);
                            localVarPath = "/v1/members/{patient_id}/properties/medical_history"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newMedicalHistoryProperty, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a new medications property.
         * @summary Add Medications Property
         * @param {number} patientId
         * @param {NewMedicationsProperty} newMedicationsProperty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedicationsProperty: function (patientId_1, newMedicationsProperty_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, newMedicationsProperty_1], args_1, true), void 0, function (patientId, newMedicationsProperty, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('addMedicationsProperty', 'patientId', patientId);
                            // verify required parameter 'newMedicationsProperty' is not null or undefined
                            (0, common_1.assertParamExists)('addMedicationsProperty', 'newMedicationsProperty', newMedicationsProperty);
                            localVarPath = "/v1/members/{patient_id}/properties/medications"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newMedicationsProperty, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a new structured allergy.
         * @summary Add Structured Allergy
         * @param {number} patientId
         * @param {NewStructuredAllergy} newStructuredAllergy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredAllergy: function (patientId_1, newStructuredAllergy_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, newStructuredAllergy_1], args_1, true), void 0, function (patientId, newStructuredAllergy, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('addStructuredAllergy', 'patientId', patientId);
                            // verify required parameter 'newStructuredAllergy' is not null or undefined
                            (0, common_1.assertParamExists)('addStructuredAllergy', 'newStructuredAllergy', newStructuredAllergy);
                            localVarPath = "/v1/members/{patient_id}/structured_allergies"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newStructuredAllergy, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Add Structured Allergy Changelog
         * @param {string} allergyId
         * @param {number} patientId
         * @param {NewStructuredAllergyLog} newStructuredAllergyLog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredAllergyChangelog: function (allergyId_1, patientId_1, newStructuredAllergyLog_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([allergyId_1, patientId_1, newStructuredAllergyLog_1], args_1, true), void 0, function (allergyId, patientId, newStructuredAllergyLog, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'allergyId' is not null or undefined
                            (0, common_1.assertParamExists)('addStructuredAllergyChangelog', 'allergyId', allergyId);
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('addStructuredAllergyChangelog', 'patientId', patientId);
                            // verify required parameter 'newStructuredAllergyLog' is not null or undefined
                            (0, common_1.assertParamExists)('addStructuredAllergyChangelog', 'newStructuredAllergyLog', newStructuredAllergyLog);
                            localVarPath = "/v1/members/{patient_id}/structured_allergies/{allergy_id}/changelog"
                                .replace("{".concat("allergy_id", "}"), encodeURIComponent(String(allergyId)))
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newStructuredAllergyLog, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a new structured medication.
         * @summary Add Structured Medication
         * @param {number} patientId
         * @param {NewStructuredMedication} newStructuredMedication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredMedication: function (patientId_1, newStructuredMedication_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, newStructuredMedication_1], args_1, true), void 0, function (patientId, newStructuredMedication, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('addStructuredMedication', 'patientId', patientId);
                            // verify required parameter 'newStructuredMedication' is not null or undefined
                            (0, common_1.assertParamExists)('addStructuredMedication', 'newStructuredMedication', newStructuredMedication);
                            localVarPath = "/v1/members/{patient_id}/structured_medications"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newStructuredMedication, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Bulk-insert a list of structured medications.  As the endpoint only accepts machine requests (for now), the payload is restricted to prescribed medications.  This is also used for migration from IH, where the created_at/updated_at dates can be specified, and an additional JSON blob can be stored that contains the full IH model (including data that has no mapping to structured medications in the CP).  The endpoint will only insert new structured medications, and will ignore any that already exist (same tenant_id, member_id, medication_name, created_at) if `allow_duplicates` is false.  De-duplication is against the database - duplicates in the supplied data will not be filtered out.
         * @summary Add Structured Medications Batch
         * @param {number} patientId
         * @param {Array<NewStructuredMedication>} newStructuredMedication
         * @param {boolean} [allowDuplicates] A medication is duplicate if it has the same name,         member_id, tenant_id and created_at date. Set this to false if such         duplicates should be ignored.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredMedicationsBatch: function (patientId_1, newStructuredMedication_1, allowDuplicates_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, newStructuredMedication_1, allowDuplicates_1], args_1, true), void 0, function (patientId, newStructuredMedication, allowDuplicates, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('addStructuredMedicationsBatch', 'patientId', patientId);
                            // verify required parameter 'newStructuredMedication' is not null or undefined
                            (0, common_1.assertParamExists)('addStructuredMedicationsBatch', 'newStructuredMedication', newStructuredMedication);
                            localVarPath = "/v1/members/{patient_id}/structured_medications/batch"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (allowDuplicates !== undefined) {
                                localVarQueryParameter['allow_duplicates'] = allowDuplicates;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newStructuredMedication, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Insert multiple properties for a member.  (To be used for IH historical data migration.)  Inserts multiple properties for a member. The properties are grouped by type and inserted into the corresponding property type table. If a property already exists (based on tenant x patient x created_at), it is ignored.  This is pretty convoluted, because of how the patient property code was factored for SQLA v1-style ORM.
         * @summary Batch Insert Member Properties
         * @param {number} patientId
         * @param {NewMemberProperties} newMemberProperties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchInsertMemberProperties: function (patientId_1, newMemberProperties_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, newMemberProperties_1], args_1, true), void 0, function (patientId, newMemberProperties, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('batchInsertMemberProperties', 'patientId', patientId);
                            // verify required parameter 'newMemberProperties' is not null or undefined
                            (0, common_1.assertParamExists)('batchInsertMemberProperties', 'newMemberProperties', newMemberProperties);
                            localVarPath = "/v1/members/{patient_id}/properties"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newMemberProperties, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a questionnaire for a member.  A request with the `er:create:questionnaire` scope can create a questionnaire for any member.
         * @summary Create Questionnaire
         * @param {number} patientId
         * @param {NewQuestionnaire} newQuestionnaire
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnaire: function (patientId_1, newQuestionnaire_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, newQuestionnaire_1], args_1, true), void 0, function (patientId, newQuestionnaire, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('createQuestionnaire', 'patientId', patientId);
                            // verify required parameter 'newQuestionnaire' is not null or undefined
                            (0, common_1.assertParamExists)('createQuestionnaire', 'newQuestionnaire', newQuestionnaire);
                            localVarPath = "/v1/members/{patient_id}/properties/questionnaires"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newQuestionnaire, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete an admin note for a member.
         * @summary Delete Admin Note
         * @param {string} memberNoteId ID of the member note
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminNote: function (memberNoteId_1, patientId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([memberNoteId_1, patientId_1], args_1, true), void 0, function (memberNoteId, patientId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'memberNoteId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteAdminNote', 'memberNoteId', memberNoteId);
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteAdminNote', 'patientId', patientId);
                            localVarPath = "/v1/members/{patient_id}/member_notes/{member_note_id}"
                                .replace("{".concat("member_note_id", "}"), encodeURIComponent(String(memberNoteId)))
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get all admin notes for a member (excluding soft-deleted notes).  The notes in the response are ordered by:  - priority (`important` first)  - then, recently created  - then, recently updated
         * @summary Get Admin Notes
         * @param {number} patientId
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminNotes: function (patientId_1, offset_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, offset_1, limit_1], args_1, true), void 0, function (patientId, offset, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getAdminNotes', 'patientId', patientId);
                            localVarPath = "/v1/members/{patient_id}/member_notes"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get the health profile of a member by returning the most recent value for each attribute type.
         * @summary Get Health Profile
         * @param {number} patientId
         * @param {Array<string>} [types] Filter by attribute type. e.g. \&#39;?types&#x3D;some-int-attribute-type&amp;types&#x3D;some-str-attribute-type\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthProfile: function (patientId_1, types_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, types_1], args_1, true), void 0, function (patientId, types, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getHealthProfile', 'patientId', patientId);
                            localVarPath = "/v1/members/{patient_id}/health_profile"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (types) {
                                localVarQueryParameter['types'] = Array.from(types);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all properties for a member.  Returns a paginated list of properties for a member. The list can be filtered by property type and date range (created_before and created_after).  The \'latest\' query parameter can be used to fetch only the up-to-date properties by only returning the latest created item, for each property type.  The list is sorted by creation date, with the most recent items first. It contains the following different types of properties: Questionnaires, Allergies, Medications, and Medical History.
         * @summary Get Member Properties
         * @param {number} patientId
         * @param {Array<MemberPropertyType>} [type] Filter by property type. Can be \&#39;questionnaire\&#39;, \&#39;allergy\&#39;, \&#39;medication\&#39;, or \&#39;medical_history\&#39;. Supports repeated values, e.g. \&#39;?type&#x3D;medications&amp;type&#x3D;allergy\&#39;
         * @param {string} [createdAfter] Only return items created after this datetime (inclusive).
         * @param {string} [createdBefore] Only return items created before this datetime (inclusive).
         * @param {boolean} [latest] Only return the latest item of each property type.  (based on creation date)
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberProperties: function (patientId_1, type_1, createdAfter_1, createdBefore_1, latest_1, offset_1, limit_1) {
            var args_1 = [];
            for (var _i = 7; _i < arguments.length; _i++) {
                args_1[_i - 7] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, type_1, createdAfter_1, createdBefore_1, latest_1, offset_1, limit_1], args_1, true), void 0, function (patientId, type, createdAfter, createdBefore, latest, offset, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getMemberProperties', 'patientId', patientId);
                            localVarPath = "/v1/members/{patient_id}/properties"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (type) {
                                localVarQueryParameter['type'] = type;
                            }
                            if (createdAfter !== undefined) {
                                localVarQueryParameter['created_after'] = (createdAfter instanceof Date) ?
                                    createdAfter.toISOString() :
                                    createdAfter;
                            }
                            if (createdBefore !== undefined) {
                                localVarQueryParameter['created_before'] = (createdBefore instanceof Date) ?
                                    createdBefore.toISOString() :
                                    createdBefore;
                            }
                            if (latest !== undefined) {
                                localVarQueryParameter['latest'] = latest;
                            }
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all questionnaires submitted for a member.  The questionnaires in the response are ordered by their create date from newest to oldest.  A request with the `patient` role can access their own questionnaire. A request with er:list:questionnaires scope can access questionnaires of any member.
         * @summary Get Questionnaires
         * @param {number} patientId
         * @param {QuestionnaireType} [type]
         * @param {string} [createdAfter] Only return items created after this datetime (inclusive).
         * @param {string} [createdBefore] Only return items created before this datetime (inclusive).
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaires: function (patientId_1, type_1, createdAfter_1, createdBefore_1, offset_1, limit_1) {
            var args_1 = [];
            for (var _i = 6; _i < arguments.length; _i++) {
                args_1[_i - 6] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, type_1, createdAfter_1, createdBefore_1, offset_1, limit_1], args_1, true), void 0, function (patientId, type, createdAfter, createdBefore, offset, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getQuestionnaires', 'patientId', patientId);
                            localVarPath = "/v1/members/{patient_id}/properties/questionnaires"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (type !== undefined) {
                                localVarQueryParameter['type'] = type;
                            }
                            if (createdAfter !== undefined) {
                                localVarQueryParameter['created_after'] = (createdAfter instanceof Date) ?
                                    createdAfter.toISOString() :
                                    createdAfter;
                            }
                            if (createdBefore !== undefined) {
                                localVarQueryParameter['created_before'] = (createdBefore instanceof Date) ?
                                    createdBefore.toISOString() :
                                    createdBefore;
                            }
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get all structured medications for a member.
         * @summary Get Structured Medications
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructuredMedications: function (patientId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1], args_1, true), void 0, function (patientId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getStructuredMedications', 'patientId', patientId);
                            localVarPath = "/v1/members/{patient_id}/structured_medications"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List structured medications for a member\'s prescription.
         * @summary Get Structured Medications By Prescription Id
         * @param {number} prescriptionId
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructuredMedicationsByPrescriptionID: function (prescriptionId_1, patientId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([prescriptionId_1, patientId_1], args_1, true), void 0, function (prescriptionId, patientId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'prescriptionId' is not null or undefined
                            (0, common_1.assertParamExists)('getStructuredMedicationsByPrescriptionID', 'prescriptionId', prescriptionId);
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getStructuredMedicationsByPrescriptionID', 'patientId', patientId);
                            localVarPath = "/v1/members/{patient_id}/structured_medications/prescription/{prescription_id}"
                                .replace("{".concat("prescription_id", "}"), encodeURIComponent(String(prescriptionId)))
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Upsert a Patient user record.
         * @summary Login Member
         * @param {LoginMember} loginMember
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginMember: function (loginMember_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([loginMember_1], args_1, true), void 0, function (loginMember, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'loginMember' is not null or undefined
                            (0, common_1.assertParamExists)('loginMember', 'loginMember', loginMember);
                            localVarPath = "/v1/members/login";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(loginMember, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update an admin note for a member.  Only the fields that are provided in the request body will be updated.
         * @summary Update Admin Note
         * @param {string} memberNoteId ID of the member note
         * @param {number} patientId
         * @param {PartialNewAdminNote} partialNewAdminNote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminNote: function (memberNoteId_1, patientId_1, partialNewAdminNote_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([memberNoteId_1, patientId_1, partialNewAdminNote_1], args_1, true), void 0, function (memberNoteId, patientId, partialNewAdminNote, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'memberNoteId' is not null or undefined
                            (0, common_1.assertParamExists)('updateAdminNote', 'memberNoteId', memberNoteId);
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('updateAdminNote', 'patientId', patientId);
                            // verify required parameter 'partialNewAdminNote' is not null or undefined
                            (0, common_1.assertParamExists)('updateAdminNote', 'partialNewAdminNote', partialNewAdminNote);
                            localVarPath = "/v1/members/{patient_id}/member_notes/{member_note_id}"
                                .replace("{".concat("member_note_id", "}"), encodeURIComponent(String(memberNoteId)))
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(partialNewAdminNote, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Updates a structured medication for a member.  For prescribed medications, only status-related fields can be updated:  - `is_stopped`  - `is_cancelled`  - `ended_at`  If any other fields are passed, the update will be rejected.
         * @summary Update Structured Medication
         * @param {string} structuredMedicationId
         * @param {number} patientId
         * @param {UpdateStructuredMedication} updateStructuredMedication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStructuredMedication: function (structuredMedicationId_1, patientId_1, updateStructuredMedication_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([structuredMedicationId_1, patientId_1, updateStructuredMedication_1], args_1, true), void 0, function (structuredMedicationId, patientId, updateStructuredMedication, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'structuredMedicationId' is not null or undefined
                            (0, common_1.assertParamExists)('updateStructuredMedication', 'structuredMedicationId', structuredMedicationId);
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('updateStructuredMedication', 'patientId', patientId);
                            // verify required parameter 'updateStructuredMedication' is not null or undefined
                            (0, common_1.assertParamExists)('updateStructuredMedication', 'updateStructuredMedication', updateStructuredMedication);
                            localVarPath = "/v1/members/{patient_id}/structured_medications/{structured_medication_id}"
                                .replace("{".concat("structured_medication_id", "}"), encodeURIComponent(String(structuredMedicationId)))
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateStructuredMedication, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Updates all structured medications for a member\'s prescription.
         * @summary Update Structured Medications By Prescription Id
         * @param {number} prescriptionId
         * @param {number} patientId
         * @param {UpdatePrescribedMedication} updatePrescribedMedication
         * @param {number} [onBehalfOf] The ID of the user the operation is done on behalf of. This is ignored if a user ID is present in claims.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStructuredMedicationsByPrescriptionID: function (prescriptionId_1, patientId_1, updatePrescribedMedication_1, onBehalfOf_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([prescriptionId_1, patientId_1, updatePrescribedMedication_1, onBehalfOf_1], args_1, true), void 0, function (prescriptionId, patientId, updatePrescribedMedication, onBehalfOf, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'prescriptionId' is not null or undefined
                            (0, common_1.assertParamExists)('updateStructuredMedicationsByPrescriptionID', 'prescriptionId', prescriptionId);
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('updateStructuredMedicationsByPrescriptionID', 'patientId', patientId);
                            // verify required parameter 'updatePrescribedMedication' is not null or undefined
                            (0, common_1.assertParamExists)('updateStructuredMedicationsByPrescriptionID', 'updatePrescribedMedication', updatePrescribedMedication);
                            localVarPath = "/v1/members/{patient_id}/structured_medications/prescription/{prescription_id}"
                                .replace("{".concat("prescription_id", "}"), encodeURIComponent(String(prescriptionId)))
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (onBehalfOf !== undefined) {
                                localVarQueryParameter['on_behalf_of'] = onBehalfOf;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePrescribedMedication, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.MembersApiAxiosParamCreator = MembersApiAxiosParamCreator;
/**
 * MembersApi - functional programming interface
 * @export
 */
var MembersApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.MembersApiAxiosParamCreator)(configuration);
    return {
        /**
         * Create a new admin note for a member.
         * @summary Add Admin Note
         * @param {number} patientId
         * @param {NewAdminNote} newAdminNote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminNote: function (patientId, newAdminNote, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addAdminNote(patientId, newAdminNote, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.addAdminNote']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Create multiple admin notes for a member.
         * @summary Add Admin Notes
         * @param {number} patientId
         * @param {Array<NewAdminNote>} newAdminNote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminNotes: function (patientId, newAdminNote, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addAdminNotes(patientId, newAdminNote, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.addAdminNotes']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Create a new allergies property.
         * @summary Add Allergies Property
         * @param {number} patientId
         * @param {NewAllergiesProperty} newAllergiesProperty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAllergiesProperty: function (patientId, newAllergiesProperty, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addAllergiesProperty(patientId, newAllergiesProperty, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.addAllergiesProperty']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Create new health profile attributes.
         * @summary Add Health Profile Attributes
         * @param {number} patientId
         * @param {NewHealthProfileAttributes} newHealthProfileAttributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHealthProfileAttributes: function (patientId, newHealthProfileAttributes, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addHealthProfileAttributes(patientId, newHealthProfileAttributes, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.addHealthProfileAttributes']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Create a new medical history property.
         * @summary Add Medical History Property
         * @param {number} patientId
         * @param {NewMedicalHistoryProperty} newMedicalHistoryProperty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedicalHistoryProperty: function (patientId, newMedicalHistoryProperty, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addMedicalHistoryProperty(patientId, newMedicalHistoryProperty, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.addMedicalHistoryProperty']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Create a new medications property.
         * @summary Add Medications Property
         * @param {number} patientId
         * @param {NewMedicationsProperty} newMedicationsProperty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedicationsProperty: function (patientId, newMedicationsProperty, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addMedicationsProperty(patientId, newMedicationsProperty, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.addMedicationsProperty']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Create a new structured allergy.
         * @summary Add Structured Allergy
         * @param {number} patientId
         * @param {NewStructuredAllergy} newStructuredAllergy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredAllergy: function (patientId, newStructuredAllergy, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addStructuredAllergy(patientId, newStructuredAllergy, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.addStructuredAllergy']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Add Structured Allergy Changelog
         * @param {string} allergyId
         * @param {number} patientId
         * @param {NewStructuredAllergyLog} newStructuredAllergyLog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredAllergyChangelog: function (allergyId, patientId, newStructuredAllergyLog, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addStructuredAllergyChangelog(allergyId, patientId, newStructuredAllergyLog, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.addStructuredAllergyChangelog']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Create a new structured medication.
         * @summary Add Structured Medication
         * @param {number} patientId
         * @param {NewStructuredMedication} newStructuredMedication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredMedication: function (patientId, newStructuredMedication, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addStructuredMedication(patientId, newStructuredMedication, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.addStructuredMedication']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Bulk-insert a list of structured medications.  As the endpoint only accepts machine requests (for now), the payload is restricted to prescribed medications.  This is also used for migration from IH, where the created_at/updated_at dates can be specified, and an additional JSON blob can be stored that contains the full IH model (including data that has no mapping to structured medications in the CP).  The endpoint will only insert new structured medications, and will ignore any that already exist (same tenant_id, member_id, medication_name, created_at) if `allow_duplicates` is false.  De-duplication is against the database - duplicates in the supplied data will not be filtered out.
         * @summary Add Structured Medications Batch
         * @param {number} patientId
         * @param {Array<NewStructuredMedication>} newStructuredMedication
         * @param {boolean} [allowDuplicates] A medication is duplicate if it has the same name,         member_id, tenant_id and created_at date. Set this to false if such         duplicates should be ignored.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredMedicationsBatch: function (patientId, newStructuredMedication, allowDuplicates, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addStructuredMedicationsBatch(patientId, newStructuredMedication, allowDuplicates, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.addStructuredMedicationsBatch']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Insert multiple properties for a member.  (To be used for IH historical data migration.)  Inserts multiple properties for a member. The properties are grouped by type and inserted into the corresponding property type table. If a property already exists (based on tenant x patient x created_at), it is ignored.  This is pretty convoluted, because of how the patient property code was factored for SQLA v1-style ORM.
         * @summary Batch Insert Member Properties
         * @param {number} patientId
         * @param {NewMemberProperties} newMemberProperties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchInsertMemberProperties: function (patientId, newMemberProperties, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.batchInsertMemberProperties(patientId, newMemberProperties, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.batchInsertMemberProperties']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Create a questionnaire for a member.  A request with the `er:create:questionnaire` scope can create a questionnaire for any member.
         * @summary Create Questionnaire
         * @param {number} patientId
         * @param {NewQuestionnaire} newQuestionnaire
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnaire: function (patientId, newQuestionnaire, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createQuestionnaire(patientId, newQuestionnaire, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.createQuestionnaire']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Delete an admin note for a member.
         * @summary Delete Admin Note
         * @param {string} memberNoteId ID of the member note
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminNote: function (memberNoteId, patientId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteAdminNote(memberNoteId, patientId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.deleteAdminNote']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get all admin notes for a member (excluding soft-deleted notes).  The notes in the response are ordered by:  - priority (`important` first)  - then, recently created  - then, recently updated
         * @summary Get Admin Notes
         * @param {number} patientId
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminNotes: function (patientId, offset, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAdminNotes(patientId, offset, limit, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.getAdminNotes']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get the health profile of a member by returning the most recent value for each attribute type.
         * @summary Get Health Profile
         * @param {number} patientId
         * @param {Array<string>} [types] Filter by attribute type. e.g. \&#39;?types&#x3D;some-int-attribute-type&amp;types&#x3D;some-str-attribute-type\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthProfile: function (patientId, types, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getHealthProfile(patientId, types, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.getHealthProfile']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all properties for a member.  Returns a paginated list of properties for a member. The list can be filtered by property type and date range (created_before and created_after).  The \'latest\' query parameter can be used to fetch only the up-to-date properties by only returning the latest created item, for each property type.  The list is sorted by creation date, with the most recent items first. It contains the following different types of properties: Questionnaires, Allergies, Medications, and Medical History.
         * @summary Get Member Properties
         * @param {number} patientId
         * @param {Array<MemberPropertyType>} [type] Filter by property type. Can be \&#39;questionnaire\&#39;, \&#39;allergy\&#39;, \&#39;medication\&#39;, or \&#39;medical_history\&#39;. Supports repeated values, e.g. \&#39;?type&#x3D;medications&amp;type&#x3D;allergy\&#39;
         * @param {string} [createdAfter] Only return items created after this datetime (inclusive).
         * @param {string} [createdBefore] Only return items created before this datetime (inclusive).
         * @param {boolean} [latest] Only return the latest item of each property type.  (based on creation date)
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberProperties: function (patientId, type, createdAfter, createdBefore, latest, offset, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMemberProperties(patientId, type, createdAfter, createdBefore, latest, offset, limit, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.getMemberProperties']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all questionnaires submitted for a member.  The questionnaires in the response are ordered by their create date from newest to oldest.  A request with the `patient` role can access their own questionnaire. A request with er:list:questionnaires scope can access questionnaires of any member.
         * @summary Get Questionnaires
         * @param {number} patientId
         * @param {QuestionnaireType} [type]
         * @param {string} [createdAfter] Only return items created after this datetime (inclusive).
         * @param {string} [createdBefore] Only return items created before this datetime (inclusive).
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaires: function (patientId, type, createdAfter, createdBefore, offset, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getQuestionnaires(patientId, type, createdAfter, createdBefore, offset, limit, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.getQuestionnaires']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get all structured medications for a member.
         * @summary Get Structured Medications
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructuredMedications: function (patientId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getStructuredMedications(patientId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.getStructuredMedications']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List structured medications for a member\'s prescription.
         * @summary Get Structured Medications By Prescription Id
         * @param {number} prescriptionId
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructuredMedicationsByPrescriptionID: function (prescriptionId, patientId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getStructuredMedicationsByPrescriptionID(prescriptionId, patientId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.getStructuredMedicationsByPrescriptionID']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Upsert a Patient user record.
         * @summary Login Member
         * @param {LoginMember} loginMember
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginMember: function (loginMember, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.loginMember(loginMember, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.loginMember']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update an admin note for a member.  Only the fields that are provided in the request body will be updated.
         * @summary Update Admin Note
         * @param {string} memberNoteId ID of the member note
         * @param {number} patientId
         * @param {PartialNewAdminNote} partialNewAdminNote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminNote: function (memberNoteId, patientId, partialNewAdminNote, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateAdminNote(memberNoteId, patientId, partialNewAdminNote, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.updateAdminNote']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Updates a structured medication for a member.  For prescribed medications, only status-related fields can be updated:  - `is_stopped`  - `is_cancelled`  - `ended_at`  If any other fields are passed, the update will be rejected.
         * @summary Update Structured Medication
         * @param {string} structuredMedicationId
         * @param {number} patientId
         * @param {UpdateStructuredMedication} updateStructuredMedication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStructuredMedication: function (structuredMedicationId, patientId, updateStructuredMedication, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateStructuredMedication(structuredMedicationId, patientId, updateStructuredMedication, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.updateStructuredMedication']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Updates all structured medications for a member\'s prescription.
         * @summary Update Structured Medications By Prescription Id
         * @param {number} prescriptionId
         * @param {number} patientId
         * @param {UpdatePrescribedMedication} updatePrescribedMedication
         * @param {number} [onBehalfOf] The ID of the user the operation is done on behalf of. This is ignored if a user ID is present in claims.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStructuredMedicationsByPrescriptionID: function (prescriptionId, patientId, updatePrescribedMedication, onBehalfOf, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateStructuredMedicationsByPrescriptionID(prescriptionId, patientId, updatePrescribedMedication, onBehalfOf, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.updateStructuredMedicationsByPrescriptionID']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.MembersApiFp = MembersApiFp;
/**
 * MembersApi - factory interface
 * @export
 */
var MembersApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.MembersApiFp)(configuration);
    return {
        /**
         * Create a new admin note for a member.
         * @summary Add Admin Note
         * @param {MembersApiAddAdminNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminNote: function (requestParameters, options) {
            return localVarFp.addAdminNote(requestParameters.patientId, requestParameters.newAdminNote, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create multiple admin notes for a member.
         * @summary Add Admin Notes
         * @param {MembersApiAddAdminNotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminNotes: function (requestParameters, options) {
            return localVarFp.addAdminNotes(requestParameters.patientId, requestParameters.newAdminNote, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a new allergies property.
         * @summary Add Allergies Property
         * @param {MembersApiAddAllergiesPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAllergiesProperty: function (requestParameters, options) {
            return localVarFp.addAllergiesProperty(requestParameters.patientId, requestParameters.newAllergiesProperty, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create new health profile attributes.
         * @summary Add Health Profile Attributes
         * @param {MembersApiAddHealthProfileAttributesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHealthProfileAttributes: function (requestParameters, options) {
            return localVarFp.addHealthProfileAttributes(requestParameters.patientId, requestParameters.newHealthProfileAttributes, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a new medical history property.
         * @summary Add Medical History Property
         * @param {MembersApiAddMedicalHistoryPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedicalHistoryProperty: function (requestParameters, options) {
            return localVarFp.addMedicalHistoryProperty(requestParameters.patientId, requestParameters.newMedicalHistoryProperty, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a new medications property.
         * @summary Add Medications Property
         * @param {MembersApiAddMedicationsPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedicationsProperty: function (requestParameters, options) {
            return localVarFp.addMedicationsProperty(requestParameters.patientId, requestParameters.newMedicationsProperty, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a new structured allergy.
         * @summary Add Structured Allergy
         * @param {MembersApiAddStructuredAllergyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredAllergy: function (requestParameters, options) {
            return localVarFp.addStructuredAllergy(requestParameters.patientId, requestParameters.newStructuredAllergy, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Add Structured Allergy Changelog
         * @param {MembersApiAddStructuredAllergyChangelogRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredAllergyChangelog: function (requestParameters, options) {
            return localVarFp.addStructuredAllergyChangelog(requestParameters.allergyId, requestParameters.patientId, requestParameters.newStructuredAllergyLog, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a new structured medication.
         * @summary Add Structured Medication
         * @param {MembersApiAddStructuredMedicationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredMedication: function (requestParameters, options) {
            return localVarFp.addStructuredMedication(requestParameters.patientId, requestParameters.newStructuredMedication, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Bulk-insert a list of structured medications.  As the endpoint only accepts machine requests (for now), the payload is restricted to prescribed medications.  This is also used for migration from IH, where the created_at/updated_at dates can be specified, and an additional JSON blob can be stored that contains the full IH model (including data that has no mapping to structured medications in the CP).  The endpoint will only insert new structured medications, and will ignore any that already exist (same tenant_id, member_id, medication_name, created_at) if `allow_duplicates` is false.  De-duplication is against the database - duplicates in the supplied data will not be filtered out.
         * @summary Add Structured Medications Batch
         * @param {MembersApiAddStructuredMedicationsBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredMedicationsBatch: function (requestParameters, options) {
            return localVarFp.addStructuredMedicationsBatch(requestParameters.patientId, requestParameters.newStructuredMedication, requestParameters.allowDuplicates, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Insert multiple properties for a member.  (To be used for IH historical data migration.)  Inserts multiple properties for a member. The properties are grouped by type and inserted into the corresponding property type table. If a property already exists (based on tenant x patient x created_at), it is ignored.  This is pretty convoluted, because of how the patient property code was factored for SQLA v1-style ORM.
         * @summary Batch Insert Member Properties
         * @param {MembersApiBatchInsertMemberPropertiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchInsertMemberProperties: function (requestParameters, options) {
            return localVarFp.batchInsertMemberProperties(requestParameters.patientId, requestParameters.newMemberProperties, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a questionnaire for a member.  A request with the `er:create:questionnaire` scope can create a questionnaire for any member.
         * @summary Create Questionnaire
         * @param {MembersApiCreateQuestionnaireRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnaire: function (requestParameters, options) {
            return localVarFp.createQuestionnaire(requestParameters.patientId, requestParameters.newQuestionnaire, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete an admin note for a member.
         * @summary Delete Admin Note
         * @param {MembersApiDeleteAdminNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminNote: function (requestParameters, options) {
            return localVarFp.deleteAdminNote(requestParameters.memberNoteId, requestParameters.patientId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get all admin notes for a member (excluding soft-deleted notes).  The notes in the response are ordered by:  - priority (`important` first)  - then, recently created  - then, recently updated
         * @summary Get Admin Notes
         * @param {MembersApiGetAdminNotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminNotes: function (requestParameters, options) {
            return localVarFp.getAdminNotes(requestParameters.patientId, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get the health profile of a member by returning the most recent value for each attribute type.
         * @summary Get Health Profile
         * @param {MembersApiGetHealthProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthProfile: function (requestParameters, options) {
            return localVarFp.getHealthProfile(requestParameters.patientId, requestParameters.types, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all properties for a member.  Returns a paginated list of properties for a member. The list can be filtered by property type and date range (created_before and created_after).  The \'latest\' query parameter can be used to fetch only the up-to-date properties by only returning the latest created item, for each property type.  The list is sorted by creation date, with the most recent items first. It contains the following different types of properties: Questionnaires, Allergies, Medications, and Medical History.
         * @summary Get Member Properties
         * @param {MembersApiGetMemberPropertiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberProperties: function (requestParameters, options) {
            return localVarFp.getMemberProperties(requestParameters.patientId, requestParameters.type, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.latest, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all questionnaires submitted for a member.  The questionnaires in the response are ordered by their create date from newest to oldest.  A request with the `patient` role can access their own questionnaire. A request with er:list:questionnaires scope can access questionnaires of any member.
         * @summary Get Questionnaires
         * @param {MembersApiGetQuestionnairesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaires: function (requestParameters, options) {
            return localVarFp.getQuestionnaires(requestParameters.patientId, requestParameters.type, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get all structured medications for a member.
         * @summary Get Structured Medications
         * @param {MembersApiGetStructuredMedicationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructuredMedications: function (requestParameters, options) {
            return localVarFp.getStructuredMedications(requestParameters.patientId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List structured medications for a member\'s prescription.
         * @summary Get Structured Medications By Prescription Id
         * @param {MembersApiGetStructuredMedicationsByPrescriptionIDRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructuredMedicationsByPrescriptionID: function (requestParameters, options) {
            return localVarFp.getStructuredMedicationsByPrescriptionID(requestParameters.prescriptionId, requestParameters.patientId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Upsert a Patient user record.
         * @summary Login Member
         * @param {MembersApiLoginMemberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginMember: function (requestParameters, options) {
            return localVarFp.loginMember(requestParameters.loginMember, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update an admin note for a member.  Only the fields that are provided in the request body will be updated.
         * @summary Update Admin Note
         * @param {MembersApiUpdateAdminNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminNote: function (requestParameters, options) {
            return localVarFp.updateAdminNote(requestParameters.memberNoteId, requestParameters.patientId, requestParameters.partialNewAdminNote, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Updates a structured medication for a member.  For prescribed medications, only status-related fields can be updated:  - `is_stopped`  - `is_cancelled`  - `ended_at`  If any other fields are passed, the update will be rejected.
         * @summary Update Structured Medication
         * @param {MembersApiUpdateStructuredMedicationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStructuredMedication: function (requestParameters, options) {
            return localVarFp.updateStructuredMedication(requestParameters.structuredMedicationId, requestParameters.patientId, requestParameters.updateStructuredMedication, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Updates all structured medications for a member\'s prescription.
         * @summary Update Structured Medications By Prescription Id
         * @param {MembersApiUpdateStructuredMedicationsByPrescriptionIDRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStructuredMedicationsByPrescriptionID: function (requestParameters, options) {
            return localVarFp.updateStructuredMedicationsByPrescriptionID(requestParameters.prescriptionId, requestParameters.patientId, requestParameters.updatePrescribedMedication, requestParameters.onBehalfOf, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.MembersApiFactory = MembersApiFactory;
/**
 * MembersApi - object-oriented interface
 * @export
 * @class MembersApi
 * @extends {BaseAPI}
 */
var MembersApi = /** @class */ (function (_super) {
    __extends(MembersApi, _super);
    function MembersApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create a new admin note for a member.
     * @summary Add Admin Note
     * @param {MembersApiAddAdminNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.addAdminNote = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).addAdminNote(requestParameters.patientId, requestParameters.newAdminNote, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create multiple admin notes for a member.
     * @summary Add Admin Notes
     * @param {MembersApiAddAdminNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.addAdminNotes = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).addAdminNotes(requestParameters.patientId, requestParameters.newAdminNote, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a new allergies property.
     * @summary Add Allergies Property
     * @param {MembersApiAddAllergiesPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.addAllergiesProperty = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).addAllergiesProperty(requestParameters.patientId, requestParameters.newAllergiesProperty, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create new health profile attributes.
     * @summary Add Health Profile Attributes
     * @param {MembersApiAddHealthProfileAttributesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.addHealthProfileAttributes = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).addHealthProfileAttributes(requestParameters.patientId, requestParameters.newHealthProfileAttributes, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a new medical history property.
     * @summary Add Medical History Property
     * @param {MembersApiAddMedicalHistoryPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.addMedicalHistoryProperty = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).addMedicalHistoryProperty(requestParameters.patientId, requestParameters.newMedicalHistoryProperty, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a new medications property.
     * @summary Add Medications Property
     * @param {MembersApiAddMedicationsPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.addMedicationsProperty = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).addMedicationsProperty(requestParameters.patientId, requestParameters.newMedicationsProperty, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a new structured allergy.
     * @summary Add Structured Allergy
     * @param {MembersApiAddStructuredAllergyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.addStructuredAllergy = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).addStructuredAllergy(requestParameters.patientId, requestParameters.newStructuredAllergy, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Add Structured Allergy Changelog
     * @param {MembersApiAddStructuredAllergyChangelogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.addStructuredAllergyChangelog = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).addStructuredAllergyChangelog(requestParameters.allergyId, requestParameters.patientId, requestParameters.newStructuredAllergyLog, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a new structured medication.
     * @summary Add Structured Medication
     * @param {MembersApiAddStructuredMedicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.addStructuredMedication = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).addStructuredMedication(requestParameters.patientId, requestParameters.newStructuredMedication, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Bulk-insert a list of structured medications.  As the endpoint only accepts machine requests (for now), the payload is restricted to prescribed medications.  This is also used for migration from IH, where the created_at/updated_at dates can be specified, and an additional JSON blob can be stored that contains the full IH model (including data that has no mapping to structured medications in the CP).  The endpoint will only insert new structured medications, and will ignore any that already exist (same tenant_id, member_id, medication_name, created_at) if `allow_duplicates` is false.  De-duplication is against the database - duplicates in the supplied data will not be filtered out.
     * @summary Add Structured Medications Batch
     * @param {MembersApiAddStructuredMedicationsBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.addStructuredMedicationsBatch = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).addStructuredMedicationsBatch(requestParameters.patientId, requestParameters.newStructuredMedication, requestParameters.allowDuplicates, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Insert multiple properties for a member.  (To be used for IH historical data migration.)  Inserts multiple properties for a member. The properties are grouped by type and inserted into the corresponding property type table. If a property already exists (based on tenant x patient x created_at), it is ignored.  This is pretty convoluted, because of how the patient property code was factored for SQLA v1-style ORM.
     * @summary Batch Insert Member Properties
     * @param {MembersApiBatchInsertMemberPropertiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.batchInsertMemberProperties = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).batchInsertMemberProperties(requestParameters.patientId, requestParameters.newMemberProperties, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a questionnaire for a member.  A request with the `er:create:questionnaire` scope can create a questionnaire for any member.
     * @summary Create Questionnaire
     * @param {MembersApiCreateQuestionnaireRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.createQuestionnaire = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).createQuestionnaire(requestParameters.patientId, requestParameters.newQuestionnaire, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete an admin note for a member.
     * @summary Delete Admin Note
     * @param {MembersApiDeleteAdminNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.deleteAdminNote = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).deleteAdminNote(requestParameters.memberNoteId, requestParameters.patientId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get all admin notes for a member (excluding soft-deleted notes).  The notes in the response are ordered by:  - priority (`important` first)  - then, recently created  - then, recently updated
     * @summary Get Admin Notes
     * @param {MembersApiGetAdminNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.getAdminNotes = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).getAdminNotes(requestParameters.patientId, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get the health profile of a member by returning the most recent value for each attribute type.
     * @summary Get Health Profile
     * @param {MembersApiGetHealthProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.getHealthProfile = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).getHealthProfile(requestParameters.patientId, requestParameters.types, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all properties for a member.  Returns a paginated list of properties for a member. The list can be filtered by property type and date range (created_before and created_after).  The \'latest\' query parameter can be used to fetch only the up-to-date properties by only returning the latest created item, for each property type.  The list is sorted by creation date, with the most recent items first. It contains the following different types of properties: Questionnaires, Allergies, Medications, and Medical History.
     * @summary Get Member Properties
     * @param {MembersApiGetMemberPropertiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.getMemberProperties = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).getMemberProperties(requestParameters.patientId, requestParameters.type, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.latest, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all questionnaires submitted for a member.  The questionnaires in the response are ordered by their create date from newest to oldest.  A request with the `patient` role can access their own questionnaire. A request with er:list:questionnaires scope can access questionnaires of any member.
     * @summary Get Questionnaires
     * @param {MembersApiGetQuestionnairesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.getQuestionnaires = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).getQuestionnaires(requestParameters.patientId, requestParameters.type, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get all structured medications for a member.
     * @summary Get Structured Medications
     * @param {MembersApiGetStructuredMedicationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.getStructuredMedications = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).getStructuredMedications(requestParameters.patientId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List structured medications for a member\'s prescription.
     * @summary Get Structured Medications By Prescription Id
     * @param {MembersApiGetStructuredMedicationsByPrescriptionIDRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.getStructuredMedicationsByPrescriptionID = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).getStructuredMedicationsByPrescriptionID(requestParameters.prescriptionId, requestParameters.patientId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Upsert a Patient user record.
     * @summary Login Member
     * @param {MembersApiLoginMemberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.loginMember = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).loginMember(requestParameters.loginMember, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update an admin note for a member.  Only the fields that are provided in the request body will be updated.
     * @summary Update Admin Note
     * @param {MembersApiUpdateAdminNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.updateAdminNote = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).updateAdminNote(requestParameters.memberNoteId, requestParameters.patientId, requestParameters.partialNewAdminNote, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Updates a structured medication for a member.  For prescribed medications, only status-related fields can be updated:  - `is_stopped`  - `is_cancelled`  - `ended_at`  If any other fields are passed, the update will be rejected.
     * @summary Update Structured Medication
     * @param {MembersApiUpdateStructuredMedicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.updateStructuredMedication = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).updateStructuredMedication(requestParameters.structuredMedicationId, requestParameters.patientId, requestParameters.updateStructuredMedication, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Updates all structured medications for a member\'s prescription.
     * @summary Update Structured Medications By Prescription Id
     * @param {MembersApiUpdateStructuredMedicationsByPrescriptionIDRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.updateStructuredMedicationsByPrescriptionID = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).updateStructuredMedicationsByPrescriptionID(requestParameters.prescriptionId, requestParameters.patientId, requestParameters.updatePrescribedMedication, requestParameters.onBehalfOf, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MembersApi;
}(base_1.BaseAPI));
exports.MembersApi = MembersApi;
/**
 * NoteApi - axios parameter creator
 * @export
 */
var NoteApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Add Encounter Note
         * @param {number} patientId
         * @param {NewNote} newNote
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        addEncounterNote: function (patientId_1, newNote_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, newNote_1], args_1, true), void 0, function (patientId, newNote, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('addEncounterNote', 'patientId', patientId);
                            // verify required parameter 'newNote' is not null or undefined
                            (0, common_1.assertParamExists)('addEncounterNote', 'newNote', newNote);
                            localVarPath = "/v1/notes/{patient_id}"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newNote, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all encounter notes for a member.  The notes in the response are ordered by created date (newest first).
         * @summary Get Encounter Notes
         * @param {number} patientId
         * @param {boolean} [filterBySubjectId] Set to true to get notes associated to         a subject_id (guardian or dependent).         Note: the patient_id will be used as the subject_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEncounterNotes: function (patientId_1, filterBySubjectId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, filterBySubjectId_1], args_1, true), void 0, function (patientId, filterBySubjectId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getEncounterNotes', 'patientId', patientId);
                            localVarPath = "/v1/notes/{patient_id}"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (filterBySubjectId !== undefined) {
                                localVarQueryParameter['filter_by_subject_id'] = filterBySubjectId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.NoteApiAxiosParamCreator = NoteApiAxiosParamCreator;
/**
 * NoteApi - functional programming interface
 * @export
 */
var NoteApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.NoteApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Add Encounter Note
         * @param {number} patientId
         * @param {NewNote} newNote
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        addEncounterNote: function (patientId, newNote, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addEncounterNote(patientId, newNote, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['NoteApi.addEncounterNote']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all encounter notes for a member.  The notes in the response are ordered by created date (newest first).
         * @summary Get Encounter Notes
         * @param {number} patientId
         * @param {boolean} [filterBySubjectId] Set to true to get notes associated to         a subject_id (guardian or dependent).         Note: the patient_id will be used as the subject_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEncounterNotes: function (patientId, filterBySubjectId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getEncounterNotes(patientId, filterBySubjectId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['NoteApi.getEncounterNotes']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.NoteApiFp = NoteApiFp;
/**
 * NoteApi - factory interface
 * @export
 */
var NoteApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.NoteApiFp)(configuration);
    return {
        /**
         *
         * @summary Add Encounter Note
         * @param {NoteApiAddEncounterNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        addEncounterNote: function (requestParameters, options) {
            return localVarFp.addEncounterNote(requestParameters.patientId, requestParameters.newNote, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all encounter notes for a member.  The notes in the response are ordered by created date (newest first).
         * @summary Get Encounter Notes
         * @param {NoteApiGetEncounterNotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEncounterNotes: function (requestParameters, options) {
            return localVarFp.getEncounterNotes(requestParameters.patientId, requestParameters.filterBySubjectId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.NoteApiFactory = NoteApiFactory;
/**
 * NoteApi - object-oriented interface
 * @export
 * @class NoteApi
 * @extends {BaseAPI}
 */
var NoteApi = /** @class */ (function (_super) {
    __extends(NoteApi, _super);
    function NoteApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Add Encounter Note
     * @param {NoteApiAddEncounterNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    NoteApi.prototype.addEncounterNote = function (requestParameters, options) {
        var _this = this;
        return (0, exports.NoteApiFp)(this.configuration).addEncounterNote(requestParameters.patientId, requestParameters.newNote, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all encounter notes for a member.  The notes in the response are ordered by created date (newest first).
     * @summary Get Encounter Notes
     * @param {NoteApiGetEncounterNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    NoteApi.prototype.getEncounterNotes = function (requestParameters, options) {
        var _this = this;
        return (0, exports.NoteApiFp)(this.configuration).getEncounterNotes(requestParameters.patientId, requestParameters.filterBySubjectId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return NoteApi;
}(base_1.BaseAPI));
exports.NoteApi = NoteApi;
/**
 * PatientApi - axios parameter creator
 * @export
 */
var PatientApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Validate whether a practitioner has access to a patient record.
         * @summary Check Access To Patient Record
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccessToPatientRecord: function (patientId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1], args_1, true), void 0, function (patientId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('checkAccessToPatientRecord', 'patientId', patientId);
                            localVarPath = "/v1/patients/{patient_id}/access"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a new episode for the requestor-patient.
         * @summary Create Episode For Me
         * @param {string} authorization
         * @param {string} [episodeTrigger] episode_trigger should not be used
         * @param {BodyCreateEpisodeForMe} [bodyCreateEpisodeForMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEpisodeForMe: function (authorization_1, episodeTrigger_1, bodyCreateEpisodeForMe_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([authorization_1, episodeTrigger_1, bodyCreateEpisodeForMe_1], args_1, true), void 0, function (authorization, episodeTrigger, bodyCreateEpisodeForMe, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'authorization' is not null or undefined
                            (0, common_1.assertParamExists)('createEpisodeForMe', 'authorization', authorization);
                            localVarPath = "/v1/patients/me/episodes";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (episodeTrigger !== undefined) {
                                localVarQueryParameter['episode_trigger'] = episodeTrigger;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            if (authorization != null) {
                                localVarHeaderParameter['authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(bodyCreateEpisodeForMe, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a new episode for a patient.
         * @summary Create Episode For Patient
         * @param {number} patientId
         * @param {string} [authorization]
         * @param {BodyCreateEpisodeForPatient} [bodyCreateEpisodeForPatient]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEpisodeForPatient: function (patientId_1, authorization_1, bodyCreateEpisodeForPatient_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, authorization_1, bodyCreateEpisodeForPatient_1], args_1, true), void 0, function (patientId, authorization, bodyCreateEpisodeForPatient, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('createEpisodeForPatient', 'patientId', patientId);
                            localVarPath = "/v1/patients/{patient_id}/episodes"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            if (authorization != null) {
                                localVarHeaderParameter['authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(bodyCreateEpisodeForPatient, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get an episode that belongs to the requestor-patient.
         * @summary Get My Episode
         * @param {string} episodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyEpisode: function (episodeId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1], args_1, true), void 0, function (episodeId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('getMyEpisode', 'episodeId', episodeId);
                            localVarPath = "/v1/patients/me/episodes/{episode_id}"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get s3 download parameters for the ID card of the requestor-patient.  If the patient\'s `idCard` attribute is null, this returns a 404.
         * @summary Get My Id Card Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyIDCardURL: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/patients/me/medical_id/download";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get encounters for the requestor-patient.
         * @summary Get My Patient Encounters
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPatientEncounters: function (authorization_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([authorization_1], args_1, true), void 0, function (authorization, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/patients/me/encounters";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all episodes for the requestor-patient.  Episodes are selected by `brand_id`, which must be the same as JWT or null.  Episodes with certain values for `service_id` can be excluded through the query parameter \'service_id_excluded\'.  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get My Patient Episodes
         * @param {Array<string>} [serviceIdExcluded]
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPatientEpisodes: function (serviceIdExcluded_1, offset_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([serviceIdExcluded_1, offset_1, limit_1], args_1, true), void 0, function (serviceIdExcluded, offset, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/patients/me/episodes";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (serviceIdExcluded) {
                                localVarQueryParameter['service_id_excluded'] = serviceIdExcluded;
                            }
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get profile of the requestor-patient.
         * @summary Get My Patient Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPatientProfile: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/patients/me";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get or create health profile episode for the requestor-patient.
         * @summary Get Or Create My Health Profile Episode
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateMyHealthProfileEpisode: function (authorization_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([authorization_1], args_1, true), void 0, function (authorization, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/patients/me/health_profile_episode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get or create iCBT episode for the requestor-patient.
         * @summary Get Or Create My Icbt Episode
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateMyICBTEpisode: function (authorization_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([authorization_1], args_1, true), void 0, function (authorization, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/patients/me/icbt_episode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get or create WBI episode for the requestor-patient.
         * @summary Get Or Create My Wbi Episode
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateMyWBIEpisode: function (authorization_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([authorization_1], args_1, true), void 0, function (authorization, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/patients/me/wbi_episode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get encounters for a patient.
         * @summary Get Patient Encounters
         * @param {number} patientId
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPatientEncounters: function (patientId_1, offset_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, offset_1, limit_1], args_1, true), void 0, function (patientId, offset, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getPatientEncounters', 'patientId', patientId);
                            localVarPath = "/v1/patients/{patient_id}/encounters"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all episodes for a patient.  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get Patient Episodes
         * @param {number} patientId
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientEpisodes: function (patientId_1, offset_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, offset_1, limit_1], args_1, true), void 0, function (patientId, offset, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getPatientEpisodes', 'patientId', patientId);
                            localVarPath = "/v1/patients/{patient_id}/episodes"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List episodes for a patient with provided IDs.  Episode IDs should be passed via the query parameter \'episode_ids\'.
         * @summary Get Patient Episodes By Ids
         * @param {number} patientId
         * @param {Array<string>} [episodeIds] Restrict results to the list of provided ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientEpisodesByIDs: function (patientId_1, episodeIds_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, episodeIds_1], args_1, true), void 0, function (patientId, episodeIds, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getPatientEpisodesByIDs', 'patientId', patientId);
                            localVarPath = "/v1/patients/{patient_id}/episodes_by_ids"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (episodeIds) {
                                localVarQueryParameter['episode_ids'] = Array.from(episodeIds);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all episodes for a patient with limited fields (id, updated_at, and state)  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get Patient Episodes With Limited Information
         * @param {number} patientId
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientEpisodesWithLimitedInformation: function (patientId_1, offset_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, offset_1, limit_1], args_1, true), void 0, function (patientId, offset, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getPatientEpisodesWithLimitedInformation', 'patientId', patientId);
                            localVarPath = "/v1/patients/{patient_id}/limited_episodes"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get s3 download parameters for a patient\'s ID card.  If the patient\'s `idCard` attribute is null, this returns a 404.
         * @summary Get Patient Id Card Url
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientIDCardURL: function (patientId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1], args_1, true), void 0, function (patientId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getPatientIDCardURL', 'patientId', patientId);
                            localVarPath = "/v1/patients/{patient_id}/medical_id/download"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get ID card upload details for a patient.
         * @summary Get Patient Id Card Upload Details
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientIDCardUploadDetails: function (patientId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1], args_1, true), void 0, function (patientId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getPatientIDCardUploadDetails', 'patientId', patientId);
                            localVarPath = "/v1/patients/{patient_id}/medical_id/upload"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get profile of a patient.
         * @summary Get Patient Profile
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientProfile: function (patientId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1], args_1, true), void 0, function (patientId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('getPatientProfile', 'patientId', patientId);
                            localVarPath = "/v1/patients/{patient_id}"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Use a captcha challenge to grant a practitioner access to a patient record.
         * @summary Grant Access To Patient Record
         * @param {number} patientId
         * @param {BodyGrantAccessToPatientRecord} bodyGrantAccessToPatientRecord
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantAccessToPatientRecord: function (patientId_1, bodyGrantAccessToPatientRecord_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, bodyGrantAccessToPatientRecord_1], args_1, true), void 0, function (patientId, bodyGrantAccessToPatientRecord, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('grantAccessToPatientRecord', 'patientId', patientId);
                            // verify required parameter 'bodyGrantAccessToPatientRecord' is not null or undefined
                            (0, common_1.assertParamExists)('grantAccessToPatientRecord', 'bodyGrantAccessToPatientRecord', bodyGrantAccessToPatientRecord);
                            localVarPath = "/v1/patients/{patient_id}/access"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(bodyGrantAccessToPatientRecord, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Set the `idCard` attribute in the requestor-patient\'s record.  NOTE: the URL is not set from the payload. Instead, it is generated server-side from a fixed pattern.
         * @summary Set My Id Card Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMyIDCardURL: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/patients/me/medical_id/upload";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Set the `idCard` attribute in a patient\'s record.  NOTE: the URL is not set from the payload. Instead, it is generated server-side from a fixed pattern.
         * @summary Set Patient Id Card Url
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPatientIDCardURL: function (patientId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1], args_1, true), void 0, function (patientId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('setPatientIDCardURL', 'patientId', patientId);
                            localVarPath = "/v1/patients/{patient_id}/medical_id/upload"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update profile of the requestor-patient.
         * @summary Update My Patient Profile
         * @param {UpdateProfile} updateProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyPatientProfile: function (updateProfile_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([updateProfile_1], args_1, true), void 0, function (updateProfile, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'updateProfile' is not null or undefined
                            (0, common_1.assertParamExists)('updateMyPatientProfile', 'updateProfile', updateProfile);
                            localVarPath = "/v1/patients/me";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateProfile, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update profile of a patient.
         * @summary Update Patient Profile
         * @param {number} patientId
         * @param {UpdateProfileByPractitioner} updateProfileByPractitioner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientProfile: function (patientId_1, updateProfileByPractitioner_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([patientId_1, updateProfileByPractitioner_1], args_1, true), void 0, function (patientId, updateProfileByPractitioner, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('updatePatientProfile', 'patientId', patientId);
                            // verify required parameter 'updateProfileByPractitioner' is not null or undefined
                            (0, common_1.assertParamExists)('updatePatientProfile', 'updateProfileByPractitioner', updateProfileByPractitioner);
                            localVarPath = "/v1/patients/{patient_id}"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateProfileByPractitioner, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PatientApiAxiosParamCreator = PatientApiAxiosParamCreator;
/**
 * PatientApi - functional programming interface
 * @export
 */
var PatientApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PatientApiAxiosParamCreator)(configuration);
    return {
        /**
         * Validate whether a practitioner has access to a patient record.
         * @summary Check Access To Patient Record
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccessToPatientRecord: function (patientId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.checkAccessToPatientRecord(patientId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.checkAccessToPatientRecord']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Create a new episode for the requestor-patient.
         * @summary Create Episode For Me
         * @param {string} authorization
         * @param {string} [episodeTrigger] episode_trigger should not be used
         * @param {BodyCreateEpisodeForMe} [bodyCreateEpisodeForMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEpisodeForMe: function (authorization, episodeTrigger, bodyCreateEpisodeForMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createEpisodeForMe(authorization, episodeTrigger, bodyCreateEpisodeForMe, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.createEpisodeForMe']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Create a new episode for a patient.
         * @summary Create Episode For Patient
         * @param {number} patientId
         * @param {string} [authorization]
         * @param {BodyCreateEpisodeForPatient} [bodyCreateEpisodeForPatient]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEpisodeForPatient: function (patientId, authorization, bodyCreateEpisodeForPatient, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createEpisodeForPatient(patientId, authorization, bodyCreateEpisodeForPatient, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.createEpisodeForPatient']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get an episode that belongs to the requestor-patient.
         * @summary Get My Episode
         * @param {string} episodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyEpisode: function (episodeId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMyEpisode(episodeId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getMyEpisode']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get s3 download parameters for the ID card of the requestor-patient.  If the patient\'s `idCard` attribute is null, this returns a 404.
         * @summary Get My Id Card Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyIDCardURL: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMyIDCardURL(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getMyIDCardURL']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get encounters for the requestor-patient.
         * @summary Get My Patient Encounters
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPatientEncounters: function (authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMyPatientEncounters(authorization, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getMyPatientEncounters']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all episodes for the requestor-patient.  Episodes are selected by `brand_id`, which must be the same as JWT or null.  Episodes with certain values for `service_id` can be excluded through the query parameter \'service_id_excluded\'.  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get My Patient Episodes
         * @param {Array<string>} [serviceIdExcluded]
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPatientEpisodes: function (serviceIdExcluded, offset, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMyPatientEpisodes(serviceIdExcluded, offset, limit, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getMyPatientEpisodes']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get profile of the requestor-patient.
         * @summary Get My Patient Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPatientProfile: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMyPatientProfile(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getMyPatientProfile']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get or create health profile episode for the requestor-patient.
         * @summary Get Or Create My Health Profile Episode
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateMyHealthProfileEpisode: function (authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getOrCreateMyHealthProfileEpisode(authorization, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getOrCreateMyHealthProfileEpisode']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get or create iCBT episode for the requestor-patient.
         * @summary Get Or Create My Icbt Episode
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateMyICBTEpisode: function (authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getOrCreateMyICBTEpisode(authorization, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getOrCreateMyICBTEpisode']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get or create WBI episode for the requestor-patient.
         * @summary Get Or Create My Wbi Episode
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateMyWBIEpisode: function (authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getOrCreateMyWBIEpisode(authorization, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getOrCreateMyWBIEpisode']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get encounters for a patient.
         * @summary Get Patient Encounters
         * @param {number} patientId
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPatientEncounters: function (patientId, offset, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPatientEncounters(patientId, offset, limit, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getPatientEncounters']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all episodes for a patient.  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get Patient Episodes
         * @param {number} patientId
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientEpisodes: function (patientId, offset, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPatientEpisodes(patientId, offset, limit, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getPatientEpisodes']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List episodes for a patient with provided IDs.  Episode IDs should be passed via the query parameter \'episode_ids\'.
         * @summary Get Patient Episodes By Ids
         * @param {number} patientId
         * @param {Array<string>} [episodeIds] Restrict results to the list of provided ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientEpisodesByIDs: function (patientId, episodeIds, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPatientEpisodesByIDs(patientId, episodeIds, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getPatientEpisodesByIDs']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all episodes for a patient with limited fields (id, updated_at, and state)  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get Patient Episodes With Limited Information
         * @param {number} patientId
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientEpisodesWithLimitedInformation: function (patientId, offset, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPatientEpisodesWithLimitedInformation(patientId, offset, limit, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getPatientEpisodesWithLimitedInformation']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get s3 download parameters for a patient\'s ID card.  If the patient\'s `idCard` attribute is null, this returns a 404.
         * @summary Get Patient Id Card Url
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientIDCardURL: function (patientId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPatientIDCardURL(patientId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getPatientIDCardURL']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get ID card upload details for a patient.
         * @summary Get Patient Id Card Upload Details
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientIDCardUploadDetails: function (patientId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPatientIDCardUploadDetails(patientId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getPatientIDCardUploadDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get profile of a patient.
         * @summary Get Patient Profile
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientProfile: function (patientId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPatientProfile(patientId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.getPatientProfile']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Use a captcha challenge to grant a practitioner access to a patient record.
         * @summary Grant Access To Patient Record
         * @param {number} patientId
         * @param {BodyGrantAccessToPatientRecord} bodyGrantAccessToPatientRecord
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantAccessToPatientRecord: function (patientId, bodyGrantAccessToPatientRecord, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.grantAccessToPatientRecord(patientId, bodyGrantAccessToPatientRecord, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.grantAccessToPatientRecord']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Set the `idCard` attribute in the requestor-patient\'s record.  NOTE: the URL is not set from the payload. Instead, it is generated server-side from a fixed pattern.
         * @summary Set My Id Card Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMyIDCardURL: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setMyIDCardURL(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.setMyIDCardURL']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Set the `idCard` attribute in a patient\'s record.  NOTE: the URL is not set from the payload. Instead, it is generated server-side from a fixed pattern.
         * @summary Set Patient Id Card Url
         * @param {number} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPatientIDCardURL: function (patientId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setPatientIDCardURL(patientId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.setPatientIDCardURL']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update profile of the requestor-patient.
         * @summary Update My Patient Profile
         * @param {UpdateProfile} updateProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyPatientProfile: function (updateProfile, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateMyPatientProfile(updateProfile, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.updateMyPatientProfile']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update profile of a patient.
         * @summary Update Patient Profile
         * @param {number} patientId
         * @param {UpdateProfileByPractitioner} updateProfileByPractitioner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientProfile: function (patientId, updateProfileByPractitioner, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updatePatientProfile(patientId, updateProfileByPractitioner, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PatientApi.updatePatientProfile']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.PatientApiFp = PatientApiFp;
/**
 * PatientApi - factory interface
 * @export
 */
var PatientApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PatientApiFp)(configuration);
    return {
        /**
         * Validate whether a practitioner has access to a patient record.
         * @summary Check Access To Patient Record
         * @param {PatientApiCheckAccessToPatientRecordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccessToPatientRecord: function (requestParameters, options) {
            return localVarFp.checkAccessToPatientRecord(requestParameters.patientId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a new episode for the requestor-patient.
         * @summary Create Episode For Me
         * @param {PatientApiCreateEpisodeForMeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEpisodeForMe: function (requestParameters, options) {
            return localVarFp.createEpisodeForMe(requestParameters.authorization, requestParameters.episodeTrigger, requestParameters.bodyCreateEpisodeForMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a new episode for a patient.
         * @summary Create Episode For Patient
         * @param {PatientApiCreateEpisodeForPatientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEpisodeForPatient: function (requestParameters, options) {
            return localVarFp.createEpisodeForPatient(requestParameters.patientId, requestParameters.authorization, requestParameters.bodyCreateEpisodeForPatient, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get an episode that belongs to the requestor-patient.
         * @summary Get My Episode
         * @param {PatientApiGetMyEpisodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyEpisode: function (requestParameters, options) {
            return localVarFp.getMyEpisode(requestParameters.episodeId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get s3 download parameters for the ID card of the requestor-patient.  If the patient\'s `idCard` attribute is null, this returns a 404.
         * @summary Get My Id Card Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyIDCardURL: function (options) {
            return localVarFp.getMyIDCardURL(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get encounters for the requestor-patient.
         * @summary Get My Patient Encounters
         * @param {PatientApiGetMyPatientEncountersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPatientEncounters: function (requestParameters, options) {
            if (requestParameters === void 0) { requestParameters = {}; }
            return localVarFp.getMyPatientEncounters(requestParameters.authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all episodes for the requestor-patient.  Episodes are selected by `brand_id`, which must be the same as JWT or null.  Episodes with certain values for `service_id` can be excluded through the query parameter \'service_id_excluded\'.  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get My Patient Episodes
         * @param {PatientApiGetMyPatientEpisodesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPatientEpisodes: function (requestParameters, options) {
            if (requestParameters === void 0) { requestParameters = {}; }
            return localVarFp.getMyPatientEpisodes(requestParameters.serviceIdExcluded, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get profile of the requestor-patient.
         * @summary Get My Patient Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPatientProfile: function (options) {
            return localVarFp.getMyPatientProfile(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get or create health profile episode for the requestor-patient.
         * @summary Get Or Create My Health Profile Episode
         * @param {PatientApiGetOrCreateMyHealthProfileEpisodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateMyHealthProfileEpisode: function (requestParameters, options) {
            if (requestParameters === void 0) { requestParameters = {}; }
            return localVarFp.getOrCreateMyHealthProfileEpisode(requestParameters.authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get or create iCBT episode for the requestor-patient.
         * @summary Get Or Create My Icbt Episode
         * @param {PatientApiGetOrCreateMyICBTEpisodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateMyICBTEpisode: function (requestParameters, options) {
            if (requestParameters === void 0) { requestParameters = {}; }
            return localVarFp.getOrCreateMyICBTEpisode(requestParameters.authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get or create WBI episode for the requestor-patient.
         * @summary Get Or Create My Wbi Episode
         * @param {PatientApiGetOrCreateMyWBIEpisodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateMyWBIEpisode: function (requestParameters, options) {
            if (requestParameters === void 0) { requestParameters = {}; }
            return localVarFp.getOrCreateMyWBIEpisode(requestParameters.authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get encounters for a patient.
         * @summary Get Patient Encounters
         * @param {PatientApiGetPatientEncountersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPatientEncounters: function (requestParameters, options) {
            return localVarFp.getPatientEncounters(requestParameters.patientId, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all episodes for a patient.  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get Patient Episodes
         * @param {PatientApiGetPatientEpisodesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientEpisodes: function (requestParameters, options) {
            return localVarFp.getPatientEpisodes(requestParameters.patientId, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List episodes for a patient with provided IDs.  Episode IDs should be passed via the query parameter \'episode_ids\'.
         * @summary Get Patient Episodes By Ids
         * @param {PatientApiGetPatientEpisodesByIDsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientEpisodesByIDs: function (requestParameters, options) {
            return localVarFp.getPatientEpisodesByIDs(requestParameters.patientId, requestParameters.episodeIds, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all episodes for a patient with limited fields (id, updated_at, and state)  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get Patient Episodes With Limited Information
         * @param {PatientApiGetPatientEpisodesWithLimitedInformationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientEpisodesWithLimitedInformation: function (requestParameters, options) {
            return localVarFp.getPatientEpisodesWithLimitedInformation(requestParameters.patientId, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get s3 download parameters for a patient\'s ID card.  If the patient\'s `idCard` attribute is null, this returns a 404.
         * @summary Get Patient Id Card Url
         * @param {PatientApiGetPatientIDCardURLRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientIDCardURL: function (requestParameters, options) {
            return localVarFp.getPatientIDCardURL(requestParameters.patientId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get ID card upload details for a patient.
         * @summary Get Patient Id Card Upload Details
         * @param {PatientApiGetPatientIDCardUploadDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientIDCardUploadDetails: function (requestParameters, options) {
            return localVarFp.getPatientIDCardUploadDetails(requestParameters.patientId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get profile of a patient.
         * @summary Get Patient Profile
         * @param {PatientApiGetPatientProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientProfile: function (requestParameters, options) {
            return localVarFp.getPatientProfile(requestParameters.patientId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Use a captcha challenge to grant a practitioner access to a patient record.
         * @summary Grant Access To Patient Record
         * @param {PatientApiGrantAccessToPatientRecordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantAccessToPatientRecord: function (requestParameters, options) {
            return localVarFp.grantAccessToPatientRecord(requestParameters.patientId, requestParameters.bodyGrantAccessToPatientRecord, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Set the `idCard` attribute in the requestor-patient\'s record.  NOTE: the URL is not set from the payload. Instead, it is generated server-side from a fixed pattern.
         * @summary Set My Id Card Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMyIDCardURL: function (options) {
            return localVarFp.setMyIDCardURL(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Set the `idCard` attribute in a patient\'s record.  NOTE: the URL is not set from the payload. Instead, it is generated server-side from a fixed pattern.
         * @summary Set Patient Id Card Url
         * @param {PatientApiSetPatientIDCardURLRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPatientIDCardURL: function (requestParameters, options) {
            return localVarFp.setPatientIDCardURL(requestParameters.patientId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update profile of the requestor-patient.
         * @summary Update My Patient Profile
         * @param {PatientApiUpdateMyPatientProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyPatientProfile: function (requestParameters, options) {
            return localVarFp.updateMyPatientProfile(requestParameters.updateProfile, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update profile of a patient.
         * @summary Update Patient Profile
         * @param {PatientApiUpdatePatientProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientProfile: function (requestParameters, options) {
            return localVarFp.updatePatientProfile(requestParameters.patientId, requestParameters.updateProfileByPractitioner, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PatientApiFactory = PatientApiFactory;
/**
 * PatientApi - object-oriented interface
 * @export
 * @class PatientApi
 * @extends {BaseAPI}
 */
var PatientApi = /** @class */ (function (_super) {
    __extends(PatientApi, _super);
    function PatientApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Validate whether a practitioner has access to a patient record.
     * @summary Check Access To Patient Record
     * @param {PatientApiCheckAccessToPatientRecordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.checkAccessToPatientRecord = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).checkAccessToPatientRecord(requestParameters.patientId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a new episode for the requestor-patient.
     * @summary Create Episode For Me
     * @param {PatientApiCreateEpisodeForMeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.createEpisodeForMe = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).createEpisodeForMe(requestParameters.authorization, requestParameters.episodeTrigger, requestParameters.bodyCreateEpisodeForMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a new episode for a patient.
     * @summary Create Episode For Patient
     * @param {PatientApiCreateEpisodeForPatientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.createEpisodeForPatient = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).createEpisodeForPatient(requestParameters.patientId, requestParameters.authorization, requestParameters.bodyCreateEpisodeForPatient, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get an episode that belongs to the requestor-patient.
     * @summary Get My Episode
     * @param {PatientApiGetMyEpisodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getMyEpisode = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).getMyEpisode(requestParameters.episodeId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get s3 download parameters for the ID card of the requestor-patient.  If the patient\'s `idCard` attribute is null, this returns a 404.
     * @summary Get My Id Card Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getMyIDCardURL = function (options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).getMyIDCardURL(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get encounters for the requestor-patient.
     * @summary Get My Patient Encounters
     * @param {PatientApiGetMyPatientEncountersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getMyPatientEncounters = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.PatientApiFp)(this.configuration).getMyPatientEncounters(requestParameters.authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all episodes for the requestor-patient.  Episodes are selected by `brand_id`, which must be the same as JWT or null.  Episodes with certain values for `service_id` can be excluded through the query parameter \'service_id_excluded\'.  Paging is available through the query parameters \'offset\' and \'limit\'.
     * @summary Get My Patient Episodes
     * @param {PatientApiGetMyPatientEpisodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getMyPatientEpisodes = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.PatientApiFp)(this.configuration).getMyPatientEpisodes(requestParameters.serviceIdExcluded, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get profile of the requestor-patient.
     * @summary Get My Patient Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getMyPatientProfile = function (options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).getMyPatientProfile(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get or create health profile episode for the requestor-patient.
     * @summary Get Or Create My Health Profile Episode
     * @param {PatientApiGetOrCreateMyHealthProfileEpisodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getOrCreateMyHealthProfileEpisode = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.PatientApiFp)(this.configuration).getOrCreateMyHealthProfileEpisode(requestParameters.authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get or create iCBT episode for the requestor-patient.
     * @summary Get Or Create My Icbt Episode
     * @param {PatientApiGetOrCreateMyICBTEpisodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getOrCreateMyICBTEpisode = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.PatientApiFp)(this.configuration).getOrCreateMyICBTEpisode(requestParameters.authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get or create WBI episode for the requestor-patient.
     * @summary Get Or Create My Wbi Episode
     * @param {PatientApiGetOrCreateMyWBIEpisodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getOrCreateMyWBIEpisode = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.PatientApiFp)(this.configuration).getOrCreateMyWBIEpisode(requestParameters.authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get encounters for a patient.
     * @summary Get Patient Encounters
     * @param {PatientApiGetPatientEncountersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getPatientEncounters = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).getPatientEncounters(requestParameters.patientId, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all episodes for a patient.  Paging is available through the query parameters \'offset\' and \'limit\'.
     * @summary Get Patient Episodes
     * @param {PatientApiGetPatientEpisodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getPatientEpisodes = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).getPatientEpisodes(requestParameters.patientId, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List episodes for a patient with provided IDs.  Episode IDs should be passed via the query parameter \'episode_ids\'.
     * @summary Get Patient Episodes By Ids
     * @param {PatientApiGetPatientEpisodesByIDsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getPatientEpisodesByIDs = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).getPatientEpisodesByIDs(requestParameters.patientId, requestParameters.episodeIds, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all episodes for a patient with limited fields (id, updated_at, and state)  Paging is available through the query parameters \'offset\' and \'limit\'.
     * @summary Get Patient Episodes With Limited Information
     * @param {PatientApiGetPatientEpisodesWithLimitedInformationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getPatientEpisodesWithLimitedInformation = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).getPatientEpisodesWithLimitedInformation(requestParameters.patientId, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get s3 download parameters for a patient\'s ID card.  If the patient\'s `idCard` attribute is null, this returns a 404.
     * @summary Get Patient Id Card Url
     * @param {PatientApiGetPatientIDCardURLRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getPatientIDCardURL = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).getPatientIDCardURL(requestParameters.patientId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get ID card upload details for a patient.
     * @summary Get Patient Id Card Upload Details
     * @param {PatientApiGetPatientIDCardUploadDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getPatientIDCardUploadDetails = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).getPatientIDCardUploadDetails(requestParameters.patientId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get profile of a patient.
     * @summary Get Patient Profile
     * @param {PatientApiGetPatientProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.getPatientProfile = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).getPatientProfile(requestParameters.patientId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Use a captcha challenge to grant a practitioner access to a patient record.
     * @summary Grant Access To Patient Record
     * @param {PatientApiGrantAccessToPatientRecordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.grantAccessToPatientRecord = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).grantAccessToPatientRecord(requestParameters.patientId, requestParameters.bodyGrantAccessToPatientRecord, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Set the `idCard` attribute in the requestor-patient\'s record.  NOTE: the URL is not set from the payload. Instead, it is generated server-side from a fixed pattern.
     * @summary Set My Id Card Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.setMyIDCardURL = function (options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).setMyIDCardURL(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Set the `idCard` attribute in a patient\'s record.  NOTE: the URL is not set from the payload. Instead, it is generated server-side from a fixed pattern.
     * @summary Set Patient Id Card Url
     * @param {PatientApiSetPatientIDCardURLRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.setPatientIDCardURL = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).setPatientIDCardURL(requestParameters.patientId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update profile of the requestor-patient.
     * @summary Update My Patient Profile
     * @param {PatientApiUpdateMyPatientProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.updateMyPatientProfile = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).updateMyPatientProfile(requestParameters.updateProfile, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update profile of a patient.
     * @summary Update Patient Profile
     * @param {PatientApiUpdatePatientProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.updatePatientProfile = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).updatePatientProfile(requestParameters.patientId, requestParameters.updateProfileByPractitioner, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PatientApi;
}(base_1.BaseAPI));
exports.PatientApi = PatientApi;
/**
 * PharmaciesApi - axios parameter creator
 * @export
 */
var PharmaciesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Add a pharmacy under the current tenant.  Scope `er:manage:pharmacies` is required.
         * @summary Create Pharmacy
         * @param {NewPharmacy} newPharmacy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPharmacy: function (newPharmacy_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([newPharmacy_1], args_1, true), void 0, function (newPharmacy, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'newPharmacy' is not null or undefined
                            (0, common_1.assertParamExists)('createPharmacy', 'newPharmacy', newPharmacy);
                            localVarPath = "/v1/pharmacies";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newPharmacy, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete a pharmacy under the current tenant.  Scope `er:manage:pharmacies` is required.
         * @summary Delete Pharmacy
         * @param {number} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePharmacy: function (pharmacyId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([pharmacyId_1], args_1, true), void 0, function (pharmacyId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'pharmacyId' is not null or undefined
                            (0, common_1.assertParamExists)('deletePharmacy', 'pharmacyId', pharmacyId);
                            localVarPath = "/v1/pharmacies/{pharmacy_id}"
                                .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a list of pharmacies under the current tenant.  Scope `er:list:pharmacies` or `er:list:limited_pharmacies` is required.
         * @summary Get Pharmacies
         * @param {string} [externalIds]
         * @param {Array<PharmacyType>} [pharmacyType] Filter by pharmacy type
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPharmacies: function (externalIds_1, pharmacyType_1, offset_1, limit_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([externalIds_1, pharmacyType_1, offset_1, limit_1], args_1, true), void 0, function (externalIds, pharmacyType, offset, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/pharmacies";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (externalIds !== undefined) {
                                localVarQueryParameter['external_ids'] = externalIds;
                            }
                            if (pharmacyType) {
                                localVarQueryParameter['pharmacy_type'] = Array.from(pharmacyType);
                            }
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a pharmacy by id under the current tenant.  Scope `er:list:pharmacies` or `er:list:limited_pharmacies` is required.
         * @summary Get Pharmacy
         * @param {number} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPharmacy: function (pharmacyId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([pharmacyId_1], args_1, true), void 0, function (pharmacyId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'pharmacyId' is not null or undefined
                            (0, common_1.assertParamExists)('getPharmacy', 'pharmacyId', pharmacyId);
                            localVarPath = "/v1/pharmacies/{pharmacy_id}"
                                .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a pharmacy under the current tenant.  Scope `er:manage:pharmacies` is required.
         * @summary Update Pharmacy
         * @param {number} pharmacyId
         * @param {UpdatePharmacy} updatePharmacy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePharmacy: function (pharmacyId_1, updatePharmacy_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([pharmacyId_1, updatePharmacy_1], args_1, true), void 0, function (pharmacyId, updatePharmacy, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'pharmacyId' is not null or undefined
                            (0, common_1.assertParamExists)('updatePharmacy', 'pharmacyId', pharmacyId);
                            // verify required parameter 'updatePharmacy' is not null or undefined
                            (0, common_1.assertParamExists)('updatePharmacy', 'updatePharmacy', updatePharmacy);
                            localVarPath = "/v1/pharmacies/{pharmacy_id}"
                                .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePharmacy, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PharmaciesApiAxiosParamCreator = PharmaciesApiAxiosParamCreator;
/**
 * PharmaciesApi - functional programming interface
 * @export
 */
var PharmaciesApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PharmaciesApiAxiosParamCreator)(configuration);
    return {
        /**
         * Add a pharmacy under the current tenant.  Scope `er:manage:pharmacies` is required.
         * @summary Create Pharmacy
         * @param {NewPharmacy} newPharmacy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPharmacy: function (newPharmacy, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createPharmacy(newPharmacy, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PharmaciesApi.createPharmacy']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Delete a pharmacy under the current tenant.  Scope `er:manage:pharmacies` is required.
         * @summary Delete Pharmacy
         * @param {number} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePharmacy: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deletePharmacy(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PharmaciesApi.deletePharmacy']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get a list of pharmacies under the current tenant.  Scope `er:list:pharmacies` or `er:list:limited_pharmacies` is required.
         * @summary Get Pharmacies
         * @param {string} [externalIds]
         * @param {Array<PharmacyType>} [pharmacyType] Filter by pharmacy type
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPharmacies: function (externalIds, pharmacyType, offset, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPharmacies(externalIds, pharmacyType, offset, limit, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PharmaciesApi.getPharmacies']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get a pharmacy by id under the current tenant.  Scope `er:list:pharmacies` or `er:list:limited_pharmacies` is required.
         * @summary Get Pharmacy
         * @param {number} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPharmacy: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPharmacy(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PharmaciesApi.getPharmacy']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update a pharmacy under the current tenant.  Scope `er:manage:pharmacies` is required.
         * @summary Update Pharmacy
         * @param {number} pharmacyId
         * @param {UpdatePharmacy} updatePharmacy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePharmacy: function (pharmacyId, updatePharmacy, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updatePharmacy(pharmacyId, updatePharmacy, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PharmaciesApi.updatePharmacy']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.PharmaciesApiFp = PharmaciesApiFp;
/**
 * PharmaciesApi - factory interface
 * @export
 */
var PharmaciesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PharmaciesApiFp)(configuration);
    return {
        /**
         * Add a pharmacy under the current tenant.  Scope `er:manage:pharmacies` is required.
         * @summary Create Pharmacy
         * @param {PharmaciesApiCreatePharmacyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPharmacy: function (requestParameters, options) {
            return localVarFp.createPharmacy(requestParameters.newPharmacy, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete a pharmacy under the current tenant.  Scope `er:manage:pharmacies` is required.
         * @summary Delete Pharmacy
         * @param {PharmaciesApiDeletePharmacyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePharmacy: function (requestParameters, options) {
            return localVarFp.deletePharmacy(requestParameters.pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a list of pharmacies under the current tenant.  Scope `er:list:pharmacies` or `er:list:limited_pharmacies` is required.
         * @summary Get Pharmacies
         * @param {PharmaciesApiGetPharmaciesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPharmacies: function (requestParameters, options) {
            if (requestParameters === void 0) { requestParameters = {}; }
            return localVarFp.getPharmacies(requestParameters.externalIds, requestParameters.pharmacyType, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a pharmacy by id under the current tenant.  Scope `er:list:pharmacies` or `er:list:limited_pharmacies` is required.
         * @summary Get Pharmacy
         * @param {PharmaciesApiGetPharmacyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPharmacy: function (requestParameters, options) {
            return localVarFp.getPharmacy(requestParameters.pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a pharmacy under the current tenant.  Scope `er:manage:pharmacies` is required.
         * @summary Update Pharmacy
         * @param {PharmaciesApiUpdatePharmacyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePharmacy: function (requestParameters, options) {
            return localVarFp.updatePharmacy(requestParameters.pharmacyId, requestParameters.updatePharmacy, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PharmaciesApiFactory = PharmaciesApiFactory;
/**
 * PharmaciesApi - object-oriented interface
 * @export
 * @class PharmaciesApi
 * @extends {BaseAPI}
 */
var PharmaciesApi = /** @class */ (function (_super) {
    __extends(PharmaciesApi, _super);
    function PharmaciesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Add a pharmacy under the current tenant.  Scope `er:manage:pharmacies` is required.
     * @summary Create Pharmacy
     * @param {PharmaciesApiCreatePharmacyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmaciesApi
     */
    PharmaciesApi.prototype.createPharmacy = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PharmaciesApiFp)(this.configuration).createPharmacy(requestParameters.newPharmacy, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete a pharmacy under the current tenant.  Scope `er:manage:pharmacies` is required.
     * @summary Delete Pharmacy
     * @param {PharmaciesApiDeletePharmacyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmaciesApi
     */
    PharmaciesApi.prototype.deletePharmacy = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PharmaciesApiFp)(this.configuration).deletePharmacy(requestParameters.pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a list of pharmacies under the current tenant.  Scope `er:list:pharmacies` or `er:list:limited_pharmacies` is required.
     * @summary Get Pharmacies
     * @param {PharmaciesApiGetPharmaciesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmaciesApi
     */
    PharmaciesApi.prototype.getPharmacies = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.PharmaciesApiFp)(this.configuration).getPharmacies(requestParameters.externalIds, requestParameters.pharmacyType, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a pharmacy by id under the current tenant.  Scope `er:list:pharmacies` or `er:list:limited_pharmacies` is required.
     * @summary Get Pharmacy
     * @param {PharmaciesApiGetPharmacyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmaciesApi
     */
    PharmaciesApi.prototype.getPharmacy = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PharmaciesApiFp)(this.configuration).getPharmacy(requestParameters.pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a pharmacy under the current tenant.  Scope `er:manage:pharmacies` is required.
     * @summary Update Pharmacy
     * @param {PharmaciesApiUpdatePharmacyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmaciesApi
     */
    PharmaciesApi.prototype.updatePharmacy = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PharmaciesApiFp)(this.configuration).updatePharmacy(requestParameters.pharmacyId, requestParameters.updatePharmacy, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PharmaciesApi;
}(base_1.BaseAPI));
exports.PharmaciesApi = PharmaciesApi;
/**
 * PractitionerApi - axios parameter creator
 * @export
 */
var PractitionerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Activate or reactivate a practitioner account.
         * @summary Activate Practitioner
         * @param {PractitionerId} practitionerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activatePractitioner: function (practitionerId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([practitionerId_1], args_1, true), void 0, function (practitionerId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'practitionerId' is not null or undefined
                            (0, common_1.assertParamExists)('activatePractitioner', 'practitionerId', practitionerId);
                            localVarPath = "/v1/practitioners/{practitioner_id}/activate"
                                .replace("{".concat("practitioner_id", "}"), encodeURIComponent(String(practitionerId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a new license for a practitioner.
         * @summary Add Practitioner License
         * @param {number} practitionerId
         * @param {NewLicense} newLicense
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPractitionerLicense: function (practitionerId_1, newLicense_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([practitionerId_1, newLicense_1], args_1, true), void 0, function (practitionerId, newLicense, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'practitionerId' is not null or undefined
                            (0, common_1.assertParamExists)('addPractitionerLicense', 'practitionerId', practitionerId);
                            // verify required parameter 'newLicense' is not null or undefined
                            (0, common_1.assertParamExists)('addPractitionerLicense', 'newLicense', newLicense);
                            localVarPath = "/v1/practitioners/{practitioner_id}/licenses"
                                .replace("{".concat("practitioner_id", "}"), encodeURIComponent(String(practitionerId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newLicense, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a new practitioner.
         * @summary Create Practitioner
         * @param {NewPractitioner} newPractitioner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPractitioner: function (newPractitioner_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([newPractitioner_1], args_1, true), void 0, function (newPractitioner, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'newPractitioner' is not null or undefined
                            (0, common_1.assertParamExists)('createPractitioner', 'newPractitioner', newPractitioner);
                            localVarPath = "/v1/practitioners";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newPractitioner, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Deactivate a practitioner account.
         * @summary Deactivate Practitioner
         * @param {PractitionerId} practitionerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePractitioner: function (practitionerId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([practitionerId_1], args_1, true), void 0, function (practitionerId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'practitionerId' is not null or undefined
                            (0, common_1.assertParamExists)('deactivatePractitioner', 'practitionerId', practitionerId);
                            localVarPath = "/v1/practitioners/{practitioner_id}/deactivate"
                                .replace("{".concat("practitioner_id", "}"), encodeURIComponent(String(practitionerId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete a license for a practitioner.
         * @summary Delete Practitioner License
         * @param {number} practitionerId
         * @param {string} licenseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePractitionerLicense: function (practitionerId_1, licenseId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([practitionerId_1, licenseId_1], args_1, true), void 0, function (practitionerId, licenseId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'practitionerId' is not null or undefined
                            (0, common_1.assertParamExists)('deletePractitionerLicense', 'practitionerId', practitionerId);
                            // verify required parameter 'licenseId' is not null or undefined
                            (0, common_1.assertParamExists)('deletePractitionerLicense', 'licenseId', licenseId);
                            localVarPath = "/v1/practitioners/{practitioner_id}/licenses/{license_id}"
                                .replace("{".concat("practitioner_id", "}"), encodeURIComponent(String(practitionerId)))
                                .replace("{".concat("license_id", "}"), encodeURIComponent(String(licenseId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all episodes assigned to the requestor.  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get Episodes Assigned To Me
         * @param {EpisodeState} state
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodesAssignedToMe: function (state_1, offset_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([state_1, offset_1, limit_1], args_1, true), void 0, function (state, offset, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'state' is not null or undefined
                            (0, common_1.assertParamExists)('getEpisodesAssignedToMe', 'state', state);
                            localVarPath = "/v1/practitioners/me/episodes";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (state !== undefined) {
                                localVarQueryParameter['state'] = state;
                            }
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a practitioner record.
         * @summary Get Practitioner
         * @param {PractitionerId} practitionerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitioner: function (practitionerId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([practitionerId_1], args_1, true), void 0, function (practitionerId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'practitionerId' is not null or undefined
                            (0, common_1.assertParamExists)('getPractitioner', 'practitionerId', practitionerId);
                            localVarPath = "/v1/practitioners/{practitioner_id}"
                                .replace("{".concat("practitioner_id", "}"), encodeURIComponent(String(practitionerId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all licenses for a practitioner.
         * @summary Get Practitioner Licenses
         * @param {number} practitionerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitionerLicenses: function (practitionerId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([practitionerId_1], args_1, true), void 0, function (practitionerId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'practitionerId' is not null or undefined
                            (0, common_1.assertParamExists)('getPractitionerLicenses', 'practitionerId', practitionerId);
                            localVarPath = "/v1/practitioners/{practitioner_id}/licenses"
                                .replace("{".concat("practitioner_id", "}"), encodeURIComponent(String(practitionerId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all practitioner records with (optional) filtering on `status_set_at`.
         * @summary Get Practitioners
         * @param {string} [statusSince]
         * @param {Array<number>} [id] Use multiple time to filter by specific practitioner id(s)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitioners: function (statusSince_1, id_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([statusSince_1, id_1], args_1, true), void 0, function (statusSince, id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/practitioners";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (statusSince !== undefined) {
                                localVarQueryParameter['status_since'] = (statusSince instanceof Date) ?
                                    statusSince.toISOString() :
                                    statusSince;
                            }
                            if (id) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a practitioner record.  If the requestor does not have the `\"er:update:practitioner:all\"` scope, the `givenName` and `familyName` fields are ignored and will not be updated.
         * @summary Update Practitioner
         * @param {PractitionerId} practitionerId
         * @param {FullPractitionerUpdate} fullPractitionerUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePractitioner: function (practitionerId_1, fullPractitionerUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([practitionerId_1, fullPractitionerUpdate_1], args_1, true), void 0, function (practitionerId, fullPractitionerUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'practitionerId' is not null or undefined
                            (0, common_1.assertParamExists)('updatePractitioner', 'practitionerId', practitionerId);
                            // verify required parameter 'fullPractitionerUpdate' is not null or undefined
                            (0, common_1.assertParamExists)('updatePractitioner', 'fullPractitionerUpdate', fullPractitionerUpdate);
                            localVarPath = "/v1/practitioners/{practitioner_id}"
                                .replace("{".concat("practitioner_id", "}"), encodeURIComponent(String(practitionerId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(fullPractitionerUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a license for a practitioner.
         * @summary Update Practitioner License
         * @param {number} practitionerId
         * @param {string} licenseId
         * @param {NewLicense} newLicense
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePractitionerLicense: function (practitionerId_1, licenseId_1, newLicense_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([practitionerId_1, licenseId_1, newLicense_1], args_1, true), void 0, function (practitionerId, licenseId, newLicense, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'practitionerId' is not null or undefined
                            (0, common_1.assertParamExists)('updatePractitionerLicense', 'practitionerId', practitionerId);
                            // verify required parameter 'licenseId' is not null or undefined
                            (0, common_1.assertParamExists)('updatePractitionerLicense', 'licenseId', licenseId);
                            // verify required parameter 'newLicense' is not null or undefined
                            (0, common_1.assertParamExists)('updatePractitionerLicense', 'newLicense', newLicense);
                            localVarPath = "/v1/practitioners/{practitioner_id}/licenses/{license_id}"
                                .replace("{".concat("practitioner_id", "}"), encodeURIComponent(String(practitionerId)))
                                .replace("{".concat("license_id", "}"), encodeURIComponent(String(licenseId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newLicense, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PractitionerApiAxiosParamCreator = PractitionerApiAxiosParamCreator;
/**
 * PractitionerApi - functional programming interface
 * @export
 */
var PractitionerApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PractitionerApiAxiosParamCreator)(configuration);
    return {
        /**
         * Activate or reactivate a practitioner account.
         * @summary Activate Practitioner
         * @param {PractitionerId} practitionerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activatePractitioner: function (practitionerId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.activatePractitioner(practitionerId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PractitionerApi.activatePractitioner']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Create a new license for a practitioner.
         * @summary Add Practitioner License
         * @param {number} practitionerId
         * @param {NewLicense} newLicense
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPractitionerLicense: function (practitionerId, newLicense, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addPractitionerLicense(practitionerId, newLicense, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PractitionerApi.addPractitionerLicense']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Create a new practitioner.
         * @summary Create Practitioner
         * @param {NewPractitioner} newPractitioner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPractitioner: function (newPractitioner, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createPractitioner(newPractitioner, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PractitionerApi.createPractitioner']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Deactivate a practitioner account.
         * @summary Deactivate Practitioner
         * @param {PractitionerId} practitionerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePractitioner: function (practitionerId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deactivatePractitioner(practitionerId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PractitionerApi.deactivatePractitioner']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Delete a license for a practitioner.
         * @summary Delete Practitioner License
         * @param {number} practitionerId
         * @param {string} licenseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePractitionerLicense: function (practitionerId, licenseId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deletePractitionerLicense(practitionerId, licenseId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PractitionerApi.deletePractitionerLicense']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all episodes assigned to the requestor.  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get Episodes Assigned To Me
         * @param {EpisodeState} state
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodesAssignedToMe: function (state, offset, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getEpisodesAssignedToMe(state, offset, limit, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PractitionerApi.getEpisodesAssignedToMe']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get a practitioner record.
         * @summary Get Practitioner
         * @param {PractitionerId} practitionerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitioner: function (practitionerId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPractitioner(practitionerId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PractitionerApi.getPractitioner']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all licenses for a practitioner.
         * @summary Get Practitioner Licenses
         * @param {number} practitionerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitionerLicenses: function (practitionerId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPractitionerLicenses(practitionerId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PractitionerApi.getPractitionerLicenses']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all practitioner records with (optional) filtering on `status_set_at`.
         * @summary Get Practitioners
         * @param {string} [statusSince]
         * @param {Array<number>} [id] Use multiple time to filter by specific practitioner id(s)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitioners: function (statusSince, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPractitioners(statusSince, id, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PractitionerApi.getPractitioners']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update a practitioner record.  If the requestor does not have the `\"er:update:practitioner:all\"` scope, the `givenName` and `familyName` fields are ignored and will not be updated.
         * @summary Update Practitioner
         * @param {PractitionerId} practitionerId
         * @param {FullPractitionerUpdate} fullPractitionerUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePractitioner: function (practitionerId, fullPractitionerUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updatePractitioner(practitionerId, fullPractitionerUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PractitionerApi.updatePractitioner']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update a license for a practitioner.
         * @summary Update Practitioner License
         * @param {number} practitionerId
         * @param {string} licenseId
         * @param {NewLicense} newLicense
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePractitionerLicense: function (practitionerId, licenseId, newLicense, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updatePractitionerLicense(practitionerId, licenseId, newLicense, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['PractitionerApi.updatePractitionerLicense']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.PractitionerApiFp = PractitionerApiFp;
/**
 * PractitionerApi - factory interface
 * @export
 */
var PractitionerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PractitionerApiFp)(configuration);
    return {
        /**
         * Activate or reactivate a practitioner account.
         * @summary Activate Practitioner
         * @param {PractitionerApiActivatePractitionerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activatePractitioner: function (requestParameters, options) {
            return localVarFp.activatePractitioner(requestParameters.practitionerId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a new license for a practitioner.
         * @summary Add Practitioner License
         * @param {PractitionerApiAddPractitionerLicenseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPractitionerLicense: function (requestParameters, options) {
            return localVarFp.addPractitionerLicense(requestParameters.practitionerId, requestParameters.newLicense, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a new practitioner.
         * @summary Create Practitioner
         * @param {PractitionerApiCreatePractitionerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPractitioner: function (requestParameters, options) {
            return localVarFp.createPractitioner(requestParameters.newPractitioner, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Deactivate a practitioner account.
         * @summary Deactivate Practitioner
         * @param {PractitionerApiDeactivatePractitionerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePractitioner: function (requestParameters, options) {
            return localVarFp.deactivatePractitioner(requestParameters.practitionerId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete a license for a practitioner.
         * @summary Delete Practitioner License
         * @param {PractitionerApiDeletePractitionerLicenseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePractitionerLicense: function (requestParameters, options) {
            return localVarFp.deletePractitionerLicense(requestParameters.practitionerId, requestParameters.licenseId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all episodes assigned to the requestor.  Paging is available through the query parameters \'offset\' and \'limit\'.
         * @summary Get Episodes Assigned To Me
         * @param {PractitionerApiGetEpisodesAssignedToMeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodesAssignedToMe: function (requestParameters, options) {
            return localVarFp.getEpisodesAssignedToMe(requestParameters.state, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a practitioner record.
         * @summary Get Practitioner
         * @param {PractitionerApiGetPractitionerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitioner: function (requestParameters, options) {
            return localVarFp.getPractitioner(requestParameters.practitionerId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all licenses for a practitioner.
         * @summary Get Practitioner Licenses
         * @param {PractitionerApiGetPractitionerLicensesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitionerLicenses: function (requestParameters, options) {
            return localVarFp.getPractitionerLicenses(requestParameters.practitionerId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all practitioner records with (optional) filtering on `status_set_at`.
         * @summary Get Practitioners
         * @param {PractitionerApiGetPractitionersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractitioners: function (requestParameters, options) {
            if (requestParameters === void 0) { requestParameters = {}; }
            return localVarFp.getPractitioners(requestParameters.statusSince, requestParameters.id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a practitioner record.  If the requestor does not have the `\"er:update:practitioner:all\"` scope, the `givenName` and `familyName` fields are ignored and will not be updated.
         * @summary Update Practitioner
         * @param {PractitionerApiUpdatePractitionerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePractitioner: function (requestParameters, options) {
            return localVarFp.updatePractitioner(requestParameters.practitionerId, requestParameters.fullPractitionerUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a license for a practitioner.
         * @summary Update Practitioner License
         * @param {PractitionerApiUpdatePractitionerLicenseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePractitionerLicense: function (requestParameters, options) {
            return localVarFp.updatePractitionerLicense(requestParameters.practitionerId, requestParameters.licenseId, requestParameters.newLicense, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PractitionerApiFactory = PractitionerApiFactory;
/**
 * PractitionerApi - object-oriented interface
 * @export
 * @class PractitionerApi
 * @extends {BaseAPI}
 */
var PractitionerApi = /** @class */ (function (_super) {
    __extends(PractitionerApi, _super);
    function PractitionerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Activate or reactivate a practitioner account.
     * @summary Activate Practitioner
     * @param {PractitionerApiActivatePractitionerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionerApi
     */
    PractitionerApi.prototype.activatePractitioner = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PractitionerApiFp)(this.configuration).activatePractitioner(requestParameters.practitionerId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a new license for a practitioner.
     * @summary Add Practitioner License
     * @param {PractitionerApiAddPractitionerLicenseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionerApi
     */
    PractitionerApi.prototype.addPractitionerLicense = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PractitionerApiFp)(this.configuration).addPractitionerLicense(requestParameters.practitionerId, requestParameters.newLicense, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a new practitioner.
     * @summary Create Practitioner
     * @param {PractitionerApiCreatePractitionerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionerApi
     */
    PractitionerApi.prototype.createPractitioner = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PractitionerApiFp)(this.configuration).createPractitioner(requestParameters.newPractitioner, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Deactivate a practitioner account.
     * @summary Deactivate Practitioner
     * @param {PractitionerApiDeactivatePractitionerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionerApi
     */
    PractitionerApi.prototype.deactivatePractitioner = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PractitionerApiFp)(this.configuration).deactivatePractitioner(requestParameters.practitionerId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete a license for a practitioner.
     * @summary Delete Practitioner License
     * @param {PractitionerApiDeletePractitionerLicenseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionerApi
     */
    PractitionerApi.prototype.deletePractitionerLicense = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PractitionerApiFp)(this.configuration).deletePractitionerLicense(requestParameters.practitionerId, requestParameters.licenseId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all episodes assigned to the requestor.  Paging is available through the query parameters \'offset\' and \'limit\'.
     * @summary Get Episodes Assigned To Me
     * @param {PractitionerApiGetEpisodesAssignedToMeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionerApi
     */
    PractitionerApi.prototype.getEpisodesAssignedToMe = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PractitionerApiFp)(this.configuration).getEpisodesAssignedToMe(requestParameters.state, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a practitioner record.
     * @summary Get Practitioner
     * @param {PractitionerApiGetPractitionerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionerApi
     */
    PractitionerApi.prototype.getPractitioner = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PractitionerApiFp)(this.configuration).getPractitioner(requestParameters.practitionerId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all licenses for a practitioner.
     * @summary Get Practitioner Licenses
     * @param {PractitionerApiGetPractitionerLicensesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionerApi
     */
    PractitionerApi.prototype.getPractitionerLicenses = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PractitionerApiFp)(this.configuration).getPractitionerLicenses(requestParameters.practitionerId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all practitioner records with (optional) filtering on `status_set_at`.
     * @summary Get Practitioners
     * @param {PractitionerApiGetPractitionersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionerApi
     */
    PractitionerApi.prototype.getPractitioners = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.PractitionerApiFp)(this.configuration).getPractitioners(requestParameters.statusSince, requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a practitioner record.  If the requestor does not have the `\"er:update:practitioner:all\"` scope, the `givenName` and `familyName` fields are ignored and will not be updated.
     * @summary Update Practitioner
     * @param {PractitionerApiUpdatePractitionerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionerApi
     */
    PractitionerApi.prototype.updatePractitioner = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PractitionerApiFp)(this.configuration).updatePractitioner(requestParameters.practitionerId, requestParameters.fullPractitionerUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a license for a practitioner.
     * @summary Update Practitioner License
     * @param {PractitionerApiUpdatePractitionerLicenseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionerApi
     */
    PractitionerApi.prototype.updatePractitionerLicense = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PractitionerApiFp)(this.configuration).updatePractitionerLicense(requestParameters.practitionerId, requestParameters.licenseId, requestParameters.newLicense, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PractitionerApi;
}(base_1.BaseAPI));
exports.PractitionerApi = PractitionerApi;
/**
 * ReminderApi - axios parameter creator
 * @export
 */
var ReminderApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create a new reminder.
         * @summary Create Reminder
         * @param {NewReminder} newReminder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReminder: function (newReminder_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([newReminder_1], args_1, true), void 0, function (newReminder, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'newReminder' is not null or undefined
                            (0, common_1.assertParamExists)('createReminder', 'newReminder', newReminder);
                            localVarPath = "/v1/reminders";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newReminder, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all tags that can be used to create reminders.  Note: this data is not currently tenantized.
         * @summary Get Reminder Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReminderTags: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/reminder_tags";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all reminders.  Filtering is available on `due_at`, via the query parameter \'due_on\'. This will select reminders due before or on the provided date.  Search is available via the query parameter \'search\'.  Paging with the query parameters \'offset\' and \'limit\'.
         * @summary Get Reminders
         * @param {ReminderStatus} status
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {boolean} [createdByMe]
         * @param {string} [dueOn]
         * @param {string} [search] A string to search reminders with. Will match on tags, title, and patient name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReminders: function (status_1, offset_1, limit_1, createdByMe_1, dueOn_1, search_1) {
            var args_1 = [];
            for (var _i = 6; _i < arguments.length; _i++) {
                args_1[_i - 6] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([status_1, offset_1, limit_1, createdByMe_1, dueOn_1, search_1], args_1, true), void 0, function (status, offset, limit, createdByMe, dueOn, search, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'status' is not null or undefined
                            (0, common_1.assertParamExists)('getReminders', 'status', status);
                            localVarPath = "/v1/reminders";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (status !== undefined) {
                                localVarQueryParameter['status'] = status;
                            }
                            if (createdByMe !== undefined) {
                                localVarQueryParameter['created_by_me'] = createdByMe;
                            }
                            if (dueOn !== undefined) {
                                localVarQueryParameter['due_on'] = (dueOn instanceof Date) ?
                                    dueOn.toISOString() :
                                    dueOn;
                            }
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List reminders that can be processed.  This will select all incomplete reminders due in < 5 minutes and which have an associated trigger command.  Filtering on `retry_count` is available through the query parameter \'max_retries\'.  The number of results can be controlled with the query parameter \'limit\'.
         * @summary Get Reminders To Process
         * @param {number} [limit] Maximum number of reminders to return
         * @param {number} [maxRetries] Omit reminders that have been retried too many times
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemindersToProcess: function (limit_1, maxRetries_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([limit_1, maxRetries_1], args_1, true), void 0, function (limit, maxRetries, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/reminders_to_process";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (maxRetries !== undefined) {
                                localVarQueryParameter['max_retries'] = maxRetries;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Process a reminder.  The reminder must be automated, i.e have a `trigger_command`, otherwise this will raise.
         * @summary Process Reminder
         * @param {string} reminderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processReminder: function (reminderId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([reminderId_1], args_1, true), void 0, function (reminderId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'reminderId' is not null or undefined
                            (0, common_1.assertParamExists)('processReminder', 'reminderId', reminderId);
                            localVarPath = "/v1/reminders/{reminder_id}/process"
                                .replace("{".concat("reminder_id", "}"), encodeURIComponent(String(reminderId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update the status of a reminder.  If the requestor has the `\"physician\"` role, updating a reminder with any other type than `\"practitioner\"` will be rejected.
         * @summary Update Reminder Status
         * @param {string} reminderId
         * @param {BodyUpdateReminderStatus} bodyUpdateReminderStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReminderStatus: function (reminderId_1, bodyUpdateReminderStatus_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([reminderId_1, bodyUpdateReminderStatus_1], args_1, true), void 0, function (reminderId, bodyUpdateReminderStatus, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'reminderId' is not null or undefined
                            (0, common_1.assertParamExists)('updateReminderStatus', 'reminderId', reminderId);
                            // verify required parameter 'bodyUpdateReminderStatus' is not null or undefined
                            (0, common_1.assertParamExists)('updateReminderStatus', 'bodyUpdateReminderStatus', bodyUpdateReminderStatus);
                            localVarPath = "/v1/reminders/{reminder_id}/status"
                                .replace("{".concat("reminder_id", "}"), encodeURIComponent(String(reminderId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(bodyUpdateReminderStatus, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ReminderApiAxiosParamCreator = ReminderApiAxiosParamCreator;
/**
 * ReminderApi - functional programming interface
 * @export
 */
var ReminderApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ReminderApiAxiosParamCreator)(configuration);
    return {
        /**
         * Create a new reminder.
         * @summary Create Reminder
         * @param {NewReminder} newReminder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReminder: function (newReminder, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createReminder(newReminder, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ReminderApi.createReminder']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all tags that can be used to create reminders.  Note: this data is not currently tenantized.
         * @summary Get Reminder Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReminderTags: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getReminderTags(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ReminderApi.getReminderTags']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all reminders.  Filtering is available on `due_at`, via the query parameter \'due_on\'. This will select reminders due before or on the provided date.  Search is available via the query parameter \'search\'.  Paging with the query parameters \'offset\' and \'limit\'.
         * @summary Get Reminders
         * @param {ReminderStatus} status
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {boolean} [createdByMe]
         * @param {string} [dueOn]
         * @param {string} [search] A string to search reminders with. Will match on tags, title, and patient name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReminders: function (status, offset, limit, createdByMe, dueOn, search, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getReminders(status, offset, limit, createdByMe, dueOn, search, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ReminderApi.getReminders']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List reminders that can be processed.  This will select all incomplete reminders due in < 5 minutes and which have an associated trigger command.  Filtering on `retry_count` is available through the query parameter \'max_retries\'.  The number of results can be controlled with the query parameter \'limit\'.
         * @summary Get Reminders To Process
         * @param {number} [limit] Maximum number of reminders to return
         * @param {number} [maxRetries] Omit reminders that have been retried too many times
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemindersToProcess: function (limit, maxRetries, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRemindersToProcess(limit, maxRetries, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ReminderApi.getRemindersToProcess']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Process a reminder.  The reminder must be automated, i.e have a `trigger_command`, otherwise this will raise.
         * @summary Process Reminder
         * @param {string} reminderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processReminder: function (reminderId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.processReminder(reminderId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ReminderApi.processReminder']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update the status of a reminder.  If the requestor has the `\"physician\"` role, updating a reminder with any other type than `\"practitioner\"` will be rejected.
         * @summary Update Reminder Status
         * @param {string} reminderId
         * @param {BodyUpdateReminderStatus} bodyUpdateReminderStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReminderStatus: function (reminderId, bodyUpdateReminderStatus, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateReminderStatus(reminderId, bodyUpdateReminderStatus, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ReminderApi.updateReminderStatus']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.ReminderApiFp = ReminderApiFp;
/**
 * ReminderApi - factory interface
 * @export
 */
var ReminderApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ReminderApiFp)(configuration);
    return {
        /**
         * Create a new reminder.
         * @summary Create Reminder
         * @param {ReminderApiCreateReminderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReminder: function (requestParameters, options) {
            return localVarFp.createReminder(requestParameters.newReminder, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all tags that can be used to create reminders.  Note: this data is not currently tenantized.
         * @summary Get Reminder Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReminderTags: function (options) {
            return localVarFp.getReminderTags(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all reminders.  Filtering is available on `due_at`, via the query parameter \'due_on\'. This will select reminders due before or on the provided date.  Search is available via the query parameter \'search\'.  Paging with the query parameters \'offset\' and \'limit\'.
         * @summary Get Reminders
         * @param {ReminderApiGetRemindersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReminders: function (requestParameters, options) {
            return localVarFp.getReminders(requestParameters.status, requestParameters.offset, requestParameters.limit, requestParameters.createdByMe, requestParameters.dueOn, requestParameters.search, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List reminders that can be processed.  This will select all incomplete reminders due in < 5 minutes and which have an associated trigger command.  Filtering on `retry_count` is available through the query parameter \'max_retries\'.  The number of results can be controlled with the query parameter \'limit\'.
         * @summary Get Reminders To Process
         * @param {ReminderApiGetRemindersToProcessRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemindersToProcess: function (requestParameters, options) {
            if (requestParameters === void 0) { requestParameters = {}; }
            return localVarFp.getRemindersToProcess(requestParameters.limit, requestParameters.maxRetries, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Process a reminder.  The reminder must be automated, i.e have a `trigger_command`, otherwise this will raise.
         * @summary Process Reminder
         * @param {ReminderApiProcessReminderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processReminder: function (requestParameters, options) {
            return localVarFp.processReminder(requestParameters.reminderId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update the status of a reminder.  If the requestor has the `\"physician\"` role, updating a reminder with any other type than `\"practitioner\"` will be rejected.
         * @summary Update Reminder Status
         * @param {ReminderApiUpdateReminderStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReminderStatus: function (requestParameters, options) {
            return localVarFp.updateReminderStatus(requestParameters.reminderId, requestParameters.bodyUpdateReminderStatus, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ReminderApiFactory = ReminderApiFactory;
/**
 * ReminderApi - object-oriented interface
 * @export
 * @class ReminderApi
 * @extends {BaseAPI}
 */
var ReminderApi = /** @class */ (function (_super) {
    __extends(ReminderApi, _super);
    function ReminderApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create a new reminder.
     * @summary Create Reminder
     * @param {ReminderApiCreateReminderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    ReminderApi.prototype.createReminder = function (requestParameters, options) {
        var _this = this;
        return (0, exports.ReminderApiFp)(this.configuration).createReminder(requestParameters.newReminder, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all tags that can be used to create reminders.  Note: this data is not currently tenantized.
     * @summary Get Reminder Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    ReminderApi.prototype.getReminderTags = function (options) {
        var _this = this;
        return (0, exports.ReminderApiFp)(this.configuration).getReminderTags(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all reminders.  Filtering is available on `due_at`, via the query parameter \'due_on\'. This will select reminders due before or on the provided date.  Search is available via the query parameter \'search\'.  Paging with the query parameters \'offset\' and \'limit\'.
     * @summary Get Reminders
     * @param {ReminderApiGetRemindersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    ReminderApi.prototype.getReminders = function (requestParameters, options) {
        var _this = this;
        return (0, exports.ReminderApiFp)(this.configuration).getReminders(requestParameters.status, requestParameters.offset, requestParameters.limit, requestParameters.createdByMe, requestParameters.dueOn, requestParameters.search, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List reminders that can be processed.  This will select all incomplete reminders due in < 5 minutes and which have an associated trigger command.  Filtering on `retry_count` is available through the query parameter \'max_retries\'.  The number of results can be controlled with the query parameter \'limit\'.
     * @summary Get Reminders To Process
     * @param {ReminderApiGetRemindersToProcessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    ReminderApi.prototype.getRemindersToProcess = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.ReminderApiFp)(this.configuration).getRemindersToProcess(requestParameters.limit, requestParameters.maxRetries, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Process a reminder.  The reminder must be automated, i.e have a `trigger_command`, otherwise this will raise.
     * @summary Process Reminder
     * @param {ReminderApiProcessReminderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    ReminderApi.prototype.processReminder = function (requestParameters, options) {
        var _this = this;
        return (0, exports.ReminderApiFp)(this.configuration).processReminder(requestParameters.reminderId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update the status of a reminder.  If the requestor has the `\"physician\"` role, updating a reminder with any other type than `\"practitioner\"` will be rejected.
     * @summary Update Reminder Status
     * @param {ReminderApiUpdateReminderStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    ReminderApi.prototype.updateReminderStatus = function (requestParameters, options) {
        var _this = this;
        return (0, exports.ReminderApiFp)(this.configuration).updateReminderStatus(requestParameters.reminderId, requestParameters.bodyUpdateReminderStatus, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ReminderApi;
}(base_1.BaseAPI));
exports.ReminderApi = ReminderApi;
/**
 * TasksApi - axios parameter creator
 * @export
 */
var TasksApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Add a new comment for a task.
         * @summary Add Comment
         * @param {string} taskId
         * @param {NewTaskComment} newTaskComment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment: function (taskId_1, newTaskComment_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([taskId_1, newTaskComment_1], args_1, true), void 0, function (taskId, newTaskComment, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'taskId' is not null or undefined
                            (0, common_1.assertParamExists)('addComment', 'taskId', taskId);
                            // verify required parameter 'newTaskComment' is not null or undefined
                            (0, common_1.assertParamExists)('addComment', 'newTaskComment', newTaskComment);
                            localVarPath = "/v1/tasks/{task_id}/comments"
                                .replace("{".concat("task_id", "}"), encodeURIComponent(String(taskId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newTaskComment, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a new task.
         * @summary Create Task
         * @param {NewTask} newTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask: function (newTask_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([newTask_1], args_1, true), void 0, function (newTask, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'newTask' is not null or undefined
                            (0, common_1.assertParamExists)('createTask', 'newTask', newTask);
                            localVarPath = "/v1/tasks/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newTask, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Subscribe the current user to the comments of a given task.  If the user is already subscribed, this updates the subscription status.
         * @summary Create Task Comments Subscription
         * @param {string} taskId
         * @param {TaskSubscriptionUpsertRequest} taskSubscriptionUpsertRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskCommentsSubscription: function (taskId_1, taskSubscriptionUpsertRequest_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([taskId_1, taskSubscriptionUpsertRequest_1], args_1, true), void 0, function (taskId, taskSubscriptionUpsertRequest, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'taskId' is not null or undefined
                            (0, common_1.assertParamExists)('createTaskCommentsSubscription', 'taskId', taskId);
                            // verify required parameter 'taskSubscriptionUpsertRequest' is not null or undefined
                            (0, common_1.assertParamExists)('createTaskCommentsSubscription', 'taskSubscriptionUpsertRequest', taskSubscriptionUpsertRequest);
                            localVarPath = "/v1/tasks/{task_id}/comments/subscriptions"
                                .replace("{".concat("task_id", "}"), encodeURIComponent(String(taskId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(taskSubscriptionUpsertRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get comment subscriptions for the current user
         * @summary Get Comment Subscriptions
         * @param {SubscriptionStatus} [subscriptionStatus] Filter subscriptions by subscription status
         * @param {TaskStatus} [taskStatus] Filter subscriptions by task status
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentSubscriptions: function (subscriptionStatus_1, taskStatus_1, offset_1, limit_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([subscriptionStatus_1, taskStatus_1, offset_1, limit_1], args_1, true), void 0, function (subscriptionStatus, taskStatus, offset, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/tasks/comments/subscriptions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (subscriptionStatus !== undefined) {
                                localVarQueryParameter['subscription_status'] = subscriptionStatus;
                            }
                            if (taskStatus !== undefined) {
                                localVarQueryParameter['task_status'] = taskStatus;
                            }
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a task by its ID.
         * @summary Get Task
         * @param {string} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask: function (taskId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([taskId_1], args_1, true), void 0, function (taskId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'taskId' is not null or undefined
                            (0, common_1.assertParamExists)('getTask', 'taskId', taskId);
                            localVarPath = "/v1/tasks/{task_id}"
                                .replace("{".concat("task_id", "}"), encodeURIComponent(String(taskId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get all activity related to a parent task and optionally its sub tasks. Returns the latest activity first.
         * @summary Get Task Activity
         * @param {string} taskId
         * @param {boolean} [includeSubtasks] Include activities from subtasks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskActivity: function (taskId_1, includeSubtasks_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([taskId_1, includeSubtasks_1], args_1, true), void 0, function (taskId, includeSubtasks, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'taskId' is not null or undefined
                            (0, common_1.assertParamExists)('getTaskActivity', 'taskId', taskId);
                            localVarPath = "/v1/tasks/{task_id}/activities"
                                .replace("{".concat("task_id", "}"), encodeURIComponent(String(taskId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (includeSubtasks !== undefined) {
                                localVarQueryParameter['include_subtasks'] = includeSubtasks;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all comments for a task.
         * @summary Get Task Comments
         * @param {string} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskComments: function (taskId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([taskId_1], args_1, true), void 0, function (taskId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'taskId' is not null or undefined
                            (0, common_1.assertParamExists)('getTaskComments', 'taskId', taskId);
                            localVarPath = "/v1/tasks/{task_id}/comments"
                                .replace("{".concat("task_id", "}"), encodeURIComponent(String(taskId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get the subscriptions of the current user to the comments of a given task.  This returns a 404 if the user is not subscribed.
         * @summary Get Task Comments Subscriptions
         * @param {string} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskCommentsSubscriptions: function (taskId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([taskId_1], args_1, true), void 0, function (taskId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'taskId' is not null or undefined
                            (0, common_1.assertParamExists)('getTaskCommentsSubscriptions', 'taskId', taskId);
                            localVarPath = "/v1/tasks/{task_id}/comments/subscriptions"
                                .replace("{".concat("task_id", "}"), encodeURIComponent(String(taskId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get all task templates for the current tenant.
         * @summary Get Task Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskTemplates: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/tasks/templates";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all tasks with (optional) filtering and sorting.  Available filters: - episode_id - status (repeated) - priority (repeated) - assignee_id (repeated) - team_ids (repeated) - scheduled_for (range) - title - parent_id - document_id  By default sorting is done on `created_at` (oldest first)     then `scheduled_for` (oldest first). When the `sort_by` parameter is explicitly provided:     - if set to `scheduled_for`, secondary sorting is done         on `priority` (highest priority first);     - if set to any other value, secondary sorting is done         on `scheduled_for` (oldest first). Available sorting: any column on the Task object.
         * @summary Get Tasks
         * @param {string} [episodeId]
         * @param {Array<TaskStatus>} [status] Filter tasks with any of the provided statuses
         * @param {Array<TaskPriority>} [priority] Filter tasks with any of the provided priorities
         * @param {Array<number>} [assigneeId] Filter tasks with any of the provided assignee IDs
         * @param {Array<number>} [teamId] Filter tasks with any of the provided team IDs
         * @param {string} [title] Filter tasks by titles containing the specified substring
         * @param {string | null} [parentId] Filter tasks by parent_id. To filter out subtasks, you can pass &#x60;null&#x60; here.
         * @param {string} [documentId]
         * @param {SortOrderEnum} [sortOrder] Sorting order (\&#39;asc\&#39; or \&#39;desc\&#39;). Default: asc
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {string} [scheduledAfter] Filter tasks scheduled after or at the provided ISO timestamp.
         * @param {string} [scheduledBefore] Filter tasks scheduled before or at the provided ISO timestamp.
         * @param {string} [sortBy] Column to sort by. Default: created_at
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks: function (episodeId_1, status_1, priority_1, assigneeId_1, teamId_1, title_1, parentId_1, documentId_1, sortOrder_1, offset_1, limit_1, scheduledAfter_1, scheduledBefore_1, sortBy_1) {
            var args_1 = [];
            for (var _i = 14; _i < arguments.length; _i++) {
                args_1[_i - 14] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1, status_1, priority_1, assigneeId_1, teamId_1, title_1, parentId_1, documentId_1, sortOrder_1, offset_1, limit_1, scheduledAfter_1, scheduledBefore_1, sortBy_1], args_1, true), void 0, function (episodeId, status, priority, assigneeId, teamId, title, parentId, documentId, sortOrder, offset, limit, scheduledAfter, scheduledBefore, sortBy, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/tasks/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (episodeId !== undefined) {
                                localVarQueryParameter['episode_id'] = episodeId;
                            }
                            if (status) {
                                localVarQueryParameter['status'] = Array.from(status);
                            }
                            if (priority) {
                                localVarQueryParameter['priority'] = Array.from(priority);
                            }
                            if (assigneeId) {
                                localVarQueryParameter['assignee_id'] = Array.from(assigneeId);
                            }
                            if (teamId) {
                                localVarQueryParameter['team_id'] = Array.from(teamId);
                            }
                            if (title !== undefined) {
                                localVarQueryParameter['title'] = title;
                            }
                            if (parentId !== undefined) {
                                localVarQueryParameter['parent_id'] = parentId;
                            }
                            if (documentId !== undefined) {
                                localVarQueryParameter['document_id'] = documentId;
                            }
                            if (sortOrder !== undefined) {
                                localVarQueryParameter['sort_order'] = sortOrder;
                            }
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (scheduledAfter !== undefined) {
                                localVarQueryParameter['scheduled_after'] = (scheduledAfter instanceof Date) ?
                                    scheduledAfter.toISOString() :
                                    scheduledAfter;
                            }
                            if (scheduledBefore !== undefined) {
                                localVarQueryParameter['scheduled_before'] = (scheduledBefore instanceof Date) ?
                                    scheduledBefore.toISOString() :
                                    scheduledBefore;
                            }
                            if (sortBy !== undefined) {
                                localVarQueryParameter['sort_by'] = sortBy;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Teams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeams: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/tasks/teams";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get the count of unread comments for the current user
         * @summary Get Unread Comments Count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadCommentsCount: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/tasks/comments/subscriptions/unread_count";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a task.
         * @summary Update Task
         * @param {string} taskId
         * @param {UpdateTask} updateTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask: function (taskId_1, updateTask_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([taskId_1, updateTask_1], args_1, true), void 0, function (taskId, updateTask, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'taskId' is not null or undefined
                            (0, common_1.assertParamExists)('updateTask', 'taskId', taskId);
                            // verify required parameter 'updateTask' is not null or undefined
                            (0, common_1.assertParamExists)('updateTask', 'updateTask', updateTask);
                            localVarPath = "/v1/tasks/{task_id}"
                                .replace("{".concat("task_id", "}"), encodeURIComponent(String(taskId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateTask, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update the current user\'s subscription to the comments of a given task.  If the user is not subscribed, this will NOT create a subscription.
         * @summary Update Task Comments Subscription
         * @param {string} taskId
         * @param {TaskSubscriptionUpdateRequest} taskSubscriptionUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskCommentsSubscription: function (taskId_1, taskSubscriptionUpdateRequest_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([taskId_1, taskSubscriptionUpdateRequest_1], args_1, true), void 0, function (taskId, taskSubscriptionUpdateRequest, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'taskId' is not null or undefined
                            (0, common_1.assertParamExists)('updateTaskCommentsSubscription', 'taskId', taskId);
                            // verify required parameter 'taskSubscriptionUpdateRequest' is not null or undefined
                            (0, common_1.assertParamExists)('updateTaskCommentsSubscription', 'taskSubscriptionUpdateRequest', taskSubscriptionUpdateRequest);
                            localVarPath = "/v1/tasks/{task_id}/comments/subscriptions"
                                .replace("{".concat("task_id", "}"), encodeURIComponent(String(taskId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(taskSubscriptionUpdateRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update status of a task.
         * @summary Update Task Status
         * @param {string} taskId
         * @param {UpdateTaskStatusRequest} updateTaskStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskStatus: function (taskId_1, updateTaskStatusRequest_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([taskId_1, updateTaskStatusRequest_1], args_1, true), void 0, function (taskId, updateTaskStatusRequest, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'taskId' is not null or undefined
                            (0, common_1.assertParamExists)('updateTaskStatus', 'taskId', taskId);
                            // verify required parameter 'updateTaskStatusRequest' is not null or undefined
                            (0, common_1.assertParamExists)('updateTaskStatus', 'updateTaskStatusRequest', updateTaskStatusRequest);
                            localVarPath = "/v1/tasks/{task_id}/status"
                                .replace("{".concat("task_id", "}"), encodeURIComponent(String(taskId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateTaskStatusRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TasksApiAxiosParamCreator = TasksApiAxiosParamCreator;
/**
 * TasksApi - functional programming interface
 * @export
 */
var TasksApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TasksApiAxiosParamCreator)(configuration);
    return {
        /**
         * Add a new comment for a task.
         * @summary Add Comment
         * @param {string} taskId
         * @param {NewTaskComment} newTaskComment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment: function (taskId, newTaskComment, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addComment(taskId, newTaskComment, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.addComment']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Create a new task.
         * @summary Create Task
         * @param {NewTask} newTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask: function (newTask, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createTask(newTask, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.createTask']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Subscribe the current user to the comments of a given task.  If the user is already subscribed, this updates the subscription status.
         * @summary Create Task Comments Subscription
         * @param {string} taskId
         * @param {TaskSubscriptionUpsertRequest} taskSubscriptionUpsertRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskCommentsSubscription: function (taskId, taskSubscriptionUpsertRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createTaskCommentsSubscription(taskId, taskSubscriptionUpsertRequest, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.createTaskCommentsSubscription']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get comment subscriptions for the current user
         * @summary Get Comment Subscriptions
         * @param {SubscriptionStatus} [subscriptionStatus] Filter subscriptions by subscription status
         * @param {TaskStatus} [taskStatus] Filter subscriptions by task status
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentSubscriptions: function (subscriptionStatus, taskStatus, offset, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCommentSubscriptions(subscriptionStatus, taskStatus, offset, limit, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.getCommentSubscriptions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get a task by its ID.
         * @summary Get Task
         * @param {string} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask: function (taskId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTask(taskId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.getTask']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get all activity related to a parent task and optionally its sub tasks. Returns the latest activity first.
         * @summary Get Task Activity
         * @param {string} taskId
         * @param {boolean} [includeSubtasks] Include activities from subtasks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskActivity: function (taskId, includeSubtasks, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTaskActivity(taskId, includeSubtasks, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.getTaskActivity']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all comments for a task.
         * @summary Get Task Comments
         * @param {string} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskComments: function (taskId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTaskComments(taskId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.getTaskComments']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get the subscriptions of the current user to the comments of a given task.  This returns a 404 if the user is not subscribed.
         * @summary Get Task Comments Subscriptions
         * @param {string} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskCommentsSubscriptions: function (taskId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTaskCommentsSubscriptions(taskId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.getTaskCommentsSubscriptions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get all task templates for the current tenant.
         * @summary Get Task Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskTemplates: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTaskTemplates(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.getTaskTemplates']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all tasks with (optional) filtering and sorting.  Available filters: - episode_id - status (repeated) - priority (repeated) - assignee_id (repeated) - team_ids (repeated) - scheduled_for (range) - title - parent_id - document_id  By default sorting is done on `created_at` (oldest first)     then `scheduled_for` (oldest first). When the `sort_by` parameter is explicitly provided:     - if set to `scheduled_for`, secondary sorting is done         on `priority` (highest priority first);     - if set to any other value, secondary sorting is done         on `scheduled_for` (oldest first). Available sorting: any column on the Task object.
         * @summary Get Tasks
         * @param {string} [episodeId]
         * @param {Array<TaskStatus>} [status] Filter tasks with any of the provided statuses
         * @param {Array<TaskPriority>} [priority] Filter tasks with any of the provided priorities
         * @param {Array<number>} [assigneeId] Filter tasks with any of the provided assignee IDs
         * @param {Array<number>} [teamId] Filter tasks with any of the provided team IDs
         * @param {string} [title] Filter tasks by titles containing the specified substring
         * @param {string | null} [parentId] Filter tasks by parent_id. To filter out subtasks, you can pass &#x60;null&#x60; here.
         * @param {string} [documentId]
         * @param {SortOrderEnum} [sortOrder] Sorting order (\&#39;asc\&#39; or \&#39;desc\&#39;). Default: asc
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {string} [scheduledAfter] Filter tasks scheduled after or at the provided ISO timestamp.
         * @param {string} [scheduledBefore] Filter tasks scheduled before or at the provided ISO timestamp.
         * @param {string} [sortBy] Column to sort by. Default: created_at
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks: function (episodeId, status, priority, assigneeId, teamId, title, parentId, documentId, sortOrder, offset, limit, scheduledAfter, scheduledBefore, sortBy, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTasks(episodeId, status, priority, assigneeId, teamId, title, parentId, documentId, sortOrder, offset, limit, scheduledAfter, scheduledBefore, sortBy, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.getTasks']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Teams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeams: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTeams(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.getTeams']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get the count of unread comments for the current user
         * @summary Get Unread Comments Count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadCommentsCount: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUnreadCommentsCount(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.getUnreadCommentsCount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update a task.
         * @summary Update Task
         * @param {string} taskId
         * @param {UpdateTask} updateTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask: function (taskId, updateTask, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTask(taskId, updateTask, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.updateTask']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update the current user\'s subscription to the comments of a given task.  If the user is not subscribed, this will NOT create a subscription.
         * @summary Update Task Comments Subscription
         * @param {string} taskId
         * @param {TaskSubscriptionUpdateRequest} taskSubscriptionUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskCommentsSubscription: function (taskId, taskSubscriptionUpdateRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTaskCommentsSubscription(taskId, taskSubscriptionUpdateRequest, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.updateTaskCommentsSubscription']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update status of a task.
         * @summary Update Task Status
         * @param {string} taskId
         * @param {UpdateTaskStatusRequest} updateTaskStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskStatus: function (taskId, updateTaskStatusRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTaskStatus(taskId, updateTaskStatusRequest, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.updateTaskStatus']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.TasksApiFp = TasksApiFp;
/**
 * TasksApi - factory interface
 * @export
 */
var TasksApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TasksApiFp)(configuration);
    return {
        /**
         * Add a new comment for a task.
         * @summary Add Comment
         * @param {TasksApiAddCommentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment: function (requestParameters, options) {
            return localVarFp.addComment(requestParameters.taskId, requestParameters.newTaskComment, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a new task.
         * @summary Create Task
         * @param {TasksApiCreateTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask: function (requestParameters, options) {
            return localVarFp.createTask(requestParameters.newTask, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Subscribe the current user to the comments of a given task.  If the user is already subscribed, this updates the subscription status.
         * @summary Create Task Comments Subscription
         * @param {TasksApiCreateTaskCommentsSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskCommentsSubscription: function (requestParameters, options) {
            return localVarFp.createTaskCommentsSubscription(requestParameters.taskId, requestParameters.taskSubscriptionUpsertRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get comment subscriptions for the current user
         * @summary Get Comment Subscriptions
         * @param {TasksApiGetCommentSubscriptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentSubscriptions: function (requestParameters, options) {
            if (requestParameters === void 0) { requestParameters = {}; }
            return localVarFp.getCommentSubscriptions(requestParameters.subscriptionStatus, requestParameters.taskStatus, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a task by its ID.
         * @summary Get Task
         * @param {TasksApiGetTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask: function (requestParameters, options) {
            return localVarFp.getTask(requestParameters.taskId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get all activity related to a parent task and optionally its sub tasks. Returns the latest activity first.
         * @summary Get Task Activity
         * @param {TasksApiGetTaskActivityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskActivity: function (requestParameters, options) {
            return localVarFp.getTaskActivity(requestParameters.taskId, requestParameters.includeSubtasks, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all comments for a task.
         * @summary Get Task Comments
         * @param {TasksApiGetTaskCommentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskComments: function (requestParameters, options) {
            return localVarFp.getTaskComments(requestParameters.taskId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get the subscriptions of the current user to the comments of a given task.  This returns a 404 if the user is not subscribed.
         * @summary Get Task Comments Subscriptions
         * @param {TasksApiGetTaskCommentsSubscriptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskCommentsSubscriptions: function (requestParameters, options) {
            return localVarFp.getTaskCommentsSubscriptions(requestParameters.taskId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get all task templates for the current tenant.
         * @summary Get Task Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskTemplates: function (options) {
            return localVarFp.getTaskTemplates(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all tasks with (optional) filtering and sorting.  Available filters: - episode_id - status (repeated) - priority (repeated) - assignee_id (repeated) - team_ids (repeated) - scheduled_for (range) - title - parent_id - document_id  By default sorting is done on `created_at` (oldest first)     then `scheduled_for` (oldest first). When the `sort_by` parameter is explicitly provided:     - if set to `scheduled_for`, secondary sorting is done         on `priority` (highest priority first);     - if set to any other value, secondary sorting is done         on `scheduled_for` (oldest first). Available sorting: any column on the Task object.
         * @summary Get Tasks
         * @param {TasksApiGetTasksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks: function (requestParameters, options) {
            if (requestParameters === void 0) { requestParameters = {}; }
            return localVarFp.getTasks(requestParameters.episodeId, requestParameters.status, requestParameters.priority, requestParameters.assigneeId, requestParameters.teamId, requestParameters.title, requestParameters.parentId, requestParameters.documentId, requestParameters.sortOrder, requestParameters.offset, requestParameters.limit, requestParameters.scheduledAfter, requestParameters.scheduledBefore, requestParameters.sortBy, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Teams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeams: function (options) {
            return localVarFp.getTeams(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get the count of unread comments for the current user
         * @summary Get Unread Comments Count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadCommentsCount: function (options) {
            return localVarFp.getUnreadCommentsCount(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a task.
         * @summary Update Task
         * @param {TasksApiUpdateTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask: function (requestParameters, options) {
            return localVarFp.updateTask(requestParameters.taskId, requestParameters.updateTask, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update the current user\'s subscription to the comments of a given task.  If the user is not subscribed, this will NOT create a subscription.
         * @summary Update Task Comments Subscription
         * @param {TasksApiUpdateTaskCommentsSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskCommentsSubscription: function (requestParameters, options) {
            return localVarFp.updateTaskCommentsSubscription(requestParameters.taskId, requestParameters.taskSubscriptionUpdateRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update status of a task.
         * @summary Update Task Status
         * @param {TasksApiUpdateTaskStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskStatus: function (requestParameters, options) {
            return localVarFp.updateTaskStatus(requestParameters.taskId, requestParameters.updateTaskStatusRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TasksApiFactory = TasksApiFactory;
/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
var TasksApi = /** @class */ (function (_super) {
    __extends(TasksApi, _super);
    function TasksApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Add a new comment for a task.
     * @summary Add Comment
     * @param {TasksApiAddCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.addComment = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).addComment(requestParameters.taskId, requestParameters.newTaskComment, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a new task.
     * @summary Create Task
     * @param {TasksApiCreateTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.createTask = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).createTask(requestParameters.newTask, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Subscribe the current user to the comments of a given task.  If the user is already subscribed, this updates the subscription status.
     * @summary Create Task Comments Subscription
     * @param {TasksApiCreateTaskCommentsSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.createTaskCommentsSubscription = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).createTaskCommentsSubscription(requestParameters.taskId, requestParameters.taskSubscriptionUpsertRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get comment subscriptions for the current user
     * @summary Get Comment Subscriptions
     * @param {TasksApiGetCommentSubscriptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.getCommentSubscriptions = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.TasksApiFp)(this.configuration).getCommentSubscriptions(requestParameters.subscriptionStatus, requestParameters.taskStatus, requestParameters.offset, requestParameters.limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a task by its ID.
     * @summary Get Task
     * @param {TasksApiGetTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.getTask = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).getTask(requestParameters.taskId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get all activity related to a parent task and optionally its sub tasks. Returns the latest activity first.
     * @summary Get Task Activity
     * @param {TasksApiGetTaskActivityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.getTaskActivity = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).getTaskActivity(requestParameters.taskId, requestParameters.includeSubtasks, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all comments for a task.
     * @summary Get Task Comments
     * @param {TasksApiGetTaskCommentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.getTaskComments = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).getTaskComments(requestParameters.taskId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get the subscriptions of the current user to the comments of a given task.  This returns a 404 if the user is not subscribed.
     * @summary Get Task Comments Subscriptions
     * @param {TasksApiGetTaskCommentsSubscriptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.getTaskCommentsSubscriptions = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).getTaskCommentsSubscriptions(requestParameters.taskId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get all task templates for the current tenant.
     * @summary Get Task Templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.getTaskTemplates = function (options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).getTaskTemplates(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all tasks with (optional) filtering and sorting.  Available filters: - episode_id - status (repeated) - priority (repeated) - assignee_id (repeated) - team_ids (repeated) - scheduled_for (range) - title - parent_id - document_id  By default sorting is done on `created_at` (oldest first)     then `scheduled_for` (oldest first). When the `sort_by` parameter is explicitly provided:     - if set to `scheduled_for`, secondary sorting is done         on `priority` (highest priority first);     - if set to any other value, secondary sorting is done         on `scheduled_for` (oldest first). Available sorting: any column on the Task object.
     * @summary Get Tasks
     * @param {TasksApiGetTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.getTasks = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.TasksApiFp)(this.configuration).getTasks(requestParameters.episodeId, requestParameters.status, requestParameters.priority, requestParameters.assigneeId, requestParameters.teamId, requestParameters.title, requestParameters.parentId, requestParameters.documentId, requestParameters.sortOrder, requestParameters.offset, requestParameters.limit, requestParameters.scheduledAfter, requestParameters.scheduledBefore, requestParameters.sortBy, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Teams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.getTeams = function (options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).getTeams(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get the count of unread comments for the current user
     * @summary Get Unread Comments Count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.getUnreadCommentsCount = function (options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).getUnreadCommentsCount(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a task.
     * @summary Update Task
     * @param {TasksApiUpdateTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.updateTask = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).updateTask(requestParameters.taskId, requestParameters.updateTask, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update the current user\'s subscription to the comments of a given task.  If the user is not subscribed, this will NOT create a subscription.
     * @summary Update Task Comments Subscription
     * @param {TasksApiUpdateTaskCommentsSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.updateTaskCommentsSubscription = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).updateTaskCommentsSubscription(requestParameters.taskId, requestParameters.taskSubscriptionUpdateRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update status of a task.
     * @summary Update Task Status
     * @param {TasksApiUpdateTaskStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.updateTaskStatus = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).updateTaskStatus(requestParameters.taskId, requestParameters.updateTaskStatusRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TasksApi;
}(base_1.BaseAPI));
exports.TasksApi = TasksApi;
/**
 * TeamApi - axios parameter creator
 * @export
 */
var TeamApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * List all episodes for a queue.  The returned episodes are sorted by: - priority (\"ER\" first) - then, `queued_at` (oldest first).
         * @summary Get Queue Episodes
         * @param {string} teamId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueueEpisodes: function (teamId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([teamId_1], args_1, true), void 0, function (teamId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'teamId' is not null or undefined
                            (0, common_1.assertParamExists)('getQueueEpisodes', 'teamId', teamId);
                            localVarPath = "/v1/teams/{team_id}/episodes"
                                .replace("{".concat("team_id", "}"), encodeURIComponent(String(teamId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all queues for a given tenant.  The returned queues are sorted by `sort_order` (lowest first).
         * @summary Get Queues
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueues: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/teams";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update the warning and danger thresholds for a queue.
         * @summary Update Queue Thresholds
         * @param {string} teamId
         * @param {UpdateTeamThresholds} updateTeamThresholds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQueueThresholds: function (teamId_1, updateTeamThresholds_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([teamId_1, updateTeamThresholds_1], args_1, true), void 0, function (teamId, updateTeamThresholds, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'teamId' is not null or undefined
                            (0, common_1.assertParamExists)('updateQueueThresholds', 'teamId', teamId);
                            // verify required parameter 'updateTeamThresholds' is not null or undefined
                            (0, common_1.assertParamExists)('updateQueueThresholds', 'updateTeamThresholds', updateTeamThresholds);
                            localVarPath = "/v1/teams/{team_id}/thresholds"
                                .replace("{".concat("team_id", "}"), encodeURIComponent(String(teamId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateTeamThresholds, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TeamApiAxiosParamCreator = TeamApiAxiosParamCreator;
/**
 * TeamApi - functional programming interface
 * @export
 */
var TeamApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TeamApiAxiosParamCreator)(configuration);
    return {
        /**
         * List all episodes for a queue.  The returned episodes are sorted by: - priority (\"ER\" first) - then, `queued_at` (oldest first).
         * @summary Get Queue Episodes
         * @param {string} teamId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueueEpisodes: function (teamId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getQueueEpisodes(teamId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TeamApi.getQueueEpisodes']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all queues for a given tenant.  The returned queues are sorted by `sort_order` (lowest first).
         * @summary Get Queues
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueues: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getQueues(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TeamApi.getQueues']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update the warning and danger thresholds for a queue.
         * @summary Update Queue Thresholds
         * @param {string} teamId
         * @param {UpdateTeamThresholds} updateTeamThresholds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQueueThresholds: function (teamId, updateTeamThresholds, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateQueueThresholds(teamId, updateTeamThresholds, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TeamApi.updateQueueThresholds']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.TeamApiFp = TeamApiFp;
/**
 * TeamApi - factory interface
 * @export
 */
var TeamApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TeamApiFp)(configuration);
    return {
        /**
         * List all episodes for a queue.  The returned episodes are sorted by: - priority (\"ER\" first) - then, `queued_at` (oldest first).
         * @summary Get Queue Episodes
         * @param {TeamApiGetQueueEpisodesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueueEpisodes: function (requestParameters, options) {
            return localVarFp.getQueueEpisodes(requestParameters.teamId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all queues for a given tenant.  The returned queues are sorted by `sort_order` (lowest first).
         * @summary Get Queues
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueues: function (options) {
            return localVarFp.getQueues(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update the warning and danger thresholds for a queue.
         * @summary Update Queue Thresholds
         * @param {TeamApiUpdateQueueThresholdsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQueueThresholds: function (requestParameters, options) {
            return localVarFp.updateQueueThresholds(requestParameters.teamId, requestParameters.updateTeamThresholds, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TeamApiFactory = TeamApiFactory;
/**
 * TeamApi - object-oriented interface
 * @export
 * @class TeamApi
 * @extends {BaseAPI}
 */
var TeamApi = /** @class */ (function (_super) {
    __extends(TeamApi, _super);
    function TeamApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * List all episodes for a queue.  The returned episodes are sorted by: - priority (\"ER\" first) - then, `queued_at` (oldest first).
     * @summary Get Queue Episodes
     * @param {TeamApiGetQueueEpisodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    TeamApi.prototype.getQueueEpisodes = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TeamApiFp)(this.configuration).getQueueEpisodes(requestParameters.teamId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all queues for a given tenant.  The returned queues are sorted by `sort_order` (lowest first).
     * @summary Get Queues
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    TeamApi.prototype.getQueues = function (options) {
        var _this = this;
        return (0, exports.TeamApiFp)(this.configuration).getQueues(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update the warning and danger thresholds for a queue.
     * @summary Update Queue Thresholds
     * @param {TeamApiUpdateQueueThresholdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    TeamApi.prototype.updateQueueThresholds = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TeamApiFp)(this.configuration).updateQueueThresholds(requestParameters.teamId, requestParameters.updateTeamThresholds, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TeamApi;
}(base_1.BaseAPI));
exports.TeamApi = TeamApi;
/**
 * WebhookApi - axios parameter creator
 * @export
 */
var WebhookApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Send a new webhook event with episode data
         * @summary Send Episode Webhook Event
         * @param {string} episodeId
         * @param {BodySendEpisodeWebhookEvent} bodySendEpisodeWebhookEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEpisodeWebhookEvent: function (episodeId_1, bodySendEpisodeWebhookEvent_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([episodeId_1, bodySendEpisodeWebhookEvent_1], args_1, true), void 0, function (episodeId, bodySendEpisodeWebhookEvent, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'episodeId' is not null or undefined
                            (0, common_1.assertParamExists)('sendEpisodeWebhookEvent', 'episodeId', episodeId);
                            // verify required parameter 'bodySendEpisodeWebhookEvent' is not null or undefined
                            (0, common_1.assertParamExists)('sendEpisodeWebhookEvent', 'bodySendEpisodeWebhookEvent', bodySendEpisodeWebhookEvent);
                            localVarPath = "/v1/webhook/episode/{episode_id}"
                                .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(bodySendEpisodeWebhookEvent, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.WebhookApiAxiosParamCreator = WebhookApiAxiosParamCreator;
/**
 * WebhookApi - functional programming interface
 * @export
 */
var WebhookApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.WebhookApiAxiosParamCreator)(configuration);
    return {
        /**
         * Send a new webhook event with episode data
         * @summary Send Episode Webhook Event
         * @param {string} episodeId
         * @param {BodySendEpisodeWebhookEvent} bodySendEpisodeWebhookEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEpisodeWebhookEvent: function (episodeId, bodySendEpisodeWebhookEvent, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendEpisodeWebhookEvent(episodeId, bodySendEpisodeWebhookEvent, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['WebhookApi.sendEpisodeWebhookEvent']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.WebhookApiFp = WebhookApiFp;
/**
 * WebhookApi - factory interface
 * @export
 */
var WebhookApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.WebhookApiFp)(configuration);
    return {
        /**
         * Send a new webhook event with episode data
         * @summary Send Episode Webhook Event
         * @param {WebhookApiSendEpisodeWebhookEventRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEpisodeWebhookEvent: function (requestParameters, options) {
            return localVarFp.sendEpisodeWebhookEvent(requestParameters.episodeId, requestParameters.bodySendEpisodeWebhookEvent, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.WebhookApiFactory = WebhookApiFactory;
/**
 * WebhookApi - object-oriented interface
 * @export
 * @class WebhookApi
 * @extends {BaseAPI}
 */
var WebhookApi = /** @class */ (function (_super) {
    __extends(WebhookApi, _super);
    function WebhookApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Send a new webhook event with episode data
     * @summary Send Episode Webhook Event
     * @param {WebhookApiSendEpisodeWebhookEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApi
     */
    WebhookApi.prototype.sendEpisodeWebhookEvent = function (requestParameters, options) {
        var _this = this;
        return (0, exports.WebhookApiFp)(this.configuration).sendEpisodeWebhookEvent(requestParameters.episodeId, requestParameters.bodySendEpisodeWebhookEvent, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return WebhookApi;
}(base_1.BaseAPI));
exports.WebhookApi = WebhookApi;
