"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PushIt = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../base-service");
var PushIt = /** @class */ (function (_super) {
    tslib_1.__extends(PushIt, _super);
    function PushIt() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.registerPractitionerDevice = function (practitioner_id, device) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v1/practitioner/".concat(practitioner_id, "/device");
                        return [4 /*yield*/, this.api.post(url, device)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, response)];
                }
            });
        }); };
        _this.deletePractitionerDevice = function (practitioner_id) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v1/practitioner/".concat(practitioner_id, "/device");
                        return [4 /*yield*/, this.api.delete(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, response)];
                }
            });
        }); };
        return _this;
    }
    return PushIt;
}(base_service_1.BaseService));
exports.PushIt = PushIt;
