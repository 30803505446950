"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Service = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../base-service");
var utils_1 = require("../utils");
var Service = /** @class */ (function (_super) {
    tslib_1.__extends(Service, _super);
    function Service() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * POST /v1/logins
         * Login user to the wellness service
         */
        _this.login = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v1/logins";
                        return [4 /*yield*/, this.api.post(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * GET /generic
         * Get Generic Active minutes
         */
        _this.getActiveMinutes = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getActiveMinutes', params, ['from', 'to']);
                        url = "/generic";
                        return [4 /*yield*/, this.api.get(url, {
                                from: params === null || params === void 0 ? void 0 : params.from,
                                to: params === null || params === void 0 ? void 0 : params.to,
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * GET /history
         * Get Active Minutes History
         */
        _this.getHistory = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getHistory', params, ['from', 'weeksBackCount']);
                        url = "/history";
                        return [4 /*yield*/, this.api.get(url, {
                                from: params === null || params === void 0 ? void 0 : params.from,
                                weeks_back_count: params === null || params === void 0 ? void 0 : params.weeksBackCount,
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * POST /target
         * Set active minutes weekly target
         */
        _this.setWeeklyTarget = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('setWeeklyTarget', params, ['target', 'changeType']);
                        url = "/target";
                        return [4 /*yield*/, this.api.post(url, {
                                target: params === null || params === void 0 ? void 0 : params.target,
                                change_type: params === null || params === void 0 ? void 0 : params.changeType,
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        /**
         * POST /confetti
         * Set active minutes confetti shown flag
         */
        _this.setConfettiShown = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('setConfettiShown', params, ['currentDate']);
                        url = "/confetti";
                        return [4 /*yield*/, this.api.post(url, {
                                current_date: params === null || params === void 0 ? void 0 : params.currentDate,
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        /**
         * POST /v1/terra/authentication-tokens
         * Generate an authentication token that allows to register a tracker in the app
         */
        _this.authenticateTerra = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v1/terra/authentication-tokens";
                        return [4 /*yield*/, this.api.post(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.getTrackers = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v1/trackers";
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.authenticateTracker = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('authenticateTracker', params, [
                            'provider',
                            'authSuccessRedirectUrl',
                            'authFailureRedirectUrl',
                        ]);
                        url = "/v1/terra/trackers/auth";
                        return [4 /*yield*/, this.api.post(url, {
                                provider: params === null || params === void 0 ? void 0 : params.provider,
                                auth_success_redirect_url: params === null || params === void 0 ? void 0 : params.authSuccessRedirectUrl,
                                auth_failure_redirect_url: params === null || params === void 0 ? void 0 : params.authFailureRedirectUrl,
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.deauthenticateTracker = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('deauthenticateTracker', params, ['trackerId']);
                        url = "/v1/terra/trackers/deauth";
                        return [4 /*yield*/, this.api.post(url, {
                                tracker_id: params === null || params === void 0 ? void 0 : params.trackerId,
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getChallenges = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v1/challenges";
                        this.api.setHeader('X-Timezone-Offset', String(new Date().getTimezoneOffset()));
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        this.api.deleteHeader('X-Timezone-Offset');
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.getCompletedChallenges = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v1/challenges/completed";
                        this.api.setHeader('X-Timezone-Offset', String(new Date().getTimezoneOffset()));
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        this.api.deleteHeader('X-Timezone-Offset');
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.getChallenge = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getChallenge', params, ['challengeId']);
                        url = "/v1/challenges/".concat(params === null || params === void 0 ? void 0 : params.challengeId);
                        this.api.setHeader('X-Timezone-Offset', String(new Date().getTimezoneOffset()));
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        this.api.deleteHeader('X-Timezone-Offset');
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.joinChallenge = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('joinChallenge', params, ['challengeId']);
                        url = "/v1/challenges/".concat(params === null || params === void 0 ? void 0 : params.challengeId, "/participation");
                        return [4 /*yield*/, this.api.put(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getLeaderboard = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getLeaderboard', params, ['challengeId']);
                        url = "/v1/challenges/".concat(params === null || params === void 0 ? void 0 : params.challengeId, "/leaderboard");
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.leaveChallenge = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('leaveChallenge', params, ['challengeId']);
                        url = "/v1/challenges/".concat(params === null || params === void 0 ? void 0 : params.challengeId, "/participation");
                        return [4 /*yield*/, this.api.delete(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    return Service;
}(base_service_1.BaseService));
exports.Service = Service;
