"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WellnessCenter = void 0;
var tslib_1 = require("tslib");
var Challenges = tslib_1.__importStar(require("./challenges"));
var Trackers = tslib_1.__importStar(require("./trackers"));
var Users = tslib_1.__importStar(require("./users"));
exports.WellnessCenter = {
    Challenges: Challenges,
    Trackers: Trackers,
    Users: Users,
};
