"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Service = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../../base-service");
var utils_1 = require("../../utils");
var Service = /** @class */ (function (_super) {
    tslib_1.__extends(Service, _super);
    function Service() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * GET /habits
         * List habit
         */
        _this.getHabitList = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/habits";
                        return [4 /*yield*/, this.api.get(url, {
                                date: params === null || params === void 0 ? void 0 : params.date,
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * GET /habits/days
         * Get daily progress of all user habits
         */
        _this.getDailyProgressList = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getDailyProgressList', params, ['fromDate', 'toDate']);
                        url = "/habits/days";
                        return [4 /*yield*/, this.api.get(url, (0, utils_1.sanitizeQueryParams)({
                                from_date: params.fromDate,
                                to_date: params.toDate,
                            }))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * GET /habits/{habit_id}
         * Get habit details with user status
         */
        _this.getHabitDetails = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getHabitDetails', params, ['habitId']);
                        url = "/habits/".concat(params.habitId);
                        return [4 /*yield*/, this.api.get(url, (0, utils_1.sanitizeQueryParams)({
                                date: params === null || params === void 0 ? void 0 : params.date,
                            }))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * POST /habits/{habit_id}
         * Start habit
         */
        _this.startHabit = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('startHabit', params, ['habitId', 'userRequest']);
                        url = "/habits/".concat(params.habitId);
                        return [4 /*yield*/, this.api.post(url, params.userRequest)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * DELETE /habits/{habit_id}
         * Stop habit execution
         */
        _this.stopHabit = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('stopHabit', params, 'habitId');
                        url = "/habits/".concat(params.habitId);
                        return [4 /*yield*/, this.api.delete(url, (0, utils_1.sanitizeQueryParams)({
                                date: params === null || params === void 0 ? void 0 : params.date,
                            }))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * PUT /habits/{habit_id}/prompt
         * Update built-in habit prompt
         */
        _this.updateBuiltInPrompt = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('updateBuiltInPrompt', params, ['habitId', 'promptId']);
                        url = "/habits/".concat(params.habitId, "/prompt");
                        return [4 /*yield*/, this.api.put(url, (0, utils_1.sanitizeQueryParams)({
                                prompt_id: params.promptId,
                            }))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * PUT /habits/{habit_id}/prompt
         * Update custom habit prompt
         */
        _this.updateCustomPrompt = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('updateCustomPrompt', params, [
                            'habitId',
                            'customMessage',
                        ]);
                        url = "/habits/".concat(params.habitId, "/prompt");
                        return [4 /*yield*/, this.api.put(url, (0, utils_1.sanitizeQueryParams)({
                                custom_message: params.customMessage,
                            }))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * DELETE /habits/{habit_id}/prompt
         * Remove habit prompt
         */
        _this.removePrompt = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('removePrompt', params, 'habitId');
                        url = "/habits/".concat(params.habitId, "/prompt");
                        return [4 /*yield*/, this.api.delete(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, response)];
                }
            });
        }); };
        /**
         * POST /habits/{habit_id}/done
         * Perform habit for specified day
         */
        _this.performHabit = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('performHabit', params, 'habitId');
                        url = "/habits/".concat(params.habitId, "/done");
                        return [4 /*yield*/, this.api.post(url, undefined, {
                                params: (0, utils_1.sanitizeQueryParams)({
                                    date: params === null || params === void 0 ? void 0 : params.date,
                                }),
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * DELETE /habits/{habit_id}/done
         * Cancel habit performing for specified day
         */
        _this.cancelHabit = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('cancelHabit', params, 'habitId');
                        url = "/habits/".concat(params.habitId, "/done");
                        return [4 /*yield*/, this.api.delete(url, (0, utils_1.sanitizeQueryParams)({
                                date: params === null || params === void 0 ? void 0 : params.date,
                            }))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * GET /habits/{habit_id}/days
         * Get daily progress of the habit
         */
        _this.getDailyProgress = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getDailyProgress', params, [
                            'habitId',
                            'fromDate',
                            'toDate',
                        ]);
                        url = "/habits/".concat(params.habitId, "/days");
                        return [4 /*yield*/, this.api.get(url, (0, utils_1.sanitizeQueryParams)({
                                from_date: params.fromDate,
                                to_date: params.toDate,
                            }))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * GET /habits/{habit_id}/stats
         * Get statistics of habit performing
         */
        _this.getStats = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getStats', params, ['habitId']);
                        url = "/habits/".concat(params.habitId, "/stats");
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * GET /habits/{habit_id}/notifications
         * List habit notifications
         */
        _this.getNotifications = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getNotifications', params, ['habitId']);
                        url = "/habits/".concat(params.habitId, "/notifications");
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * PUT /habits/{habit_id}/notifications/{notification_type}
         * Update habit notification
         */
        _this.updateNotification = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('updateNotification', params, [
                            'habitId',
                            'notificationType',
                            'enabled',
                        ]);
                        url = "/habits/".concat(params.habitId, "/notifications/").concat(params.notificationType);
                        return [4 /*yield*/, this.api.put(url, {
                                enabled: params.enabled,
                                reminder_time: (params === null || params === void 0 ? void 0 : params.reminderTime) || null,
                                week_days: (params === null || params === void 0 ? void 0 : params.weekDays) || null,
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * GET /notifications
         * List all active notifications
         */
        _this.getAllNotifications = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/notifications";
                        return [4 /*yield*/, this.api.get(url, (0, utils_1.sanitizeQueryParams)({
                                enabled: params === null || params === void 0 ? void 0 : params.enabled,
                            }))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        return _this;
    }
    return Service;
}(base_service_1.BaseService));
exports.Service = Service;
