"use strict";
/* globals Blob, File, atob */
Object.defineProperty(exports, "__esModule", { value: true });
exports.pathToFile = exports.dataUriToFile = exports.dataUritoBlob = void 0;
var DEFAULT_TYPE = 'image/jpeg';
var DEFAULT_FILENAME = 'photo.jpg';
var dataUritoBlob = function (dataURI, type) {
    if (type === void 0) { type = DEFAULT_TYPE; }
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: type });
};
exports.dataUritoBlob = dataUritoBlob;
var dataUriToFile = function (path, type, fileName) {
    if (type === void 0) { type = DEFAULT_TYPE; }
    if (fileName === void 0) { fileName = DEFAULT_FILENAME; }
    var fileBlob = (0, exports.dataUritoBlob)(path, type);
    var file = new File([fileBlob], fileName);
    return file;
};
exports.dataUriToFile = dataUriToFile;
var pathToFile = function (path, fileType, fileName) {
    if (fileName === void 0) { fileName = DEFAULT_FILENAME; }
    var type;
    try {
        type = fileType || path.substring(5, path.indexOf(';'));
    }
    catch (err) {
        type = DEFAULT_TYPE;
    }
    var file = (path.indexOf('data:') !== -1)
        ? (0, exports.dataUriToFile)(path, type, fileName)
        : {
            type: DEFAULT_TYPE,
            name: DEFAULT_FILENAME,
            uri: 'file://' + path
        };
    return file;
};
exports.pathToFile = pathToFile;
exports.default = {
    dataUritoBlob: exports.dataUritoBlob,
    dataUriToFile: exports.dataUriToFile,
    pathToFile: exports.pathToFile
};
