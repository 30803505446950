"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationType = exports.State = exports.WeekDay = void 0;
var WeekDay;
(function (WeekDay) {
    WeekDay[WeekDay["Mon"] = 1] = "Mon";
    WeekDay[WeekDay["Tue"] = 2] = "Tue";
    WeekDay[WeekDay["Wed"] = 3] = "Wed";
    WeekDay[WeekDay["Thu"] = 4] = "Thu";
    WeekDay[WeekDay["Fri"] = 5] = "Fri";
    WeekDay[WeekDay["Sat"] = 6] = "Sat";
    WeekDay[WeekDay["Sun"] = 7] = "Sun";
})(WeekDay = exports.WeekDay || (exports.WeekDay = {}));
var State;
(function (State) {
    State["Ongoing"] = "ongoing";
    State["Pending"] = "pending";
    State["Completed"] = "completed";
})(State = exports.State || (exports.State = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType["GET_PREPARED"] = "get_prepared";
    NotificationType["DO_HABIT"] = "do_habit";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
