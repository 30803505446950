"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ensureRequiredParams = void 0;
var errors_1 = require("./errors");
var ensureRequiredParams = function (method, params, requiredKeys) {
    var isParamsAnObject = typeof params === 'object';
    if (!isParamsAnObject || !params) {
        throw new errors_1.MissingRequiredParamError('', method, "".concat(method, " params must be an object"));
    }
    if (Array.isArray(requiredKeys)) {
        requiredKeys.forEach(function (requiredKey) {
            if (!(requiredKey in params)) {
                throw new errors_1.MissingRequiredParamError(requiredKey, method);
            }
        });
        return;
    }
    if (!(requiredKeys in params)) {
        throw new errors_1.MissingRequiredParamError(requiredKeys, method);
    }
};
exports.ensureRequiredParams = ensureRequiredParams;
