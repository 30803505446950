"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sanitizeQueryParams = void 0;
var sanitizeQueryParams = function (values) {
    var params = {};
    for (var property in values) {
        if (values.hasOwnProperty(property)) {
            // Transform arrays into comma-separated string
            if (Array.isArray(values[property])) {
                params[property] = values[property].join(',');
                continue;
            }
            // Pass non-null properties through
            if ([null, undefined].includes(values[property]) === false) {
                params[property] = values[property];
                continue;
            }
        }
    }
    return params;
};
exports.sanitizeQueryParams = sanitizeQueryParams;
