"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageRetraction = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../base-service");
var MessageRetraction = /** @class */ (function (_super) {
    tslib_1.__extends(MessageRetraction, _super);
    function MessageRetraction(accessToken, url) {
        var _this = _super.call(this, accessToken, url) || this;
        _this.retractMessage = function (messageId, userId, episodeId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = '/retract-message';
                        return [4 /*yield*/, this.api.post(url, {
                                message_id: messageId,
                                by_user: userId,
                                episode_id: episodeId,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, response)];
                }
            });
        }); };
        return _this;
    }
    return MessageRetraction;
}(base_service_1.BaseService));
exports.MessageRetraction = MessageRetraction;
