"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toktok = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../base-service");
/**
 * See https://github.com/dialoguemd/toktok/blob/master/doc/api.md for more info
 */
var Toktok = /** @class */ (function (_super) {
    tslib_1.__extends(Toktok, _super);
    function Toktok(accessToken, url) {
        var _this = _super.call(this, accessToken, url) || this;
        /**
         * https://github.com/dialoguemd/toktok/blob/master/doc/api.md#creating-patient-session-token
         */
        _this.getUserSessionToken = function (userId, payload) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        url = this.buildUserSessionTokenUrl(userId);
                        if (!payload) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.api.post(url, payload)];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.api.post(url)];
                    case 3:
                        _a = _b.sent();
                        _b.label = 4;
                    case 4:
                        response = _a;
                        return [2 /*return*/, this.handleResponse(null, url, response)];
                }
            });
        }); };
        /**
         * https://github.com/dialoguemd/toktok/blob/master/doc/api.md#creating-self-session-token
         */
        _this.getSelfSessionToken = function (payload) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        url = this.buildSelfSessionTokenUrl();
                        if (!payload) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.api.post(url, payload)];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.api.post(url)];
                    case 3:
                        _a = _b.sent();
                        _b.label = 4;
                    case 4:
                        response = _a;
                        return [2 /*return*/, this.handleResponse(null, url, response)];
                }
            });
        }); };
        _this.buildUserSessionTokenUrl = function (userId) {
            return "".concat(Toktok.apiVersion, "/users/").concat(userId, "/tokens");
        };
        _this.buildSelfSessionTokenUrl = function () { return "".concat(Toktok.apiVersion, "/tokens"); };
        return _this;
    }
    Toktok.apiVersion = '/v1';
    return Toktok;
}(base_service_1.BaseService));
exports.Toktok = Toktok;
