"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Habits = void 0;
var tslib_1 = require("tslib");
var Categories = tslib_1.__importStar(require("./categories"));
var HabitsModule = tslib_1.__importStar(require("./habits"));
exports.Habits = {
    Categories: Categories,
    Habits: HabitsModule,
};
