"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Emergency Room
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProviderAvailabilityConstraintApi = exports.ProviderAvailabilityConstraintApiFactory = exports.ProviderAvailabilityConstraintApiFp = exports.ProviderAvailabilityConstraintApiAxiosParamCreator = exports.MembersApi = exports.MembersApiFactory = exports.MembersApiFp = exports.MembersApiAxiosParamCreator = exports.JobsApi = exports.JobsApiFactory = exports.JobsApiFp = exports.JobsApiAxiosParamCreator = exports.DraftAppointmentsApi = exports.DraftAppointmentsApiFactory = exports.DraftAppointmentsApiFp = exports.DraftAppointmentsApiAxiosParamCreator = exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = exports.AvailabilitiesApi = exports.AvailabilitiesApiFactory = exports.AvailabilitiesApiFp = exports.AvailabilitiesApiAxiosParamCreator = exports.AppointmentsApi = exports.AppointmentsApiFactory = exports.AppointmentsApiFp = exports.AppointmentsApiAxiosParamCreator = exports.AdminEventsApi = exports.AdminEventsApiFactory = exports.AdminEventsApiFp = exports.AdminEventsApiAxiosParamCreator = exports.Preference = exports.Practice = exports.PacUnbookablePurpose = exports.PacLocation = exports.PacExclusivityConstraint = exports.PacEncounterType = exports.PacBookingFlow = exports.Location = exports.Language = exports.IHPractice = exports.Gender = exports.CulturalBackground = exports.AppointmentTypeSlotChunkingStrategy = exports.AppointmentType = exports.AppointmentStatus = exports.AppointmentBookingExperience = exports.AdminType = exports.AdditionalInterest = void 0;
exports.TasksApi = exports.TasksApiFactory = exports.TasksApiFp = exports.TasksApiAxiosParamCreator = exports.ProvidersApi = exports.ProvidersApiFactory = exports.ProvidersApiFp = exports.ProvidersApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.AdditionalInterest = {
    Lgbtqia2sPlus: 'lgbtqia2s_plus',
    Neurodiversity: 'neurodiversity',
    TraumaInformedCare: 'trauma_informed_care',
    NotDisclosed: 'not_disclosed'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.AdminType = {
    Labs: 'labs',
    Forms: 'forms',
    ClinicAdmin: 'clinic_admin',
    Meeting: 'meeting',
    Training: 'training',
    Inbox: 'inbox',
    Crisis: 'crisis',
    OnBreak: 'on_break',
    Other: 'other',
    WalkIn: 'walk_in'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.AppointmentBookingExperience = {
    TimeBased: 'time_based',
    ProviderBased: 'provider_based'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.AppointmentStatus = {
    Confirmed: 'confirmed',
    NoShow: 'no_show',
    BeingSeen: 'being_seen',
    VisitCompleted: 'visit_completed',
    Cancelled: 'cancelled',
    Done: 'done',
    Arrived: 'arrived',
    Triage: 'triage'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.AppointmentType = {
    EapMhsEvaluation: 'eap_mhs_evaluation',
    EapMhsInitial: 'eap_mhs_initial',
    EapMhsInitialOop: 'eap_mhs_initial_oop',
    EapMhsFu: 'eap_mhs_fu',
    EapMhsFuOop: 'eap_mhs_fu_oop',
    EapMhsCrisis: 'eap_mhs_crisis',
    EapMhsFamilyInitial: 'eap_mhs_family_initial',
    EapMhsFamilyFu: 'eap_mhs_family_fu',
    EapMhsFamilyFinalFu: 'eap_mhs_family_final_fu',
    EapMhsSwInitial: 'eap_mhs_sw_initial',
    EapMhsSwFu: 'eap_mhs_sw_fu',
    EapMhsChildElder: 'eap_mhs_child_elder',
    EapMhsManagerInitial: 'eap_mhs_manager_initial',
    EapMhsManagerFu: 'eap_mhs_manager_fu',
    EapMhsInternationalInitial: 'eap_mhs_international_initial',
    EapMhsInternationalFu: 'eap_mhs_international_fu',
    EapMhtInitial: 'eap_mht_initial',
    EapMhtFu: 'eap_mht_fu',
    EapMhtInitialOop: 'eap_mht_initial_oop',
    EapMhtFuOop: 'eap_mht_fu_oop',
    EapMhtCcqInitial: 'eap_mht_ccq_initial',
    EapMhtCcqFu: 'eap_mht_ccq_fu',
    EapPsyInitial: 'eap_psy_initial',
    EapPsyFu: 'eap_psy_fu',
    EapPsyInitialOop: 'eap_psy_initial_oop',
    EapPsyFuOop: 'eap_psy_fu_oop',
    EapDietitianInitial: 'eap_dietitian_initial',
    EapDietitianFu: 'eap_dietitian_fu',
    EapDietitianInitialOop: 'eap_dietitian_initial_oop',
    EapDietitianFuOop: 'eap_dietitian_fu_oop',
    EapFpInitial: 'eap_fp_initial',
    EapFpInitialOop: 'eap_fp_initial_oop',
    EapFpFu: 'eap_fp_fu',
    EapFpFuOop: 'eap_fp_fu_oop',
    EapCoInitial: 'eap_co_initial',
    EapCoInitialOop: 'eap_co_initial_oop',
    EapCoFu: 'eap_co_fu',
    EapCoFuOop: 'eap_co_fu_oop',
    EapCoManagerInitial: 'eap_co_manager_initial',
    EapCoManagerInitialOop: 'eap_co_manager_initial_oop',
    EapCoManagerFu: 'eap_co_manager_fu',
    EapCoManagerFuOop: 'eap_co_manager_fu_oop',
    EapMhsWprInitial: 'eap_mhs_wpr_initial',
    EapMhsWprFu: 'eap_mhs_wpr_fu',
    EapMhtWprInitial: 'eap_mht_wpr_initial',
    EapMhtWprFu: 'eap_mht_wpr_fu',
    EapPsyWprInitial: 'eap_psy_wpr_initial',
    EapPsyWprFu: 'eap_psy_wpr_fu',
    EapMhtWprSuInitial: 'eap_mht_wpr_su_initial',
    EapMhtWprSuFu: 'eap_mht_wpr_su_fu',
    EapMhtSuInitial: 'eap_mht_su_initial',
    EapMhtSuFu: 'eap_mht_su_fu',
    EapMhtSuInitialConsent: 'eap_mht_su_initial_consent',
    IcbtCoachingInitial: 'icbt_coaching_initial',
    IcbtCoachingFu: 'icbt_coaching_fu',
    PcInitialShort: 'pc_initial_short',
    PcMdInitialShort: 'pc_md_initial_short',
    PcInitialLong: 'pc_initial_long',
    PcNpmdInitial15: 'pc_npmd_initial_15',
    PcNpmdInitial20: 'pc_npmd_initial_20',
    PcNpmdInitial30: 'pc_npmd_initial_30',
    PcFuShort: 'pc_fu_short',
    PcMdFuShort: 'pc_md_fu_short',
    PcFuLong: 'pc_fu_long',
    PcNpmdFu15: 'pc_npmd_fu_15',
    PcNpmdFu20: 'pc_npmd_fu_20',
    PcNpmdFu30: 'pc_npmd_fu_30',
    PcMhsCoreInitial: 'pc_mhs_core_initial',
    PcMhsCoreFu: 'pc_mhs_core_fu',
    PcMhsCrisis: 'pc_mhs_crisis',
    PcPsyInitialOop: 'pc_psy_initial_oop',
    PcPsyFuOop: 'pc_psy_fu_oop',
    PcRnFu: 'pc_rn_fu',
    PcRnFuPed: 'pc_rn_fu_ped',
    PcRnNc: 'pc_rn_nc',
    PcRnNcPed: 'pc_rn_nc_ped',
    PcRnOc: 'pc_rn_oc',
    PcRnOr: 'pc_rn_or',
    PcRnOrPed: 'pc_rn_or_ped',
    PcDietitianInitialOop: 'pc_dietitian_initial_oop',
    PcDietitianFuOop: 'pc_dietitian_fu_oop',
    SmwbMhsEvaluation: 'smwb_mhs_evaluation',
    SmwbMhsInitial: 'smwb_mhs_initial',
    SmwbMhsInitialOop: 'smwb_mhs_initial_oop',
    SmwbMhsFu: 'smwb_mhs_fu',
    SmwbMhsFuOop: 'smwb_mhs_fu_oop',
    SmwbMhsCrisis: 'smwb_mhs_crisis',
    SmwbMhtInitial: 'smwb_mht_initial',
    SmwbMhtFu: 'smwb_mht_fu',
    SmwbMhtInitialOop: 'smwb_mht_initial_oop',
    SmwbMhtFuOop: 'smwb_mht_fu_oop',
    SmwbPsyInitial: 'smwb_psy_initial',
    SmwbPsyFu: 'smwb_psy_fu',
    SmwbPsyInitialOop: 'smwb_psy_initial_oop',
    SmwbPsyFuOop: 'smwb_psy_fu_oop',
    SmwbNpmdInitial: 'smwb_npmd_initial',
    SmwbNpmdFu: 'smwb_npmd_fu',
    SmwbNpmdFuLong: 'smwb_npmd_fu_long',
    SmwbNpFu: 'smwb_np_fu',
    SmwbDietitianInitialOop: 'smwb_dietitian_initial_oop',
    SmwbDietitianFuOop: 'smwb_dietitian_fu_oop',
    RnCcqInitial: 'rn_ccq_initial',
    RnCcqFu: 'rn_ccq_fu',
    RnCcqChronicInitial: 'rn_ccq_chronic_initial',
    RnCcqChronicFu: 'rn_ccq_chronic_fu',
    UbiPcRnInitialShort: 'ubi_pc_rn_initial_short',
    UbiPcRnInitialLong: 'ubi_pc_rn_initial_long',
    UbiPcNpmdInitialShort: 'ubi_pc_npmd_initial_short',
    UbiPcNpmdInitialLong: 'ubi_pc_npmd_initial_long',
    UbiPcPhysioInitial: 'ubi_pc_physio_initial',
    UbiPcPhysioFu: 'ubi_pc_physio_fu',
    BncPcRnInitialShort: 'bnc_pc_rn_initial_short',
    BncPcRnInitialLong: 'bnc_pc_rn_initial_long',
    BncPcNpmdInitialShort: 'bnc_pc_npmd_initial_short',
    BncPcNpmdInitialLong: 'bnc_pc_npmd_initial_long'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.AppointmentTypeSlotChunkingStrategy = {
    Asap: 'asap',
    ApptLength: 'appt_length',
    ApptLengthFixed: 'appt_length_fixed'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CulturalBackground = {
    AsianAndPacificIslander: 'asian_and_pacific_islander',
    Black: 'black',
    Caucasian: 'caucasian',
    IndigenousPeoples: 'indigenous_peoples',
    LatinCentralSouthAmerican: 'latin_central_south_american',
    NotDisclosed: 'not_disclosed'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Gender = {
    Woman: 'woman',
    Man: 'man',
    NonBinary: 'non_binary',
    NotDisclosed: 'not_disclosed'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.IHPractice = {
    Dietitian: 'DIETITIAN',
    MdNp: 'MD/NP',
    MhsProgram: 'MHS-PROGRAM'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Language = {
    Fr: 'fr',
    En: 'en'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Location = {
    Ab: 'CA-AB',
    Bc: 'CA-BC',
    Mb: 'CA-MB',
    Nb: 'CA-NB',
    Nl: 'CA-NL',
    Ns: 'CA-NS',
    On: 'CA-ON',
    Pe: 'CA-PE',
    Qc: 'CA-QC',
    Sk: 'CA-SK',
    Nt: 'CA-NT',
    Nu: 'CA-NU',
    Yt: 'CA-YT'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PacBookingFlow = {
    Bot: 'bot',
    Manual: 'manual'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PacEncounterType = {
    Initial: 'initial',
    Followup: 'followup'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PacExclusivityConstraint = {
    MhPlus: 'mh_plus',
    Empty: ''
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PacLocation = {
    Virtual: 'virtual',
    Ubisoft: 'ubisoft',
    Bnc: 'bnc'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PacUnbookablePurpose = {
    Break: 'break',
    Admin: 'admin',
    OnCall: 'on_call',
    Dispatch: 'dispatch',
    Queue: 'queue',
    Reminder: 'reminder'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Practice = {
    Md: 'MD',
    Np: 'NP',
    Rn: 'RN',
    RnCcq: 'RN-CCQ',
    RnOc: 'RN-OC',
    Diet: 'DIET',
    Physio: 'PHYSIO',
    Psy: 'PSY',
    PsyCore: 'PSY-CORE',
    PsyWpr: 'PSY-WPR',
    Mhs: 'MHS',
    MhsCore: 'MHS-CORE',
    MhsFam: 'MHS-FAM',
    MhsSw: 'MHS-SW',
    MhsWpr: 'MHS-WPR',
    Mht: 'MHT',
    MhtCcq: 'MHT-CCQ',
    MhtWpr: 'MHT-WPR',
    MhtSu: 'MHT-SU',
    MhtWprSu: 'MHT-WPR-SU',
    Fp: 'FP',
    Co: 'CO',
    CoMgrcoach: 'CO-MGRCOACH',
    Icbt: 'ICBT'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Preference = {
    ShorterAppts: 'shorter_appts',
    LongerAppts: 'longer_appts'
};
/**
 * AdminEventsApi - axios parameter creator
 * @export
 */
var AdminEventsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create an admin event.
         * @summary Create
         * @param {NewAdminEvent} newAdminEvent
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: function (newAdminEvent, userAgent, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'newAdminEvent' is not null or undefined
                            (0, common_1.assertParamExists)('create', 'newAdminEvent', newAdminEvent);
                            localVarPath = "/admin_events";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (userAgent != null) {
                                localVarHeaderParameter['user-agent'] = String(userAgent);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newAdminEvent, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete an admin event.
         * @summary Delete Admin Event
         * @param {number} adminEventId
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminEvent: function (adminEventId, userAgent, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'adminEventId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteAdminEvent', 'adminEventId', adminEventId);
                            localVarPath = "/admin_events/{admin_event_id}"
                                .replace("{".concat("admin_event_id", "}"), encodeURIComponent(String(adminEventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (userAgent != null) {
                                localVarHeaderParameter['user-agent'] = String(userAgent);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AdminEventsApiAxiosParamCreator = AdminEventsApiAxiosParamCreator;
/**
 * AdminEventsApi - functional programming interface
 * @export
 */
var AdminEventsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AdminEventsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Create an admin event.
         * @summary Create
         * @param {NewAdminEvent} newAdminEvent
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: function (newAdminEvent, userAgent, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.create(newAdminEvent, userAgent, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['AdminEventsApi.create']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Delete an admin event.
         * @summary Delete Admin Event
         * @param {number} adminEventId
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminEvent: function (adminEventId, userAgent, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteAdminEvent(adminEventId, userAgent, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['AdminEventsApi.deleteAdminEvent']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.AdminEventsApiFp = AdminEventsApiFp;
/**
 * AdminEventsApi - factory interface
 * @export
 */
var AdminEventsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AdminEventsApiFp)(configuration);
    return {
        /**
         * Create an admin event.
         * @summary Create
         * @param {NewAdminEvent} newAdminEvent
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: function (newAdminEvent, userAgent, options) {
            return localVarFp.create(newAdminEvent, userAgent, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete an admin event.
         * @summary Delete Admin Event
         * @param {number} adminEventId
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminEvent: function (adminEventId, userAgent, options) {
            return localVarFp.deleteAdminEvent(adminEventId, userAgent, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AdminEventsApiFactory = AdminEventsApiFactory;
/**
 * AdminEventsApi - object-oriented interface
 * @export
 * @class AdminEventsApi
 * @extends {BaseAPI}
 */
var AdminEventsApi = /** @class */ (function (_super) {
    __extends(AdminEventsApi, _super);
    function AdminEventsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create an admin event.
     * @summary Create
     * @param {NewAdminEvent} newAdminEvent
     * @param {string | null} [userAgent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEventsApi
     */
    AdminEventsApi.prototype.create = function (newAdminEvent, userAgent, options) {
        var _this = this;
        return (0, exports.AdminEventsApiFp)(this.configuration).create(newAdminEvent, userAgent, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete an admin event.
     * @summary Delete Admin Event
     * @param {number} adminEventId
     * @param {string | null} [userAgent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEventsApi
     */
    AdminEventsApi.prototype.deleteAdminEvent = function (adminEventId, userAgent, options) {
        var _this = this;
        return (0, exports.AdminEventsApiFp)(this.configuration).deleteAdminEvent(adminEventId, userAgent, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AdminEventsApi;
}(base_1.BaseAPI));
exports.AdminEventsApi = AdminEventsApi;
/**
 * AppointmentsApi - axios parameter creator
 * @export
 */
var AppointmentsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create an appointment  Scope `timekeeper:manage:appointment` is required to create an appointment. If said scope is not present in claims, the user_id in the claims must match the episode_owner_id in the request payload.
         * @summary Create
         * @param {NewAppointment} newAppointment
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: function (newAppointment, userAgent, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'newAppointment' is not null or undefined
                            (0, common_1.assertParamExists)('create', 'newAppointment', newAppointment);
                            localVarPath = "/appointments";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (userAgent != null) {
                                localVarHeaderParameter['user-agent'] = String(userAgent);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newAppointment, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get an appointment
         * @summary Get Appointment
         * @param {number} appointmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointment: function (appointmentId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'appointmentId' is not null or undefined
                            (0, common_1.assertParamExists)('getAppointment', 'appointmentId', appointmentId);
                            localVarPath = "/appointments/{appointment_id}"
                                .replace("{".concat("appointment_id", "}"), encodeURIComponent(String(appointmentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Appointment Types
         * @param {number} [providerId] Limit to appointment types for a given provider.
         * @param {AppointmentBookingExperience} [bookingExperience] Filter appointments that include a specific booking experience.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentTypes: function (providerId, bookingExperience, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/appointments/types";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (providerId !== undefined) {
                                localVarQueryParameter['provider_id'] = providerId;
                            }
                            if (bookingExperience !== undefined) {
                                localVarQueryParameter['booking_experience'] = bookingExperience;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Partial appointment update
         * @summary Update
         * @param {number} appointmentId
         * @param {EditAppointment} editAppointment
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (appointmentId, editAppointment, userAgent, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'appointmentId' is not null or undefined
                            (0, common_1.assertParamExists)('update', 'appointmentId', appointmentId);
                            // verify required parameter 'editAppointment' is not null or undefined
                            (0, common_1.assertParamExists)('update', 'editAppointment', editAppointment);
                            localVarPath = "/appointments/{appointment_id}"
                                .replace("{".concat("appointment_id", "}"), encodeURIComponent(String(appointmentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (userAgent != null) {
                                localVarHeaderParameter['user-agent'] = String(userAgent);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(editAppointment, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Upsert an appointment.
         * @summary Upsert Appointment
         * @param {IhAppointmentUpsert} ihAppointmentUpsert
         * @param {boolean} [checkAvailability] Whether to enforce that provider is still available during booked time.Only applies to new, TK-only appointments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertAppointment: function (ihAppointmentUpsert, checkAvailability, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'ihAppointmentUpsert' is not null or undefined
                            (0, common_1.assertParamExists)('upsertAppointment', 'ihAppointmentUpsert', ihAppointmentUpsert);
                            localVarPath = "/appointments/emerald";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (checkAvailability !== undefined) {
                                localVarQueryParameter['check_availability'] = checkAvailability;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(ihAppointmentUpsert, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AppointmentsApiAxiosParamCreator = AppointmentsApiAxiosParamCreator;
/**
 * AppointmentsApi - functional programming interface
 * @export
 */
var AppointmentsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AppointmentsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Create an appointment  Scope `timekeeper:manage:appointment` is required to create an appointment. If said scope is not present in claims, the user_id in the claims must match the episode_owner_id in the request payload.
         * @summary Create
         * @param {NewAppointment} newAppointment
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: function (newAppointment, userAgent, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.create(newAppointment, userAgent, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['AppointmentsApi.create']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get an appointment
         * @summary Get Appointment
         * @param {number} appointmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointment: function (appointmentId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAppointment(appointmentId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['AppointmentsApi.getAppointment']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Appointment Types
         * @param {number} [providerId] Limit to appointment types for a given provider.
         * @param {AppointmentBookingExperience} [bookingExperience] Filter appointments that include a specific booking experience.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentTypes: function (providerId, bookingExperience, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAppointmentTypes(providerId, bookingExperience, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['AppointmentsApi.getAppointmentTypes']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Partial appointment update
         * @summary Update
         * @param {number} appointmentId
         * @param {EditAppointment} editAppointment
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (appointmentId, editAppointment, userAgent, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(appointmentId, editAppointment, userAgent, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['AppointmentsApi.update']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Upsert an appointment.
         * @summary Upsert Appointment
         * @param {IhAppointmentUpsert} ihAppointmentUpsert
         * @param {boolean} [checkAvailability] Whether to enforce that provider is still available during booked time.Only applies to new, TK-only appointments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertAppointment: function (ihAppointmentUpsert, checkAvailability, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.upsertAppointment(ihAppointmentUpsert, checkAvailability, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['AppointmentsApi.upsertAppointment']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.AppointmentsApiFp = AppointmentsApiFp;
/**
 * AppointmentsApi - factory interface
 * @export
 */
var AppointmentsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AppointmentsApiFp)(configuration);
    return {
        /**
         * Create an appointment  Scope `timekeeper:manage:appointment` is required to create an appointment. If said scope is not present in claims, the user_id in the claims must match the episode_owner_id in the request payload.
         * @summary Create
         * @param {NewAppointment} newAppointment
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: function (newAppointment, userAgent, options) {
            return localVarFp.create(newAppointment, userAgent, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get an appointment
         * @summary Get Appointment
         * @param {number} appointmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointment: function (appointmentId, options) {
            return localVarFp.getAppointment(appointmentId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Appointment Types
         * @param {number} [providerId] Limit to appointment types for a given provider.
         * @param {AppointmentBookingExperience} [bookingExperience] Filter appointments that include a specific booking experience.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentTypes: function (providerId, bookingExperience, options) {
            return localVarFp.getAppointmentTypes(providerId, bookingExperience, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Partial appointment update
         * @summary Update
         * @param {number} appointmentId
         * @param {EditAppointment} editAppointment
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (appointmentId, editAppointment, userAgent, options) {
            return localVarFp.update(appointmentId, editAppointment, userAgent, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Upsert an appointment.
         * @summary Upsert Appointment
         * @param {IhAppointmentUpsert} ihAppointmentUpsert
         * @param {boolean} [checkAvailability] Whether to enforce that provider is still available during booked time.Only applies to new, TK-only appointments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertAppointment: function (ihAppointmentUpsert, checkAvailability, options) {
            return localVarFp.upsertAppointment(ihAppointmentUpsert, checkAvailability, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AppointmentsApiFactory = AppointmentsApiFactory;
/**
 * AppointmentsApi - object-oriented interface
 * @export
 * @class AppointmentsApi
 * @extends {BaseAPI}
 */
var AppointmentsApi = /** @class */ (function (_super) {
    __extends(AppointmentsApi, _super);
    function AppointmentsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create an appointment  Scope `timekeeper:manage:appointment` is required to create an appointment. If said scope is not present in claims, the user_id in the claims must match the episode_owner_id in the request payload.
     * @summary Create
     * @param {NewAppointment} newAppointment
     * @param {string | null} [userAgent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    AppointmentsApi.prototype.create = function (newAppointment, userAgent, options) {
        var _this = this;
        return (0, exports.AppointmentsApiFp)(this.configuration).create(newAppointment, userAgent, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get an appointment
     * @summary Get Appointment
     * @param {number} appointmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    AppointmentsApi.prototype.getAppointment = function (appointmentId, options) {
        var _this = this;
        return (0, exports.AppointmentsApiFp)(this.configuration).getAppointment(appointmentId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Appointment Types
     * @param {number} [providerId] Limit to appointment types for a given provider.
     * @param {AppointmentBookingExperience} [bookingExperience] Filter appointments that include a specific booking experience.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    AppointmentsApi.prototype.getAppointmentTypes = function (providerId, bookingExperience, options) {
        var _this = this;
        return (0, exports.AppointmentsApiFp)(this.configuration).getAppointmentTypes(providerId, bookingExperience, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Partial appointment update
     * @summary Update
     * @param {number} appointmentId
     * @param {EditAppointment} editAppointment
     * @param {string | null} [userAgent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    AppointmentsApi.prototype.update = function (appointmentId, editAppointment, userAgent, options) {
        var _this = this;
        return (0, exports.AppointmentsApiFp)(this.configuration).update(appointmentId, editAppointment, userAgent, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Upsert an appointment.
     * @summary Upsert Appointment
     * @param {IhAppointmentUpsert} ihAppointmentUpsert
     * @param {boolean} [checkAvailability] Whether to enforce that provider is still available during booked time.Only applies to new, TK-only appointments.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentsApi
     */
    AppointmentsApi.prototype.upsertAppointment = function (ihAppointmentUpsert, checkAvailability, options) {
        var _this = this;
        return (0, exports.AppointmentsApiFp)(this.configuration).upsertAppointment(ihAppointmentUpsert, checkAvailability, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AppointmentsApi;
}(base_1.BaseAPI));
exports.AppointmentsApi = AppointmentsApi;
/**
 * AvailabilitiesApi - axios parameter creator
 * @export
 */
var AvailabilitiesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Get availability slots for an appointment type and a time period
         * @summary Get Availabilities
         * @param {AppointmentType} appointmentType
         * @param {Location} location
         * @param {Language} language
         * @param {string} startAt
         * @param {string} endAt
         * @param {Set<number>} [provider]
         * @param {boolean} [cherryPick] Provide a subset of the available slots spaced at least by the appointment type length
         * @param {Set<Gender>} [genders]
         * @param {Set<CulturalBackground>} [culturalBackgrounds]
         * @param {Set<AdditionalInterest>} [additionalInterests]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailabilities: function (appointmentType, location, language, startAt, endAt, provider, cherryPick, genders, culturalBackgrounds, additionalInterests, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'appointmentType' is not null or undefined
                            (0, common_1.assertParamExists)('getAvailabilities', 'appointmentType', appointmentType);
                            // verify required parameter 'location' is not null or undefined
                            (0, common_1.assertParamExists)('getAvailabilities', 'location', location);
                            // verify required parameter 'language' is not null or undefined
                            (0, common_1.assertParamExists)('getAvailabilities', 'language', language);
                            // verify required parameter 'startAt' is not null or undefined
                            (0, common_1.assertParamExists)('getAvailabilities', 'startAt', startAt);
                            // verify required parameter 'endAt' is not null or undefined
                            (0, common_1.assertParamExists)('getAvailabilities', 'endAt', endAt);
                            localVarPath = "/availabilities";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (appointmentType !== undefined) {
                                localVarQueryParameter['appointment_type'] = appointmentType;
                            }
                            if (location !== undefined) {
                                localVarQueryParameter['location'] = location;
                            }
                            if (language !== undefined) {
                                localVarQueryParameter['language'] = language;
                            }
                            if (provider) {
                                localVarQueryParameter['provider'] = Array.from(provider);
                            }
                            if (cherryPick !== undefined) {
                                localVarQueryParameter['cherry_pick'] = cherryPick;
                            }
                            if (genders) {
                                localVarQueryParameter['genders'] = Array.from(genders);
                            }
                            if (culturalBackgrounds) {
                                localVarQueryParameter['cultural_backgrounds'] = Array.from(culturalBackgrounds);
                            }
                            if (additionalInterests) {
                                localVarQueryParameter['additional_interests'] = Array.from(additionalInterests);
                            }
                            if (startAt !== undefined) {
                                localVarQueryParameter['start_at'] = (startAt instanceof Date) ?
                                    startAt.toISOString() :
                                    startAt;
                            }
                            if (endAt !== undefined) {
                                localVarQueryParameter['end_at'] = (endAt instanceof Date) ?
                                    endAt.toISOString() :
                                    endAt;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AvailabilitiesApiAxiosParamCreator = AvailabilitiesApiAxiosParamCreator;
/**
 * AvailabilitiesApi - functional programming interface
 * @export
 */
var AvailabilitiesApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AvailabilitiesApiAxiosParamCreator)(configuration);
    return {
        /**
         * Get availability slots for an appointment type and a time period
         * @summary Get Availabilities
         * @param {AppointmentType} appointmentType
         * @param {Location} location
         * @param {Language} language
         * @param {string} startAt
         * @param {string} endAt
         * @param {Set<number>} [provider]
         * @param {boolean} [cherryPick] Provide a subset of the available slots spaced at least by the appointment type length
         * @param {Set<Gender>} [genders]
         * @param {Set<CulturalBackground>} [culturalBackgrounds]
         * @param {Set<AdditionalInterest>} [additionalInterests]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailabilities: function (appointmentType, location, language, startAt, endAt, provider, cherryPick, genders, culturalBackgrounds, additionalInterests, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAvailabilities(appointmentType, location, language, startAt, endAt, provider, cherryPick, genders, culturalBackgrounds, additionalInterests, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['AvailabilitiesApi.getAvailabilities']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.AvailabilitiesApiFp = AvailabilitiesApiFp;
/**
 * AvailabilitiesApi - factory interface
 * @export
 */
var AvailabilitiesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AvailabilitiesApiFp)(configuration);
    return {
        /**
         * Get availability slots for an appointment type and a time period
         * @summary Get Availabilities
         * @param {AppointmentType} appointmentType
         * @param {Location} location
         * @param {Language} language
         * @param {string} startAt
         * @param {string} endAt
         * @param {Set<number>} [provider]
         * @param {boolean} [cherryPick] Provide a subset of the available slots spaced at least by the appointment type length
         * @param {Set<Gender>} [genders]
         * @param {Set<CulturalBackground>} [culturalBackgrounds]
         * @param {Set<AdditionalInterest>} [additionalInterests]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailabilities: function (appointmentType, location, language, startAt, endAt, provider, cherryPick, genders, culturalBackgrounds, additionalInterests, options) {
            return localVarFp.getAvailabilities(appointmentType, location, language, startAt, endAt, provider, cherryPick, genders, culturalBackgrounds, additionalInterests, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AvailabilitiesApiFactory = AvailabilitiesApiFactory;
/**
 * AvailabilitiesApi - object-oriented interface
 * @export
 * @class AvailabilitiesApi
 * @extends {BaseAPI}
 */
var AvailabilitiesApi = /** @class */ (function (_super) {
    __extends(AvailabilitiesApi, _super);
    function AvailabilitiesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get availability slots for an appointment type and a time period
     * @summary Get Availabilities
     * @param {AppointmentType} appointmentType
     * @param {Location} location
     * @param {Language} language
     * @param {string} startAt
     * @param {string} endAt
     * @param {Set<number>} [provider]
     * @param {boolean} [cherryPick] Provide a subset of the available slots spaced at least by the appointment type length
     * @param {Set<Gender>} [genders]
     * @param {Set<CulturalBackground>} [culturalBackgrounds]
     * @param {Set<AdditionalInterest>} [additionalInterests]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailabilitiesApi
     */
    AvailabilitiesApi.prototype.getAvailabilities = function (appointmentType, location, language, startAt, endAt, provider, cherryPick, genders, culturalBackgrounds, additionalInterests, options) {
        var _this = this;
        return (0, exports.AvailabilitiesApiFp)(this.configuration).getAvailabilities(appointmentType, location, language, startAt, endAt, provider, cherryPick, genders, culturalBackgrounds, additionalInterests, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AvailabilitiesApi;
}(base_1.BaseAPI));
exports.AvailabilitiesApi = AvailabilitiesApi;
/**
 * DefaultApi - axios parameter creator
 * @export
 */
var DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Check if the application is reachable.
         * @summary Get Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/health";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Check if the application and its DB are reachable.
         * @summary Get Ready
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ready: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/ready";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.DefaultApiAxiosParamCreator = DefaultApiAxiosParamCreator;
/**
 * DefaultApi - functional programming interface
 * @export
 */
var DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DefaultApiAxiosParamCreator)(configuration);
    return {
        /**
         * Check if the application is reachable.
         * @summary Get Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: function (options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.health(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['DefaultApi.health']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Check if the application and its DB are reachable.
         * @summary Get Ready
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ready: function (options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.ready(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['DefaultApi.ready']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.DefaultApiFp = DefaultApiFp;
/**
 * DefaultApi - factory interface
 * @export
 */
var DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DefaultApiFp)(configuration);
    return {
        /**
         * Check if the application is reachable.
         * @summary Get Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: function (options) {
            return localVarFp.health(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Check if the application and its DB are reachable.
         * @summary Get Ready
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ready: function (options) {
            return localVarFp.ready(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DefaultApiFactory = DefaultApiFactory;
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Check if the application is reachable.
     * @summary Get Health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.health = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).health(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Check if the application and its DB are reachable.
     * @summary Get Ready
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.ready = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).ready(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
/**
 * DraftAppointmentsApi - axios parameter creator
 * @export
 */
var DraftAppointmentsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create a draft appointment
         * @summary Create
         * @param {NewDraftAppointment} newDraftAppointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: function (newDraftAppointment, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'newDraftAppointment' is not null or undefined
                            (0, common_1.assertParamExists)('create', 'newDraftAppointment', newDraftAppointment);
                            localVarPath = "/draft_appointments";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newDraftAppointment, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete a draft appointment  If the draft appointment does not exist then this is a no op
         * @summary Delete Draft
         * @param {number} draftId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraft: function (draftId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'draftId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteDraft', 'draftId', draftId);
                            localVarPath = "/draft_appointments/{draft_id}"
                                .replace("{".concat("draft_id", "}"), encodeURIComponent(String(draftId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update existing draft appointment or create a new one
         * @summary Update
         * @param {number} draftId
         * @param {NewDraftAppointment} newDraftAppointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (draftId, newDraftAppointment, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'draftId' is not null or undefined
                            (0, common_1.assertParamExists)('update', 'draftId', draftId);
                            // verify required parameter 'newDraftAppointment' is not null or undefined
                            (0, common_1.assertParamExists)('update', 'newDraftAppointment', newDraftAppointment);
                            localVarPath = "/draft_appointments/{draft_id}"
                                .replace("{".concat("draft_id", "}"), encodeURIComponent(String(draftId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(newDraftAppointment, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.DraftAppointmentsApiAxiosParamCreator = DraftAppointmentsApiAxiosParamCreator;
/**
 * DraftAppointmentsApi - functional programming interface
 * @export
 */
var DraftAppointmentsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DraftAppointmentsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Create a draft appointment
         * @summary Create
         * @param {NewDraftAppointment} newDraftAppointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: function (newDraftAppointment, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.create(newDraftAppointment, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['DraftAppointmentsApi.create']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Delete a draft appointment  If the draft appointment does not exist then this is a no op
         * @summary Delete Draft
         * @param {number} draftId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraft: function (draftId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteDraft(draftId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['DraftAppointmentsApi.deleteDraft']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update existing draft appointment or create a new one
         * @summary Update
         * @param {number} draftId
         * @param {NewDraftAppointment} newDraftAppointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (draftId, newDraftAppointment, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(draftId, newDraftAppointment, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['DraftAppointmentsApi.update']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.DraftAppointmentsApiFp = DraftAppointmentsApiFp;
/**
 * DraftAppointmentsApi - factory interface
 * @export
 */
var DraftAppointmentsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DraftAppointmentsApiFp)(configuration);
    return {
        /**
         * Create a draft appointment
         * @summary Create
         * @param {NewDraftAppointment} newDraftAppointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: function (newDraftAppointment, options) {
            return localVarFp.create(newDraftAppointment, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete a draft appointment  If the draft appointment does not exist then this is a no op
         * @summary Delete Draft
         * @param {number} draftId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraft: function (draftId, options) {
            return localVarFp.deleteDraft(draftId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update existing draft appointment or create a new one
         * @summary Update
         * @param {number} draftId
         * @param {NewDraftAppointment} newDraftAppointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (draftId, newDraftAppointment, options) {
            return localVarFp.update(draftId, newDraftAppointment, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DraftAppointmentsApiFactory = DraftAppointmentsApiFactory;
/**
 * DraftAppointmentsApi - object-oriented interface
 * @export
 * @class DraftAppointmentsApi
 * @extends {BaseAPI}
 */
var DraftAppointmentsApi = /** @class */ (function (_super) {
    __extends(DraftAppointmentsApi, _super);
    function DraftAppointmentsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create a draft appointment
     * @summary Create
     * @param {NewDraftAppointment} newDraftAppointment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftAppointmentsApi
     */
    DraftAppointmentsApi.prototype.create = function (newDraftAppointment, options) {
        var _this = this;
        return (0, exports.DraftAppointmentsApiFp)(this.configuration).create(newDraftAppointment, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete a draft appointment  If the draft appointment does not exist then this is a no op
     * @summary Delete Draft
     * @param {number} draftId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftAppointmentsApi
     */
    DraftAppointmentsApi.prototype.deleteDraft = function (draftId, options) {
        var _this = this;
        return (0, exports.DraftAppointmentsApiFp)(this.configuration).deleteDraft(draftId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update existing draft appointment or create a new one
     * @summary Update
     * @param {number} draftId
     * @param {NewDraftAppointment} newDraftAppointment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftAppointmentsApi
     */
    DraftAppointmentsApi.prototype.update = function (draftId, newDraftAppointment, options) {
        var _this = this;
        return (0, exports.DraftAppointmentsApiFp)(this.configuration).update(draftId, newDraftAppointment, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DraftAppointmentsApi;
}(base_1.BaseAPI));
exports.DraftAppointmentsApi = DraftAppointmentsApi;
/**
 * JobsApi - axios parameter creator
 * @export
 */
var JobsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Delete all expired drafts from the database
         * @summary Delete Expired Drafts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpiredDrafts: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/jobs/draft_appointments/expire";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Send to analytics the pacs for a provider
         * @summary Send Provider Pacs Analytics
         * @param {number} providerId
         * @param {string} startAt
         * @param {string} endAt
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProviderPacsAnalytics: function (providerId, startAt, endAt, userAgent, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'providerId' is not null or undefined
                            (0, common_1.assertParamExists)('sendProviderPacsAnalytics', 'providerId', providerId);
                            // verify required parameter 'startAt' is not null or undefined
                            (0, common_1.assertParamExists)('sendProviderPacsAnalytics', 'startAt', startAt);
                            // verify required parameter 'endAt' is not null or undefined
                            (0, common_1.assertParamExists)('sendProviderPacsAnalytics', 'endAt', endAt);
                            localVarPath = "/jobs/providers/{provider_id}/send_pacs_analytics"
                                .replace("{".concat("provider_id", "}"), encodeURIComponent(String(providerId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (startAt !== undefined) {
                                localVarQueryParameter['start_at'] = (startAt instanceof Date) ?
                                    startAt.toISOString() :
                                    startAt;
                            }
                            if (endAt !== undefined) {
                                localVarQueryParameter['end_at'] = (endAt instanceof Date) ?
                                    endAt.toISOString() :
                                    endAt;
                            }
                            if (userAgent != null) {
                                localVarHeaderParameter['user-agent'] = String(userAgent);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.JobsApiAxiosParamCreator = JobsApiAxiosParamCreator;
/**
 * JobsApi - functional programming interface
 * @export
 */
var JobsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.JobsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Delete all expired drafts from the database
         * @summary Delete Expired Drafts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpiredDrafts: function (options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteExpiredDrafts(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['JobsApi.deleteExpiredDrafts']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Send to analytics the pacs for a provider
         * @summary Send Provider Pacs Analytics
         * @param {number} providerId
         * @param {string} startAt
         * @param {string} endAt
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProviderPacsAnalytics: function (providerId, startAt, endAt, userAgent, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendProviderPacsAnalytics(providerId, startAt, endAt, userAgent, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['JobsApi.sendProviderPacsAnalytics']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.JobsApiFp = JobsApiFp;
/**
 * JobsApi - factory interface
 * @export
 */
var JobsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.JobsApiFp)(configuration);
    return {
        /**
         * Delete all expired drafts from the database
         * @summary Delete Expired Drafts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpiredDrafts: function (options) {
            return localVarFp.deleteExpiredDrafts(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Send to analytics the pacs for a provider
         * @summary Send Provider Pacs Analytics
         * @param {number} providerId
         * @param {string} startAt
         * @param {string} endAt
         * @param {string | null} [userAgent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProviderPacsAnalytics: function (providerId, startAt, endAt, userAgent, options) {
            return localVarFp.sendProviderPacsAnalytics(providerId, startAt, endAt, userAgent, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.JobsApiFactory = JobsApiFactory;
/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
var JobsApi = /** @class */ (function (_super) {
    __extends(JobsApi, _super);
    function JobsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Delete all expired drafts from the database
     * @summary Delete Expired Drafts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    JobsApi.prototype.deleteExpiredDrafts = function (options) {
        var _this = this;
        return (0, exports.JobsApiFp)(this.configuration).deleteExpiredDrafts(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Send to analytics the pacs for a provider
     * @summary Send Provider Pacs Analytics
     * @param {number} providerId
     * @param {string} startAt
     * @param {string} endAt
     * @param {string | null} [userAgent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    JobsApi.prototype.sendProviderPacsAnalytics = function (providerId, startAt, endAt, userAgent, options) {
        var _this = this;
        return (0, exports.JobsApiFp)(this.configuration).sendProviderPacsAnalytics(providerId, startAt, endAt, userAgent, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return JobsApi;
}(base_1.BaseAPI));
exports.JobsApi = JobsApi;
/**
 * MembersApi - axios parameter creator
 * @export
 */
var MembersApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Fetch appointments for a member
         * @summary Get Appointments
         * @param {number} memberId
         * @param {AppointmentStatus} [status]
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointments: function (memberId, status, offset, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'memberId' is not null or undefined
                            (0, common_1.assertParamExists)('getAppointments', 'memberId', memberId);
                            localVarPath = "/members/{member_id}/appointments"
                                .replace("{".concat("member_id", "}"), encodeURIComponent(String(memberId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (status !== undefined) {
                                localVarQueryParameter['status'] = status;
                            }
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.MembersApiAxiosParamCreator = MembersApiAxiosParamCreator;
/**
 * MembersApi - functional programming interface
 * @export
 */
var MembersApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.MembersApiAxiosParamCreator)(configuration);
    return {
        /**
         * Fetch appointments for a member
         * @summary Get Appointments
         * @param {number} memberId
         * @param {AppointmentStatus} [status]
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointments: function (memberId, status, offset, limit, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAppointments(memberId, status, offset, limit, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['MembersApi.getAppointments']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.MembersApiFp = MembersApiFp;
/**
 * MembersApi - factory interface
 * @export
 */
var MembersApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.MembersApiFp)(configuration);
    return {
        /**
         * Fetch appointments for a member
         * @summary Get Appointments
         * @param {number} memberId
         * @param {AppointmentStatus} [status]
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointments: function (memberId, status, offset, limit, options) {
            return localVarFp.getAppointments(memberId, status, offset, limit, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.MembersApiFactory = MembersApiFactory;
/**
 * MembersApi - object-oriented interface
 * @export
 * @class MembersApi
 * @extends {BaseAPI}
 */
var MembersApi = /** @class */ (function (_super) {
    __extends(MembersApi, _super);
    function MembersApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Fetch appointments for a member
     * @summary Get Appointments
     * @param {number} memberId
     * @param {AppointmentStatus} [status]
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    MembersApi.prototype.getAppointments = function (memberId, status, offset, limit, options) {
        var _this = this;
        return (0, exports.MembersApiFp)(this.configuration).getAppointments(memberId, status, offset, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MembersApi;
}(base_1.BaseAPI));
exports.MembersApi = MembersApi;
/**
 * ProviderAvailabilityConstraintApi - axios parameter creator
 * @export
 */
var ProviderAvailabilityConstraintApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Set provider availability constraints for a given provider and time range.
         * @summary Set Provider Availability Constraints
         * @param {ProviderAvailabilityConstraintSetRequest} providerAvailabilityConstraintSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabilityConstraintSetProviderAvailabilityConstraints: function (providerAvailabilityConstraintSetRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'providerAvailabilityConstraintSetRequest' is not null or undefined
                            (0, common_1.assertParamExists)('availabilityConstraintSetProviderAvailabilityConstraints', 'providerAvailabilityConstraintSetRequest', providerAvailabilityConstraintSetRequest);
                            localVarPath = "/provider_availability_constraints";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(providerAvailabilityConstraintSetRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ProviderAvailabilityConstraintApiAxiosParamCreator = ProviderAvailabilityConstraintApiAxiosParamCreator;
/**
 * ProviderAvailabilityConstraintApi - functional programming interface
 * @export
 */
var ProviderAvailabilityConstraintApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ProviderAvailabilityConstraintApiAxiosParamCreator)(configuration);
    return {
        /**
         * Set provider availability constraints for a given provider and time range.
         * @summary Set Provider Availability Constraints
         * @param {ProviderAvailabilityConstraintSetRequest} providerAvailabilityConstraintSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabilityConstraintSetProviderAvailabilityConstraints: function (providerAvailabilityConstraintSetRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.availabilityConstraintSetProviderAvailabilityConstraints(providerAvailabilityConstraintSetRequest, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ProviderAvailabilityConstraintApi.availabilityConstraintSetProviderAvailabilityConstraints']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.ProviderAvailabilityConstraintApiFp = ProviderAvailabilityConstraintApiFp;
/**
 * ProviderAvailabilityConstraintApi - factory interface
 * @export
 */
var ProviderAvailabilityConstraintApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ProviderAvailabilityConstraintApiFp)(configuration);
    return {
        /**
         * Set provider availability constraints for a given provider and time range.
         * @summary Set Provider Availability Constraints
         * @param {ProviderAvailabilityConstraintSetRequest} providerAvailabilityConstraintSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabilityConstraintSetProviderAvailabilityConstraints: function (providerAvailabilityConstraintSetRequest, options) {
            return localVarFp.availabilityConstraintSetProviderAvailabilityConstraints(providerAvailabilityConstraintSetRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ProviderAvailabilityConstraintApiFactory = ProviderAvailabilityConstraintApiFactory;
/**
 * ProviderAvailabilityConstraintApi - object-oriented interface
 * @export
 * @class ProviderAvailabilityConstraintApi
 * @extends {BaseAPI}
 */
var ProviderAvailabilityConstraintApi = /** @class */ (function (_super) {
    __extends(ProviderAvailabilityConstraintApi, _super);
    function ProviderAvailabilityConstraintApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Set provider availability constraints for a given provider and time range.
     * @summary Set Provider Availability Constraints
     * @param {ProviderAvailabilityConstraintSetRequest} providerAvailabilityConstraintSetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderAvailabilityConstraintApi
     */
    ProviderAvailabilityConstraintApi.prototype.availabilityConstraintSetProviderAvailabilityConstraints = function (providerAvailabilityConstraintSetRequest, options) {
        var _this = this;
        return (0, exports.ProviderAvailabilityConstraintApiFp)(this.configuration).availabilityConstraintSetProviderAvailabilityConstraints(providerAvailabilityConstraintSetRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ProviderAvailabilityConstraintApi;
}(base_1.BaseAPI));
exports.ProviderAvailabilityConstraintApi = ProviderAvailabilityConstraintApi;
/**
 * ProvidersApi - axios parameter creator
 * @export
 */
var ProvidersApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Get provider
         * @summary Get By Id
         * @param {number} providerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: function (providerId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'providerId' is not null or undefined
                            (0, common_1.assertParamExists)('getById', 'providerId', providerId);
                            localVarPath = "/providers/{provider_id}"
                                .replace("{".concat("provider_id", "}"), encodeURIComponent(String(providerId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Fetch appointments for a provider in a given time range
         * @summary Get For Provider
         * @param {number} providerId
         * @param {string} startAt
         * @param {string} endAt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForProvider: function (providerId, startAt, endAt, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'providerId' is not null or undefined
                            (0, common_1.assertParamExists)('getForProvider', 'providerId', providerId);
                            // verify required parameter 'startAt' is not null or undefined
                            (0, common_1.assertParamExists)('getForProvider', 'startAt', startAt);
                            // verify required parameter 'endAt' is not null or undefined
                            (0, common_1.assertParamExists)('getForProvider', 'endAt', endAt);
                            localVarPath = "/providers/{provider_id}/appointments"
                                .replace("{".concat("provider_id", "}"), encodeURIComponent(String(providerId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (startAt !== undefined) {
                                localVarQueryParameter['start_at'] = (startAt instanceof Date) ?
                                    startAt.toISOString() :
                                    startAt;
                            }
                            if (endAt !== undefined) {
                                localVarQueryParameter['end_at'] = (endAt instanceof Date) ?
                                    endAt.toISOString() :
                                    endAt;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Fetch Schedule for a provider in a given time range
         * @summary Get Provider Schedule
         * @param {number} providerId
         * @param {string} startAt
         * @param {string} endAt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderSchedule: function (providerId, startAt, endAt, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'providerId' is not null or undefined
                            (0, common_1.assertParamExists)('getProviderSchedule', 'providerId', providerId);
                            // verify required parameter 'startAt' is not null or undefined
                            (0, common_1.assertParamExists)('getProviderSchedule', 'startAt', startAt);
                            // verify required parameter 'endAt' is not null or undefined
                            (0, common_1.assertParamExists)('getProviderSchedule', 'endAt', endAt);
                            localVarPath = "/providers/{provider_id}/schedule"
                                .replace("{".concat("provider_id", "}"), encodeURIComponent(String(providerId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (startAt !== undefined) {
                                localVarQueryParameter['start_at'] = (startAt instanceof Date) ?
                                    startAt.toISOString() :
                                    startAt;
                            }
                            if (endAt !== undefined) {
                                localVarQueryParameter['end_at'] = (endAt instanceof Date) ?
                                    endAt.toISOString() :
                                    endAt;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get providers
         * @summary Get Providers
         * @param {Set<Practice>} [practice] Limit to providers with any of given practices.
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviders: function (practice, offset, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/providers";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (practice) {
                                localVarQueryParameter['practice'] = Array.from(practice);
                            }
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all provider matching attributes
         * @summary Get Providers Attributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersAttributes: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/providers/attributes";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Fetch Schedule in a given time range and given filters
         * @summary Get Schedule
         * @param {string} startAt
         * @param {string} endAt
         * @param {Set<Language>} [language]
         * @param {Set<Location>} [location]
         * @param {Set<Practice1Inner>} [practice]
         * @param {Set<PacLocation>} [pacLocation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedule: function (startAt, endAt, language, location, practice, pacLocation, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'startAt' is not null or undefined
                            (0, common_1.assertParamExists)('getSchedule', 'startAt', startAt);
                            // verify required parameter 'endAt' is not null or undefined
                            (0, common_1.assertParamExists)('getSchedule', 'endAt', endAt);
                            localVarPath = "/providers/schedule";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (language) {
                                localVarQueryParameter['language'] = Array.from(language);
                            }
                            if (location) {
                                localVarQueryParameter['location'] = Array.from(location);
                            }
                            if (practice) {
                                localVarQueryParameter['practice'] = Array.from(practice);
                            }
                            if (pacLocation) {
                                localVarQueryParameter['pac_location'] = Array.from(pacLocation);
                            }
                            if (startAt !== undefined) {
                                localVarQueryParameter['start_at'] = (startAt instanceof Date) ?
                                    startAt.toISOString() :
                                    startAt;
                            }
                            if (endAt !== undefined) {
                                localVarQueryParameter['end_at'] = (endAt instanceof Date) ?
                                    endAt.toISOString() :
                                    endAt;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Fetch schedule filters
         * @summary Get Schedule Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleFilters: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/providers/schedule/filters";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update provider matching attributes.  The scope `timekeeper:manage:provider` is required to access this endpoint. If the scope is not present, access will be granted if requestor `user_id` matches the `provider_id`.  New values will overwrite the existing ones. If a value is not provided or is null, it will remain unchanged.  When updating `cultural_backgrounds` or `additional_interests` with a list containing `Prefer not to disclose`, any other additional value will be removed.  The other attributes (language, email, scopes_of_practice, preferences) are not updatable through this endpoint.
         * @summary Update Provider
         * @param {number} providerId
         * @param {ProviderMatchingAttributes} providerMatchingAttributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvider: function (providerId, providerMatchingAttributes, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'providerId' is not null or undefined
                            (0, common_1.assertParamExists)('updateProvider', 'providerId', providerId);
                            // verify required parameter 'providerMatchingAttributes' is not null or undefined
                            (0, common_1.assertParamExists)('updateProvider', 'providerMatchingAttributes', providerMatchingAttributes);
                            localVarPath = "/providers/{provider_id}"
                                .replace("{".concat("provider_id", "}"), encodeURIComponent(String(providerId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(providerMatchingAttributes, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a provider or create if it doesn\'t exist.
         * @summary Upsert Provider
         * @param {Provider} provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertProvider: function (provider, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'provider' is not null or undefined
                            (0, common_1.assertParamExists)('upsertProvider', 'provider', provider);
                            localVarPath = "/providers";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(provider, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ProvidersApiAxiosParamCreator = ProvidersApiAxiosParamCreator;
/**
 * ProvidersApi - functional programming interface
 * @export
 */
var ProvidersApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ProvidersApiAxiosParamCreator)(configuration);
    return {
        /**
         * Get provider
         * @summary Get By Id
         * @param {number} providerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: function (providerId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getById(providerId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ProvidersApi.getById']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Fetch appointments for a provider in a given time range
         * @summary Get For Provider
         * @param {number} providerId
         * @param {string} startAt
         * @param {string} endAt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForProvider: function (providerId, startAt, endAt, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getForProvider(providerId, startAt, endAt, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ProvidersApi.getForProvider']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Fetch Schedule for a provider in a given time range
         * @summary Get Provider Schedule
         * @param {number} providerId
         * @param {string} startAt
         * @param {string} endAt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderSchedule: function (providerId, startAt, endAt, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getProviderSchedule(providerId, startAt, endAt, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ProvidersApi.getProviderSchedule']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Get providers
         * @summary Get Providers
         * @param {Set<Practice>} [practice] Limit to providers with any of given practices.
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviders: function (practice, offset, limit, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getProviders(practice, offset, limit, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ProvidersApi.getProviders']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * List all provider matching attributes
         * @summary Get Providers Attributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersAttributes: function (options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getProvidersAttributes(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ProvidersApi.getProvidersAttributes']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Fetch Schedule in a given time range and given filters
         * @summary Get Schedule
         * @param {string} startAt
         * @param {string} endAt
         * @param {Set<Language>} [language]
         * @param {Set<Location>} [location]
         * @param {Set<Practice1Inner>} [practice]
         * @param {Set<PacLocation>} [pacLocation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedule: function (startAt, endAt, language, location, practice, pacLocation, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSchedule(startAt, endAt, language, location, practice, pacLocation, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ProvidersApi.getSchedule']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Fetch schedule filters
         * @summary Get Schedule Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleFilters: function (options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getScheduleFilters(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ProvidersApi.getScheduleFilters']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update provider matching attributes.  The scope `timekeeper:manage:provider` is required to access this endpoint. If the scope is not present, access will be granted if requestor `user_id` matches the `provider_id`.  New values will overwrite the existing ones. If a value is not provided or is null, it will remain unchanged.  When updating `cultural_backgrounds` or `additional_interests` with a list containing `Prefer not to disclose`, any other additional value will be removed.  The other attributes (language, email, scopes_of_practice, preferences) are not updatable through this endpoint.
         * @summary Update Provider
         * @param {number} providerId
         * @param {ProviderMatchingAttributes} providerMatchingAttributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvider: function (providerId, providerMatchingAttributes, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateProvider(providerId, providerMatchingAttributes, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ProvidersApi.updateProvider']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         * Update a provider or create if it doesn\'t exist.
         * @summary Upsert Provider
         * @param {Provider} provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertProvider: function (provider, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.upsertProvider(provider, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['ProvidersApi.upsertProvider']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.ProvidersApiFp = ProvidersApiFp;
/**
 * ProvidersApi - factory interface
 * @export
 */
var ProvidersApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ProvidersApiFp)(configuration);
    return {
        /**
         * Get provider
         * @summary Get By Id
         * @param {number} providerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: function (providerId, options) {
            return localVarFp.getById(providerId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Fetch appointments for a provider in a given time range
         * @summary Get For Provider
         * @param {number} providerId
         * @param {string} startAt
         * @param {string} endAt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForProvider: function (providerId, startAt, endAt, options) {
            return localVarFp.getForProvider(providerId, startAt, endAt, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Fetch Schedule for a provider in a given time range
         * @summary Get Provider Schedule
         * @param {number} providerId
         * @param {string} startAt
         * @param {string} endAt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderSchedule: function (providerId, startAt, endAt, options) {
            return localVarFp.getProviderSchedule(providerId, startAt, endAt, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get providers
         * @summary Get Providers
         * @param {Set<Practice>} [practice] Limit to providers with any of given practices.
         * @param {number} [offset]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviders: function (practice, offset, limit, options) {
            return localVarFp.getProviders(practice, offset, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all provider matching attributes
         * @summary Get Providers Attributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersAttributes: function (options) {
            return localVarFp.getProvidersAttributes(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Fetch Schedule in a given time range and given filters
         * @summary Get Schedule
         * @param {string} startAt
         * @param {string} endAt
         * @param {Set<Language>} [language]
         * @param {Set<Location>} [location]
         * @param {Set<Practice1Inner>} [practice]
         * @param {Set<PacLocation>} [pacLocation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedule: function (startAt, endAt, language, location, practice, pacLocation, options) {
            return localVarFp.getSchedule(startAt, endAt, language, location, practice, pacLocation, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Fetch schedule filters
         * @summary Get Schedule Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleFilters: function (options) {
            return localVarFp.getScheduleFilters(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update provider matching attributes.  The scope `timekeeper:manage:provider` is required to access this endpoint. If the scope is not present, access will be granted if requestor `user_id` matches the `provider_id`.  New values will overwrite the existing ones. If a value is not provided or is null, it will remain unchanged.  When updating `cultural_backgrounds` or `additional_interests` with a list containing `Prefer not to disclose`, any other additional value will be removed.  The other attributes (language, email, scopes_of_practice, preferences) are not updatable through this endpoint.
         * @summary Update Provider
         * @param {number} providerId
         * @param {ProviderMatchingAttributes} providerMatchingAttributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvider: function (providerId, providerMatchingAttributes, options) {
            return localVarFp.updateProvider(providerId, providerMatchingAttributes, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a provider or create if it doesn\'t exist.
         * @summary Upsert Provider
         * @param {Provider} provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertProvider: function (provider, options) {
            return localVarFp.upsertProvider(provider, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ProvidersApiFactory = ProvidersApiFactory;
/**
 * ProvidersApi - object-oriented interface
 * @export
 * @class ProvidersApi
 * @extends {BaseAPI}
 */
var ProvidersApi = /** @class */ (function (_super) {
    __extends(ProvidersApi, _super);
    function ProvidersApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get provider
     * @summary Get By Id
     * @param {number} providerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    ProvidersApi.prototype.getById = function (providerId, options) {
        var _this = this;
        return (0, exports.ProvidersApiFp)(this.configuration).getById(providerId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Fetch appointments for a provider in a given time range
     * @summary Get For Provider
     * @param {number} providerId
     * @param {string} startAt
     * @param {string} endAt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    ProvidersApi.prototype.getForProvider = function (providerId, startAt, endAt, options) {
        var _this = this;
        return (0, exports.ProvidersApiFp)(this.configuration).getForProvider(providerId, startAt, endAt, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Fetch Schedule for a provider in a given time range
     * @summary Get Provider Schedule
     * @param {number} providerId
     * @param {string} startAt
     * @param {string} endAt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    ProvidersApi.prototype.getProviderSchedule = function (providerId, startAt, endAt, options) {
        var _this = this;
        return (0, exports.ProvidersApiFp)(this.configuration).getProviderSchedule(providerId, startAt, endAt, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get providers
     * @summary Get Providers
     * @param {Set<Practice>} [practice] Limit to providers with any of given practices.
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    ProvidersApi.prototype.getProviders = function (practice, offset, limit, options) {
        var _this = this;
        return (0, exports.ProvidersApiFp)(this.configuration).getProviders(practice, offset, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all provider matching attributes
     * @summary Get Providers Attributes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    ProvidersApi.prototype.getProvidersAttributes = function (options) {
        var _this = this;
        return (0, exports.ProvidersApiFp)(this.configuration).getProvidersAttributes(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Fetch Schedule in a given time range and given filters
     * @summary Get Schedule
     * @param {string} startAt
     * @param {string} endAt
     * @param {Set<Language>} [language]
     * @param {Set<Location>} [location]
     * @param {Set<Practice1Inner>} [practice]
     * @param {Set<PacLocation>} [pacLocation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    ProvidersApi.prototype.getSchedule = function (startAt, endAt, language, location, practice, pacLocation, options) {
        var _this = this;
        return (0, exports.ProvidersApiFp)(this.configuration).getSchedule(startAt, endAt, language, location, practice, pacLocation, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Fetch schedule filters
     * @summary Get Schedule Filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    ProvidersApi.prototype.getScheduleFilters = function (options) {
        var _this = this;
        return (0, exports.ProvidersApiFp)(this.configuration).getScheduleFilters(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update provider matching attributes.  The scope `timekeeper:manage:provider` is required to access this endpoint. If the scope is not present, access will be granted if requestor `user_id` matches the `provider_id`.  New values will overwrite the existing ones. If a value is not provided or is null, it will remain unchanged.  When updating `cultural_backgrounds` or `additional_interests` with a list containing `Prefer not to disclose`, any other additional value will be removed.  The other attributes (language, email, scopes_of_practice, preferences) are not updatable through this endpoint.
     * @summary Update Provider
     * @param {number} providerId
     * @param {ProviderMatchingAttributes} providerMatchingAttributes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    ProvidersApi.prototype.updateProvider = function (providerId, providerMatchingAttributes, options) {
        var _this = this;
        return (0, exports.ProvidersApiFp)(this.configuration).updateProvider(providerId, providerMatchingAttributes, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a provider or create if it doesn\'t exist.
     * @summary Upsert Provider
     * @param {Provider} provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    ProvidersApi.prototype.upsertProvider = function (provider, options) {
        var _this = this;
        return (0, exports.ProvidersApiFp)(this.configuration).upsertProvider(provider, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ProvidersApi;
}(base_1.BaseAPI));
exports.ProvidersApi = ProvidersApi;
/**
 * TasksApi - axios parameter creator
 * @export
 */
var TasksApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Fetch appointments for which a \'task\' has not yet been completed
         * @summary Unprocessed Appointments
         * @param {string} taskId
         * @param {string} minStartAt
         * @param {string} maxStartAt
         * @param {Array<AppointmentStatus>} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unprocessedAppointments: function (taskId, minStartAt, maxStartAt, status, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'taskId' is not null or undefined
                            (0, common_1.assertParamExists)('unprocessedAppointments', 'taskId', taskId);
                            // verify required parameter 'minStartAt' is not null or undefined
                            (0, common_1.assertParamExists)('unprocessedAppointments', 'minStartAt', minStartAt);
                            // verify required parameter 'maxStartAt' is not null or undefined
                            (0, common_1.assertParamExists)('unprocessedAppointments', 'maxStartAt', maxStartAt);
                            localVarPath = "/tasks/{task_id}/unprocessed_appointments"
                                .replace("{".concat("task_id", "}"), encodeURIComponent(String(taskId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication HTTPBearer required
                            // http bearer authentication required
                            _a.sent();
                            if (minStartAt !== undefined) {
                                localVarQueryParameter['min_start_at'] = (minStartAt instanceof Date) ?
                                    minStartAt.toISOString() :
                                    minStartAt;
                            }
                            if (maxStartAt !== undefined) {
                                localVarQueryParameter['max_start_at'] = (maxStartAt instanceof Date) ?
                                    maxStartAt.toISOString() :
                                    maxStartAt;
                            }
                            if (status) {
                                localVarQueryParameter['status'] = status;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TasksApiAxiosParamCreator = TasksApiAxiosParamCreator;
/**
 * TasksApi - functional programming interface
 * @export
 */
var TasksApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TasksApiAxiosParamCreator)(configuration);
    return {
        /**
         * Fetch appointments for which a \'task\' has not yet been completed
         * @summary Unprocessed Appointments
         * @param {string} taskId
         * @param {string} minStartAt
         * @param {string} maxStartAt
         * @param {Array<AppointmentStatus>} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unprocessedAppointments: function (taskId, minStartAt, maxStartAt, status, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.unprocessedAppointments(taskId, minStartAt, maxStartAt, status, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = base_1.operationServerMap['TasksApi.unprocessedAppointments']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
exports.TasksApiFp = TasksApiFp;
/**
 * TasksApi - factory interface
 * @export
 */
var TasksApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TasksApiFp)(configuration);
    return {
        /**
         * Fetch appointments for which a \'task\' has not yet been completed
         * @summary Unprocessed Appointments
         * @param {string} taskId
         * @param {string} minStartAt
         * @param {string} maxStartAt
         * @param {Array<AppointmentStatus>} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unprocessedAppointments: function (taskId, minStartAt, maxStartAt, status, options) {
            return localVarFp.unprocessedAppointments(taskId, minStartAt, maxStartAt, status, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TasksApiFactory = TasksApiFactory;
/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
var TasksApi = /** @class */ (function (_super) {
    __extends(TasksApi, _super);
    function TasksApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Fetch appointments for which a \'task\' has not yet been completed
     * @summary Unprocessed Appointments
     * @param {string} taskId
     * @param {string} minStartAt
     * @param {string} maxStartAt
     * @param {Array<AppointmentStatus>} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    TasksApi.prototype.unprocessedAppointments = function (taskId, minStartAt, maxStartAt, status, options) {
        var _this = this;
        return (0, exports.TasksApiFp)(this.configuration).unprocessedAppointments(taskId, minStartAt, maxStartAt, status, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TasksApi;
}(base_1.BaseAPI));
exports.TasksApi = TasksApi;
