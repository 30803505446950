"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeStatus = exports.TrackerStatus = exports.SDKTrackers = exports.TrackerName = exports.TargetChangeType = exports.ActivityMetric = exports.ActivityType = void 0;
var ActivityType;
(function (ActivityType) {
    ActivityType["STEPS"] = "steps";
    ActivityType["RUNNING"] = "running";
    ActivityType["CYCLING"] = "cycling";
    ActivityType["SWIMMING"] = "swimming";
    ActivityType["WHEELCHAIR"] = "wheelchair";
    ActivityType["YOGA"] = "yoga";
    ActivityType["OTHER"] = "other";
})(ActivityType = exports.ActivityType || (exports.ActivityType = {}));
var ActivityMetric;
(function (ActivityMetric) {
    ActivityMetric["DistanceMeters"] = "distance_meters";
    ActivityMetric["Steps"] = "steps";
})(ActivityMetric = exports.ActivityMetric || (exports.ActivityMetric = {}));
var TargetChangeType;
(function (TargetChangeType) {
    TargetChangeType["MANAUL"] = "manual";
    TargetChangeType["ACCEPT_PROMPT"] = "accept_prompt";
    TargetChangeType["DECLINE_PROMPT"] = "decline_prompt";
    TargetChangeType["POSTPONE_PROMPT"] = "postpone_prompt";
})(TargetChangeType = exports.TargetChangeType || (exports.TargetChangeType = {}));
var TrackerName;
(function (TrackerName) {
    TrackerName["Fitbit"] = "FITBIT";
    TrackerName["SamsungHealth"] = "SAMSUNG";
    TrackerName["GoogleFit"] = "GOOGLE";
    TrackerName["AppleHealth"] = "APPLE";
    TrackerName["Garmin"] = "GARMIN";
    TrackerName["Strava"] = "STRAVA";
    TrackerName["Withings"] = "WITHINGS";
})(TrackerName = exports.TrackerName || (exports.TrackerName = {}));
var SDKTrackers;
(function (SDKTrackers) {
    SDKTrackers["AppleHealth"] = "APPLE";
})(SDKTrackers = exports.SDKTrackers || (exports.SDKTrackers = {}));
var TrackerStatus;
(function (TrackerStatus) {
    TrackerStatus["Connected"] = "connected";
    TrackerStatus["Disconnected"] = "disconnected";
    TrackerStatus["Broken"] = "broken";
})(TrackerStatus = exports.TrackerStatus || (exports.TrackerStatus = {}));
var ChallengeStatus;
(function (ChallengeStatus) {
    ChallengeStatus["Ongoing"] = "ongoing";
    ChallengeStatus["Completed"] = "completed";
    ChallengeStatus["Upcoming"] = "upcoming";
})(ChallengeStatus = exports.ChallengeStatus || (exports.ChallengeStatus = {}));
