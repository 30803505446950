"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SilkroadUnAuthenticated = exports.Silkroad = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../base-service");
/**
 * See https://github.com/dialoguemd/silkroad
 *
 * @export
 * @class Silkroad
 * @extends {BaseService}
 */
var Silkroad = /** @class */ (function (_super) {
    tslib_1.__extends(Silkroad, _super);
    function Silkroad(idToken, url, timeout, extraHeaders) {
        var _this = _super.call(this, idToken, url, timeout, extraHeaders) || this;
        _this.resendVerificationEmail = function (verificationEmailRequest) {
            if (verificationEmailRequest === void 0) { verificationEmailRequest = {}; }
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var url, res;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            url = this.buildVerifyEmailIntentUrl();
                            return [4 /*yield*/, this.api.post(url, verificationEmailRequest)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, this.handleResponse(null, url, res)];
                    }
                });
            });
        };
        _this.changeEmailIntent = function (emailObject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.buildChangeEmailIntentUrl();
                        return [4 /*yield*/, this.api.post(url, emailObject)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.changeEmail = function (token) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.buildChangeEmailUrl();
                        return [4 /*yield*/, this.api.post(url, { token: token })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.changeUnverifiedEmail = function (emailObject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.buildChangeUnferifiedEmailUrl();
                        return [4 /*yield*/, this.api.post(url, emailObject)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.createEpisode = function (userId, payload) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/members/".concat(userId, "/episodes");
                        return [4 /*yield*/, this.api.post(url, payload)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.getMemberEpisodes = function (userId, limit) {
            if (limit === void 0) { limit = 100; }
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var url, params, res;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            url = "/members/".concat(userId, "/episodes");
                            params = { limit: limit };
                            return [4 /*yield*/, this.api.get(url, params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, this.handleResponse(null, url, res)];
                    }
                });
            });
        };
        _this.getEpisode = function (episodeId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/episodes/".concat(episodeId);
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.sendCommand = function (episodeId, payload) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/episodes/".concat(episodeId, "/command");
                        return [4 /*yield*/, this.api.post(url, { payload: payload })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.getMemberOrganization = function (userId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/members/".concat(userId, "/organization");
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.setMFA = function (setMFARequest) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.buildSetMFAUrl();
                        return [4 /*yield*/, this.api.post(url, setMFARequest)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.addDevice = function (addDeviceRequest) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.buildAddDeviceUrl();
                        return [4 /*yield*/, this.api.post(url, addDeviceRequest)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.getFamilyAppointments = function (userId, offset, limit) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.buildGetFamilyAppointmentsUrl(userId);
                        return [4 /*yield*/, this.api.get(url, { offset: offset, limit: limit })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.buildChangeEmailIntentUrl = function () { return '/members/change-email-intent'; };
        _this.buildChangeEmailUrl = function () { return '/members/change-email'; };
        _this.buildChangeUnferifiedEmailUrl = function () {
            return '/members/change-unverified-email';
        };
        _this.buildVerifyEmailIntentUrl = function () { return '/members/verify-email-intent'; };
        _this.buildSetMFAUrl = function () { return '/members/set-mfa'; };
        _this.buildAddDeviceUrl = function () { return '/members/devices'; };
        _this.buildGetFamilyAppointmentsUrl = function (userId) {
            return "/v1/members/".concat(userId, "/family-appointments");
        };
        return _this;
    }
    return Silkroad;
}(base_service_1.BaseService));
exports.Silkroad = Silkroad;
/**
 * Silkoad's unauthenticated routes. See https://github.com/dialoguemd/silkroad
 * @export
 * @class SilkroadUnAuthenticated
 * @extends {BaseService}
 */
var SilkroadUnAuthenticated = /** @class */ (function (_super) {
    tslib_1.__extends(SilkroadUnAuthenticated, _super);
    function SilkroadUnAuthenticated(url, timeout, extraHeaders) {
        var _this = _super.call(this, null, url, timeout, extraHeaders) || this;
        _this.resetPassword = function (emailObject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.buildPasswordResetIntentUrl();
                        return [4 /*yield*/, this.api.post(url, emailObject)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.buildPasswordResetIntentUrl = function () { return '/members/password-reset-intent'; };
        return _this;
    }
    return SilkroadUnAuthenticated;
}(base_service_1.BaseService));
exports.SilkroadUnAuthenticated = SilkroadUnAuthenticated;
