"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionType = exports.RecommendationType = void 0;
var RecommendationType;
(function (RecommendationType) {
    RecommendationType["PROGRESS"] = "wbi_progress";
    RecommendationType["COMPARE"] = "wbi_compare";
    RecommendationType["FOCUS"] = "wbi_focus";
    RecommendationType["MH"] = "mh";
    RecommendationType["ICBT"] = "icbt";
})(RecommendationType = exports.RecommendationType || (exports.RecommendationType = {}));
var ActionType;
(function (ActionType) {
    ActionType["SHOW_HABITS"] = "show_habits";
    ActionType["SHOW_ARTICLES"] = "show_articles";
    ActionType["LINK"] = "link";
    ActionType["NEW_CONSULT"] = "newConsult";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
