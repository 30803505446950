"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Usher = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../base-service");
var Usher = /** @class */ (function (_super) {
    tslib_1.__extends(Usher, _super);
    function Usher(accessToken, url) {
        var _this = _super.call(this, accessToken, url) || this;
        _this.practitionersEndpoint = '/physicians';
        _this.channelsEndpoint = '/channels';
        _this.userEndpoint = '/user';
        _this.postsEndpoint = '/posts';
        _this.getChannels = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.channelsEndpoint;
                        return [4 /*yield*/, this.api.get(url, params)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.getPractitioners = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.practitionersEndpoint;
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.updateChannelProps = function (channelId, channelProps) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "".concat(this.channelsEndpoint, "/").concat(channelId, "/props");
                        return [4 /*yield*/, this.api.patch(url, {
                                props: channelProps,
                            })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.updateChannelState = function (channelId, newState) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "".concat(this.channelsEndpoint, "/").concat(channelId, "/state");
                        return [4 /*yield*/, this.api.put(url, { state: newState })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.setChannelQueue = function (channelId, selectedQueue, selectedActionId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "".concat(this.channelsEndpoint, "/").concat(channelId, "/queue");
                        return [4 /*yield*/, this.api.post(url, {
                                queue: selectedQueue,
                                next_action_id: selectedActionId,
                            })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.setChannelAssignee = function (channelId, assigneeId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "".concat(this.channelsEndpoint, "/").concat(channelId, "/assignee");
                        return [4 /*yield*/, this.api.put(url, {
                                physician_id: assigneeId,
                            })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.removeChannelAssignee = function (channelId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "".concat(this.channelsEndpoint, "/").concat(channelId, "/assignee");
                        return [4 /*yield*/, this.api.delete(url)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        /**
         * Get a mattermost user by dialogue id (known as app_id in Usher)
         */
        _this.getMMUserById = function (id) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "".concat(this.userEndpoint, "/").concat(id);
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.patchPostProps = function (postId, props) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "".concat(this.postsEndpoint, "/").concat(postId, "/props_v2");
                        return [4 /*yield*/, this.api.patch(url, { props: props })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        return _this;
    }
    return Usher;
}(base_service_1.BaseService));
exports.Usher = Usher;
