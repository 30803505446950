"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Service = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../base-service");
var Service = /** @class */ (function (_super) {
    tslib_1.__extends(Service, _super);
    function Service() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * GET /recommendations/wbi
         * Get rich recommendation for WBI assessment
         */
        _this.getWbiRecommendations = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/recommendations/wbi";
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        return _this;
    }
    return Service;
}(base_service_1.BaseService));
exports.Service = Service;
