"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Service = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../../base-service");
var utils_1 = require("../../utils");
var Service = /** @class */ (function (_super) {
    tslib_1.__extends(Service, _super);
    function Service() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.API_BASE = '/api/user/v4';
        // GET /trackers
        _this.getTrackers = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, items;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "".concat(this.API_BASE, "/trackers");
                        return [4 /*yield*/, this.api.get(url, tslib_1.__assign(tslib_1.__assign({}, (0, utils_1.sanitizeQueryParams)({ lang: params === null || params === void 0 ? void 0 : params.lang })), { page_size: 100, page: 0 }))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        items = (_a.sent()).items;
                        return [2 /*return*/, items];
                }
            });
        }); };
        // GET /trackers/redirect-to-tracker-auth
        _this.getTrackersRedirectToTrackerAuth = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getTrackersRedirectToTrackerAuth()', params, [
                            'connectionId',
                            'successUrl',
                        ]);
                        url = "".concat(this.API_BASE, "/trackers/redirect-to-tracker-auth");
                        return [4 /*yield*/, this.api.get(url, {
                                connection_id: params.connectionId,
                                success_url: params.successUrl,
                                error_url: params.errorUrl,
                            }, {
                                validateStatus: function (status) {
                                    return status === 302;
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        if (!(response.status !== 302)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        // GET /trackers/{tracker_name}/auth-callback
        _this.getTrackersAuthCallback = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getTrackersAuthCallback()', params, 'trackerName');
                        url = "/trackers/".concat(params.trackerName, "/auth-callback");
                        return [4 /*yield*/, this.api.get(url, tslib_1.__assign({}, params.authQuery), {
                                validateStatus: function (status) {
                                    return status === 302;
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        if (!(response.status !== 302)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    return Service;
}(base_service_1.BaseService));
exports.Service = Service;
