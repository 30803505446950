"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Service = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../../base-service");
var utils_1 = require("../../utils");
var Service = /** @class */ (function (_super) {
    tslib_1.__extends(Service, _super);
    function Service() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.API_BASE = '/api/user/v4';
        _this.getChallenges = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, challenges, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "".concat(this.API_BASE, "/challenges");
                        return [4 /*yield*/, this.api.get(url, tslib_1.__assign(tslib_1.__assign({}, (0, utils_1.sanitizeQueryParams)({
                                status: params === null || params === void 0 ? void 0 : params.status,
                                type: params === null || params === void 0 ? void 0 : params.type,
                                tag: params === null || params === void 0 ? void 0 : params.tag,
                                participant: params === null || params === void 0 ? void 0 : params.participant,
                                activity: params === null || params === void 0 ? void 0 : params.activity,
                                from_date: params === null || params === void 0 ? void 0 : params.from_date,
                                to_date: params === null || params === void 0 ? void 0 : params.to_date,
                                sort: params === null || params === void 0 ? void 0 : params.sort,
                                lang: params === null || params === void 0 ? void 0 : params.lang,
                            })), { page: 0, page_size: 100 }))];
                    case 1:
                        challenges = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, challenges)];
                    case 2:
                        response = (_a.sent());
                        return [2 /*return*/, response.items];
                }
            });
        }); };
        _this.getChallenge = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getChallenge()', params, 'challengeId');
                        url = "".concat(this.API_BASE, "/challenges/").concat(params.challengeId);
                        return [4 /*yield*/, this.api.get(url, (0, utils_1.sanitizeQueryParams)({ lang: params.lang }))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.getParticipants = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, participants, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getParticipants()', params, 'challengeId');
                        url = "".concat(this.API_BASE, "/challenges/").concat(params.challengeId, "/participants");
                        return [4 /*yield*/, this.api.get(url, {
                                page_size: (params === null || params === void 0 ? void 0 : params.pageSize) || 100,
                                page: (params === null || params === void 0 ? void 0 : params.page) || 0,
                            })];
                    case 1:
                        participants = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, participants)];
                    case 2:
                        response = (_a.sent());
                        return [2 /*return*/, response.items];
                }
            });
        }); };
        _this.getTeams = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, teams, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getTeams()', params, 'challengeId');
                        url = "".concat(this.API_BASE, "/challenges/").concat(params.challengeId, "/teams");
                        return [4 /*yield*/, this.api.get(url, tslib_1.__assign(tslib_1.__assign({}, (0, utils_1.sanitizeQueryParams)({ lang: params === null || params === void 0 ? void 0 : params.lang })), { page_size: 100, page: 0 }))];
                    case 1:
                        teams = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, teams)];
                    case 2:
                        response = (_a.sent());
                        return [2 /*return*/, response.items];
                }
            });
        }); };
        _this.getTeamParticipants = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, participants, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getTeamParticipants()', params, [
                            'challengeId',
                            'teamId',
                        ]);
                        url = "".concat(this.API_BASE, "/challenges/").concat(params.challengeId, "/teams/").concat(params.teamId, "/participants");
                        return [4 /*yield*/, this.api.get(url, {
                                page_size: (params === null || params === void 0 ? void 0 : params.pageSize) || 100,
                                page: (params === null || params === void 0 ? void 0 : params.page) || 0,
                            })];
                    case 1:
                        participants = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, participants)];
                    case 2:
                        response = (_a.sent());
                        return [2 /*return*/, response.items];
                }
            });
        }); };
        _this.getTeamParticipant = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getOneUserInTeam()', params, ['challengeId', 'teamId']);
                        url = "".concat(this.API_BASE, "/challenges/").concat(params.challengeId, "/teams/").concat(params.teamId, "/participants/me");
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.getParticipantRank = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getUserRank()', params, 'challengeId');
                        url = "".concat(this.API_BASE, "/challenges/").concat(params.challengeId, "/participants/me");
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.getTeamRank = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getTeamRank()', params, ['challengeId', 'teamId']);
                        url = "".concat(this.API_BASE, "/challenges/").concat(params.challengeId, "/teams/").concat(params.teamId);
                        return [4 /*yield*/, this.api.get(url, (0, utils_1.sanitizeQueryParams)({ lang: params.lang }))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.addParticipant = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('addParticipant()', params, 'challengeId');
                        url = "".concat(this.API_BASE, "/challenges/").concat(params.challengeId, "/participants/me");
                        return [4 /*yield*/, this.api.post(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.addParticipantToTeam = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('addParticipantToTeam()', params, [
                            'challengeId',
                            'teamId',
                        ]);
                        url = "".concat(this.API_BASE, "/challenges/").concat(params.challengeId, "/teams/").concat(params.teamId, "/participants/me");
                        return [4 /*yield*/, this.api.post(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.addTeam = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('addTeam()', params, ['challengeId', 'teamInfo']);
                        url = "".concat(this.API_BASE, "/challenges/").concat(params.challengeId, "/teams/");
                        return [4 /*yield*/, this.api.post(url, params.teamInfo)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.updateTeam = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('updateTeam()', params, [
                            'challengeId',
                            'teamId',
                            'teamInfo',
                        ]);
                        url = "".concat(this.API_BASE, "/challenges/").concat(params.challengeId, "/teams/").concat(params.teamId);
                        return [4 /*yield*/, this.api.put(url, params.teamInfo)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.removeUser = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('removeUser()', params, ['challengeId']);
                        url = "".concat(this.API_BASE, "/challenges/").concat(params.challengeId, "/participants/me");
                        return [4 /*yield*/, this.api.delete(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.removeTeam = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('removeTeam()', params, ['challengeId', 'teamId']);
                        url = "".concat(this.API_BASE, "/challenges/").concat(params.challengeId, "/teamId/").concat(params.teamId);
                        return [4 /*yield*/, this.api.delete(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        return _this;
    }
    return Service;
}(base_service_1.BaseService));
exports.Service = Service;
