import merge from 'lodash/merge'
import {
  Action,
  calc,
  inertia,
  listen,
  pointer,
  styler,
  tween,
  value,
  ValueReaction,
} from 'popmotion'
import React from 'react'
import styled, { ThemeProvider } from 'styled-components'

import highlightImage from './resources/highlight.png'
import zoomOutIconSource from './resources/ic_minus.png'
import zoomInIconSource from './resources/ic_plus.png'
import zoomIconSource from './resources/ic_zoom.png'
import { ImageMapSelectorProps, Region } from './types'

const HIGHLIGHT_SCALE_FACTOR = 0.25 // Expand region 25% of it's size in all directions
const HIGHLIGHT_OPACITY = 0.6

interface Theme {
  colors: {
    background?: string
    border?: string
    shadow?: string
  }
}
interface ZoomProps {
  zoom?: boolean
  minimumZoomScale?: number
  maximumZoomScale?: number
  zoomRef?: any
  initialScale?: number
  zoomStep?: number
}
interface WebImageMapSelectorProps extends ImageMapSelectorProps, ZoomProps {
  style?: any
  theme?: Theme
  containerProps?: {}
  clickHandlerProps?: object
  zoomInButtonProps?: object
  zoomOutButtonProps?: object
  dragBuffer?: number
  imageAlt?: string
}

interface WebImageMapSelectorState {
  imageSize: [number, number]
  scale: number
  theme?: Theme
}

const defaultTheme = {
  colors: {
    background: '#FFFFFF',
    border: '#EAEDEE',
    shadow: '#F1F1F1',
  },
}

const Container = styled.div<{ disabled: boolean }>`
  position: relative;
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: ${p => (p.disabled ? 'none' : 'all')};
  background-color: ${p => p.theme.colors.background};
`

const ContainerInner = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`

const ImageComp = styled.img`
  position: absolute;
  z-index: 1;
  user-select: none;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
`
/*

[DIA-79563] 
The following styles have been temporarily removed from SvgContainer to quickly resolve an outage.
We might consider reinstating them when the CORS issue blocking the mask from loading has been resolved. 

  mask-type: alpha;
  mask-image: url(${p => p.maskImageSrc});
  mask-size: contain;
  mask-position: center center;
  mask-repeat: no-repeat;

*/

const SvgContainer = styled.svg<{ maskImageSrc: string }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;

  opacity: ${HIGHLIGHT_OPACITY};
`

const ZoomContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
  min-width: 45px;
  min-height: 102px;
  border-radius: 50px;
  border-style: solid;
  border-width: 1px;
  border-color: ${p => p.theme.colors.border};
  background-color: ${p => p.theme.colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
`

const ZoomIcon = styled.img`
  width: 12px;
  height: 12px;
  object-fit: contain;
`

const ZoomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 30px;
  margin: 8px;
  background-color: unset;
  border-radius: 50%;
  border: unset;
  transition: all 300ms;
  &:hover {
    box-shadow: 0px 1px 10px 0px ${p => p.theme.colors.shadow};
  }
`

const clickEventToFloatCoords = event => {
  const boundingRect = event.currentTarget.getBoundingClientRect()
  const { width, height, left, top } = boundingRect
  const x = event.pageX - window.scrollX - left
  const y = event.pageY - window.scrollY - top
  return [x / width, y / height]
}

const selectMatchingRegions = (regions: Region[], [x, y]: number[]) => {
  const matches = regions.filter(({ coordsList }) =>
    coordsList.some(([x1, y1, x2, y2]) => x > x1 && x < x2 && y > y1 && y < y2)
  )
  return matches
}

const coordsWithScaling = ([x1, y1, x2, y2]: number[]) => {
  const w = (x2 - x1) * HIGHLIGHT_SCALE_FACTOR
  const h = (y2 - y1) * HIGHLIGHT_SCALE_FACTOR
  return [x1 - w, y1 - h, x2 + w, y2 + h]
}

const RegionComp: React.FC<Region> = ({ coordsList }) => (
  <>
    {coordsList.map((coords, i) => {
      const [x1, y1, x2, y2] = coordsWithScaling(coords)
      const w = x2 - x1
      const h = y2 - y1
      const zIndex = 1000 - Math.ceil(w * h * 1000) // Smaller areas on top

      return (
        <image
          key={i}
          x={x1 * 100 + '%'}
          y={y1 * 100 + '%'}
          width={w * 100 + '%'}
          height={h * 100 + '%'}
          style={{ zIndex }}
          href={`${highlightImage}`}
          preserveAspectRatio="none"
        />
      )
    })}
  </>
)

export class ImageMapSelector extends React.Component<
  WebImageMapSelectorProps,
  WebImageMapSelectorState
> {
  static defaultProps = {
    selectedRegions: [],
    onChange: () => {},
    onError: () => {},
    initialScale: 0.7,
    zoomStep: 1.5,
    dragBuffer: 0,
    maximumZoomScale: 4,
    minimumZoomScale: 0.7,
    readOnly: false,
  }

  static getDerivedStateFromProps({ theme: themeProp }, { prevTheme }) {
    if (themeProp !== prevTheme) {
      return {
        theme: merge({}, defaultTheme, themeProp),
        prevTheme: themeProp,
      }
    }

    return null
  }
  regions: Region[]
  moseDownLisntner: ReturnType<Action['start']>
  mouseMoveListner: ReturnType<Action['start']>
  mouseUpListner: ReturnType<Action['start']>
  containerRef: HTMLDivElement
  innerContainerRef: HTMLDivElement
  innerContainerXY: ValueReaction
  innerContainerScale: ValueReaction
  slider = {}
  moved = false
  initialPointerPosition = { x: 0, y: 0 }
  startDrag = false
  scale = this.props.initialScale
  nextScale = this.props.initialScale

  constructor(props) {
    super(props)
    const { imageRegions } = props as WebImageMapSelectorProps

    this.regions = Object.entries(imageRegions).map(([id, coordsList]) => ({
      id,
      coordsList,
    }))

    this.state = {
      imageSize: [0, 0],
      scale: props.initialScale,
      theme: defaultTheme,
    }
  }

  componentDidMount() {
    this.updateImageMeta(this.props.imageSource)
    const innerContainerStyler = styler(this.innerContainerRef as any)
    this.innerContainerXY = value({ x: 0, y: 0 }, innerContainerStyler.set)
    this.innerContainerScale = value(this.props.initialScale, scale =>
      innerContainerStyler.set({
        width: 100 * scale + '%',
        height: 100 * scale + '%',
      })
    )
    if (this.props.zoom) {
      this.moseDownLisntner = listen(
        this.containerRef as any,
        'mousedown'
      ).start(() => {
        this.moved = false
        this.startDrag = true
      })
      this.mouseMoveListner = listen(
        this.containerRef as any,
        'mousemove'
      ).start(() => {
        if (this.startDrag) {
          this.startDrag = false
          this.initialPointerPosition = this.innerContainerXY.get() as any
          pointer(this.initialPointerPosition)
            .pipe(
              this.getXYValuesClapmpPipe(this.getXYBounds(), 0.2),
              this.trackMovement
            )
            .start(this.innerContainerXY)
        }
      })
      this.mouseUpListner = listen(document, 'mouseup').start(e => {
        this.startDrag = false
        if (this.scale === this.nextScale) {
          const { xMax, xMin, yMax, yMin } = this.getXYBounds()
          inertia({
            min: { x: xMin, y: yMin },
            max: { x: xMax, y: yMax },
            from: this.innerContainerXY.get(),
            velocity: this.innerContainerXY.getVelocity(),
            power: 0.1,
            bounceStiffness: 400,
            bounceDamping: 40,
          }).start(this.innerContainerXY)
        } else {
          this.resize()
        }
      })
      if (typeof this.props.zoomRef === 'function') {
        this.props.zoomRef({
          zoom: this.resize,
        })
      }
    }
  }

  componentWillUnmount() {
    if (this.moseDownLisntner) this.moseDownLisntner.stop()
    if (this.mouseMoveListner) this.mouseMoveListner.stop()
    if (this.mouseUpListner) this.mouseUpListner.stop()
  }

  updateImageMeta(url) {
    const setState = this.setState.bind(this)
    const img = new Image()
    img.addEventListener('load', function() {
      setState({ imageSize: [this.naturalWidth, this.naturalHeight] })
    })
    img.src = url
  }

  setInnerContainerRef = node => {
    if (node) this.innerContainerRef = node
  }

  setContainerRef = node => {
    if (node) this.containerRef = node
  }

  trackMovement = value => {
    if (!this.moved) {
      const { x, y } = value
      const { x: initX, y: initY } = this.initialPointerPosition
      this.moved = [initX - x, initY - y].some(
        v => Math.abs(v) > this.props.dragBuffer
      )
    }
    return value
  }

  clampValueWithTug = (v, min, max, tug) => {
    if (v < min) return calc.getValueFromProgress(min, v, tug)
    if (v > max) return calc.getValueFromProgress(max, v, tug)
    return v
  }

  getXYBounds = (
    scale = this.scale,
    rect = (this.innerContainerRef as any).getBoundingClientRect()
  ) => {
    const { width: x, height: y } = rect
    const allowedMovement = (scale - this.props.initialScale) / scale / 2
    return {
      xMax: x * allowedMovement,
      xMin: -x * allowedMovement,
      yMax: y * allowedMovement,
      yMin: -y * allowedMovement,
    }
  }

  getXYValuesClapmpPipe = ({ xMin, xMax, yMin, yMax }, tug = 0) => ({
    x,
    y,
  }) => ({
    x: this.clampValueWithTug(x, xMin, xMax, tug),
    y: this.clampValueWithTug(y, yMin, yMax, tug),
  })

  resize = (nextRequestedScale = this.nextScale) => {
    const { maximumZoomScale, minimumZoomScale } = this.props
    let nextScale = nextRequestedScale
    if (nextRequestedScale < minimumZoomScale) nextScale = minimumZoomScale
    if (nextRequestedScale > maximumZoomScale) nextScale = maximumZoomScale

    const { width, height } = (this
      .innerContainerRef as any).getBoundingClientRect()
    const currentScale = this.innerContainerScale.get() as number
    const nextRect = {
      width: (width * nextScale) / currentScale,
      height: (height * nextScale) / currentScale,
    }
    const clamp = this.getXYValuesClapmpPipe(
      this.getXYBounds(nextScale, nextRect)
    )
    const currentXY = this.innerContainerXY.get() as any
    const animationDuration =
      (500 * Math.abs(currentScale - nextScale)) / this.props.zoomStep
    tween({
      from: currentXY,
      to: clamp({
        x: (currentXY.x * nextScale) / currentScale,
        y: (currentXY.y * nextScale) / currentScale,
      }),
      duration: animationDuration,
    }).start(this.innerContainerXY)
    tween({
      from: currentScale,
      to: nextScale,
      duration: animationDuration,
    }).start(this.innerContainerScale)

    this.nextScale = nextScale
    this.scale = nextScale
  }

  zoomIn = () => {
    this.nextScale = Math.min(
      this.scale + this.props.zoomStep,
      this.props.maximumZoomScale
    )
    this.resize()
  }
  zoomOut = () => {
    this.nextScale = Math.max(
      this.scale - this.props.zoomStep,
      this.props.minimumZoomScale
    )
    this.resize()
  }

  isRegionSelected = ({ id }: Region) =>
    this.props.selectedRegions.indexOf(id) !== -1

  setSelectedRegions = (ids: string[]) => {
    this.props.onChange(ids)
  }

  toggleSelection = (region: Region) => {
    const { selectedRegions } = this.props
    if (this.isRegionSelected(region)) {
      this.setSelectedRegions(selectedRegions.filter(id => id !== region.id))
    } else {
      this.setSelectedRegions([...selectedRegions, region.id])
    }
  }

  handleMapClick = event => {
    if (this.moved) {
      return
    }
    const eventCoords = clickEventToFloatCoords(event)
    const matchingRegions = selectMatchingRegions(this.regions, eventCoords)
    matchingRegions.forEach(this.toggleSelection)
  }

  render() {
    const {
      style,
      readOnly,
      imageSource,
      imageAlt,
      zoom,
      containerProps,
      clickHandlerProps,
      zoomInButtonProps,
      zoomOutButtonProps,
    } = this.props
    const { imageSize, theme } = this.state

    return (
      <ThemeProvider theme={theme}>
        <Container
          style={style}
          disabled={readOnly}
          ref={this.setContainerRef}
          {...containerProps}
        >
          <ContainerInner ref={this.setInnerContainerRef}>
            <ImageComp src={imageSource} alt={imageAlt} />
            <SvgContainer
              viewBox={`0 0 ${imageSize[0]} ${imageSize[1]}`}
              maskImageSrc={imageSource}
            >
              {this.regions.filter(this.isRegionSelected).map(region => (
                <RegionComp key={region.id} {...region} />
              ))}
              <rect
                width="100%"
                height="100%"
                fill="transparent"
                onClick={this.handleMapClick}
                {...clickHandlerProps}
              />
            </SvgContainer>
          </ContainerInner>
          {zoom && (
            <ZoomContainer>
              <ZoomButton onClick={this.zoomIn} {...zoomInButtonProps}>
                <ZoomIcon
                  src={zoomInIconSource as any}
                  alt=""
                  data-testid="icon-zoom-in"
                />
              </ZoomButton>
              <ZoomIcon
                src={zoomIconSource as any}
                alt=""
                data-testid="icon-zoom"
              />
              <ZoomButton onClick={this.zoomOut} {...zoomOutButtonProps}>
                <ZoomIcon
                  src={zoomOutIconSource as any}
                  alt=""
                  data-testid="icon-zoom-out"
                />
              </ZoomButton>
            </ZoomContainer>
          )}
        </Container>
      </ThemeProvider>
    )
  }
}
