"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Multipass = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../base-service");
var Multipass = /** @class */ (function (_super) {
    tslib_1.__extends(Multipass, _super);
    function Multipass() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.createUser = function (requestProps) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/users";
                        return [4 /*yield*/, this.api.put(url, requestProps)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.updateSelfPin = function (requestProps) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/users/me/pin";
                        return [4 /*yield*/, this.api.put(url, requestProps)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.updateUserPin = function (_a) {
            var auth_id = _a.auth_id, pin = _a.pin;
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var url, response, data;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            url = "/users/".concat(auth_id, "/pin");
                            return [4 /*yield*/, this.api.put(url, pin)];
                        case 1:
                            response = _b.sent();
                            return [4 /*yield*/, this.handleResponse(null, url, response)];
                        case 2:
                            data = (_b.sent()).data;
                            return [2 /*return*/, data];
                    }
                });
            });
        };
        _this.authenticatePin = function (requestProps) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/authenticate/pin";
                        return [4 /*yield*/, this.api.post(url, requestProps)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.getChallenges = function (_a) {
            var auth_id = _a.auth_id;
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var url, response, data;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            url = "/authenticate/".concat(auth_id, "/challenge");
                            return [4 /*yield*/, this.api.get(url)];
                        case 1:
                            response = _b.sent();
                            return [4 /*yield*/, this.handleResponse(null, url, response)];
                        case 2:
                            data = (_b.sent()).data;
                            return [2 /*return*/, data];
                    }
                });
            });
        };
        return _this;
    }
    return Multipass;
}(base_service_1.BaseService));
exports.Multipass = Multipass;
