"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppointmentStatus = void 0;
var AppointmentStatus;
(function (AppointmentStatus) {
    AppointmentStatus["REQUESTED"] = "requested";
    AppointmentStatus["PROVIDER_REQUESTED"] = "provider_requested";
    AppointmentStatus["CONFIRMED"] = "confirmed";
    AppointmentStatus["ACTION_REQUIRED"] = "action_required";
    AppointmentStatus["NO_SHOW"] = "no_show";
    AppointmentStatus["ARRIVED"] = "arrived";
    AppointmentStatus["IN_ROOM"] = "in_room";
    AppointmentStatus["BEING_SEEN"] = "being_seen";
    AppointmentStatus["VISIT_COMPLETED"] = "visit_completed";
    AppointmentStatus["NOTE_SIGNED"] = "note_signed";
    AppointmentStatus["CANCELLED"] = "cancelled";
    AppointmentStatus["REJECTED"] = "rejected";
    AppointmentStatus["BOOKED"] = "booked";
    AppointmentStatus["RESCHEDULED"] = "rescheduled";
    AppointmentStatus["TRIAGE"] = "triage";
})(AppointmentStatus = exports.AppointmentStatus || (exports.AppointmentStatus = {}));
