"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Service = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../../base-service");
var utils_1 = require("../../utils");
var model_1 = require("../trackers/model");
// Across all (or most) endpoints the API supports pagination -> the assumption is that we don't actually
// need to paginate, so rather than returning unnecessary data, returning just the items response.
// (And the actual API call would be made with a large perPage value.)
var Service = /** @class */ (function (_super) {
    tslib_1.__extends(Service, _super);
    function Service() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.API_BASE = '/api/user/v4';
        // GET /my/trackers
        _this.getUsersTrackers = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, items;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "".concat(this.API_BASE, "/my/trackers");
                        return [4 /*yield*/, this.api.get(url, tslib_1.__assign(tslib_1.__assign({}, (0, utils_1.sanitizeQueryParams)({ lang: params === null || params === void 0 ? void 0 : params.lang, status: params === null || params === void 0 ? void 0 : params.status })), { page: 0, page_size: 100 }))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        items = (_a.sent()).items;
                        return [2 /*return*/, items];
                }
            });
        }); };
        // POST /my/trackers/{tracker_name}
        _this.createUsersTrackerConnection = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var query, url, response, connection_id;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('createUsersTrackerConnection()', params, ['trackerName']);
                        query = {};
                        if (params.trackerName === model_1.TrackerName.AppleHealthkit) {
                            (0, utils_1.ensureRequiredParams)('createUsersTrackerConnection()', params, ['id']);
                            query.id = params.id;
                        }
                        url = "".concat(this.API_BASE, "/my/trackers/").concat(params.trackerName);
                        return [4 /*yield*/, this.api.post(url, {}, { params: query })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        connection_id = (_a.sent()).connection_id;
                        return [2 /*return*/, connection_id];
                }
            });
        }); };
        // PUT /my/trackers/{tracker_name}
        _this.updateUsersTrackerStatus = function (_a) {
            var trackerName = _a.trackerName, trackerStatusUpdate = _a.trackerStatusUpdate;
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var url, response;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            (0, utils_1.ensureRequiredParams)('updateUsersTrackerStatus', { trackerName: trackerName, trackerStatusUpdate: trackerStatusUpdate }, [
                                'trackerName',
                                'trackerStatusUpdate',
                            ]);
                            url = "".concat(this.API_BASE, "/my/trackers/").concat(trackerName);
                            return [4 /*yield*/, this.api.put(url, trackerStatusUpdate)];
                        case 1:
                            response = _b.sent();
                            return [2 /*return*/, this.handleResponse(null, url, response)];
                    }
                });
            });
        };
        // PUT /my/profile
        _this.updateUserProfile = function (userUpdate) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('updateUserProfile()', userUpdate, []);
                        url = "".concat(this.API_BASE, "/my/profile");
                        return [4 /*yield*/, this.api.put(url, userUpdate)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, response)];
                }
            });
        }); };
        // DELETE /my/trackers/{tracker_name}
        _this.deleteUsersTrackerConnection = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('deleteUsersTrackerConnection()', params, ['trackerName']);
                        url = "".concat(this.API_BASE, "/my/trackers/").concat(params.trackerName);
                        return [4 /*yield*/, this.api.delete(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        // POST /my/trackers/{tracker_name}/metrics
        _this.addUsersTrackerMetric = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('addUsersTrackerMetric()', params, [
                            'trackerName',
                            'fromDate',
                        ]);
                        url = "".concat(this.API_BASE, "/my/trackers/").concat(params.trackerName, "/metrics");
                        return [4 /*yield*/, this.api.post(url, params.trackerActivities, {
                                params: {
                                    from_date: params.fromDate,
                                    to_date: params.toDate,
                                    sync_time: params.syncTime,
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getChallenges = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, challenges, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "".concat(this.API_BASE, "/my/challenges");
                        return [4 /*yield*/, this.api.get(url, tslib_1.__assign(tslib_1.__assign({}, (0, utils_1.sanitizeQueryParams)({
                                status: params === null || params === void 0 ? void 0 : params.status,
                                type: params === null || params === void 0 ? void 0 : params.type,
                                tag: params === null || params === void 0 ? void 0 : params.tag,
                                from_date: params === null || params === void 0 ? void 0 : params.from_date,
                                to_date: params === null || params === void 0 ? void 0 : params.to_date,
                                sort: params === null || params === void 0 ? void 0 : params.sort,
                                lang: params === null || params === void 0 ? void 0 : params.lang,
                            })), { page: 0, page_size: 100 }))];
                    case 1:
                        challenges = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, challenges)];
                    case 2:
                        response = (_a.sent());
                        return [2 /*return*/, response.items];
                }
            });
        }); };
        _this.getChallenge = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, utils_1.ensureRequiredParams)('getChallenge()', params, 'challengeId');
                        url = "".concat(this.API_BASE, "/my/challenges/").concat(params.challengeId);
                        return [4 /*yield*/, this.api.get(url, (0, utils_1.sanitizeQueryParams)({ lang: params.lang }))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        return _this;
    }
    return Service;
}(base_service_1.BaseService));
exports.Service = Service;
