"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackerStatus = exports.SdkTrackerName = exports.TrackerName = void 0;
var TrackerName;
(function (TrackerName) {
    TrackerName["Fitbit"] = "fitbit";
    TrackerName["SamsungHealth"] = "samsung_health";
    TrackerName["GoogleFit"] = "google_fit";
    TrackerName["AppleHealthkit"] = "apple_healthkit";
    TrackerName["Garmin"] = "garmin";
    TrackerName["Strava"] = "strava";
    TrackerName["Withings"] = "withings";
    TrackerName["PhoneTracker"] = "phone_tracker";
})(TrackerName = exports.TrackerName || (exports.TrackerName = {}));
var SdkTrackerName;
(function (SdkTrackerName) {
    SdkTrackerName["AppleHealthKit"] = "apple_healthkit";
    SdkTrackerName["SamsungHealth"] = "samsung_health";
    SdkTrackerName["PhoneTracker"] = "phone_tracker";
})(SdkTrackerName = exports.SdkTrackerName || (exports.SdkTrackerName = {}));
var TrackerStatus;
(function (TrackerStatus) {
    TrackerStatus["New"] = "new";
    TrackerStatus["Connected"] = "connected";
    TrackerStatus["Broken"] = "broken";
    TrackerStatus["Disconnected"] = "disconnected";
})(TrackerStatus = exports.TrackerStatus || (exports.TrackerStatus = {}));
