"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DEPRESSION_MODULE;
(function (DEPRESSION_MODULE) {
    DEPRESSION_MODULE["DEPRESSION_INFO"] = "depression_info";
    DEPRESSION_MODULE["DEPRESSION_CBT"] = "depression_cbt";
    DEPRESSION_MODULE["DEPRESSION_CBT_TAT"] = "depression_cbt_tat";
    DEPRESSION_MODULE["DEPRESSION_CBT_CYT"] = "depression_cbt_cyt";
    DEPRESSION_MODULE["DEPRESSION_CBT_CYB"] = "depression_cbt_cyb";
    DEPRESSION_MODULE["DEPRESSION_IPT"] = "depression_ipt";
    DEPRESSION_MODULE["DEPRESSION_IPT_CHANGES"] = "depression_ipt_changes";
    DEPRESSION_MODULE["DEPRESSION_IPT_DISPUTES"] = "depression_ipt_disputes";
    DEPRESSION_MODULE["DEPRESSION_IPT_GRIEF"] = "depression_ipt_grief";
    DEPRESSION_MODULE["DEPRESSION_IPT_RELATIONSHIPS"] = "depression_ipt_relationships";
    DEPRESSION_MODULE["DEPRESSION_RELAXATION"] = "depression_relaxation";
    DEPRESSION_MODULE["DEPRESSION_PHYSICALACTIVITY"] = "depression_physicalactivity";
    DEPRESSION_MODULE["DEPRESSION_PHYSICALACTIVITY_MOD1"] = "depression_physicalactivity_mod1";
    DEPRESSION_MODULE["DEPRESSION_PHYSICALACTIVITY_MOD2"] = "depression_physicalactivity_mod2";
    DEPRESSION_MODULE["DEPRESSION_PHYSICALACTIVITY_MOD3"] = "depression_physicalactivity_mod3";
    DEPRESSION_MODULE["DEPRESSION_PHYSICALACTIVITY_MOD4"] = "depression_physicalactivity_mod4";
    DEPRESSION_MODULE["DEPRESSION_PHYSICALACTIVITY_MOD5"] = "depression_physicalactivity_mod5";
    DEPRESSION_MODULE["DEPRESSION_PROBLEMSOLVING"] = "depression_problemsolving";
})(DEPRESSION_MODULE || (DEPRESSION_MODULE = {}));
var GAD_MODULE;
(function (GAD_MODULE) {
    GAD_MODULE["GAD_INFO"] = "gad_info";
    GAD_MODULE["GAD_CBT"] = "gad_cbt";
    GAD_MODULE["GAD_CBT_MEANING"] = "gad_cbt_meaning";
    GAD_MODULE["GAD_CBT_ACT"] = "gad_cbt_act";
    GAD_MODULE["GAD_CBT_CONTENT"] = "gad_cbt_content";
    GAD_MODULE["GAD_CBT_FEELINGS"] = "gad_cbt_feelings";
    GAD_MODULE["GAD_RELAXATION"] = "gad_relaxation";
    GAD_MODULE["GAD_PHYSICALACTIVITY"] = "gad_physicalactivity";
    GAD_MODULE["GAD_PHYSICALACTIVITY_MOD1"] = "gad_physicalactivity_mod1";
    GAD_MODULE["GAD_PHYSICALACTIVITY_MOD2"] = "gad_physicalactivity_mod2";
    GAD_MODULE["GAD_PHYSICALACTIVITY_MOD3"] = "gad_physicalactivity_mod3";
    GAD_MODULE["GAD_PHYSICALACTIVITY_MOD4"] = "gad_physicalactivity_mod4";
    GAD_MODULE["GAD_PHYSICALACTIVITY_MOD5"] = "gad_physicalactivity_mod5";
})(GAD_MODULE || (GAD_MODULE = {}));
var LOSS_MODULE;
(function (LOSS_MODULE) {
    LOSS_MODULE["LOSS_INFO"] = "loss_info";
    LOSS_MODULE["LOSS_SELF_HELP"] = "loss_self_help";
    LOSS_MODULE["LOSS_CYT"] = "loss_cyt";
    LOSS_MODULE["LOSS_WORKBOOK"] = "loss_workbook";
})(LOSS_MODULE || (LOSS_MODULE = {}));
var DIVORCE_MODULE;
(function (DIVORCE_MODULE) {
    DIVORCE_MODULE["DIVORCE_INFO"] = "divorce_info";
    DIVORCE_MODULE["DIVORCE_PROBLEMSOLVING"] = "divorce_problemsolving";
    DIVORCE_MODULE["DIVORCE_CBT"] = "divorce_cbt";
    DIVORCE_MODULE["DIVORCE_ANGER"] = "divorce_anger";
    DIVORCE_MODULE["DIVORCE_PHYSICALACTIVITY"] = "divorce_physicalactivity";
    DIVORCE_MODULE["DIVORCE_PHYSICALACTIVITY_MOD1"] = "divorce_physicalactivity_mod1";
    DIVORCE_MODULE["DIVORCE_PHYSICALACTIVITY_MOD2"] = "divorce_physicalactivity_mod2";
    DIVORCE_MODULE["DIVORCE_PHYSICALACTIVITY_MOD3"] = "divorce_physicalactivity_mod3";
    DIVORCE_MODULE["DIVORCE_PHYSICALACTIVITY_MOD4"] = "divorce_physicalactivity_mod4";
    DIVORCE_MODULE["DIVORCE_PHYSICALACTIVITY_MOD5"] = "divorce_physicalactivity_mod5";
    DIVORCE_MODULE["DIVORCE_WORKBOOK"] = "divorce_workbook";
})(DIVORCE_MODULE || (DIVORCE_MODULE = {}));
var SOCIALANXIETY_MODULE;
(function (SOCIALANXIETY_MODULE) {
    SOCIALANXIETY_MODULE["SOCIALANXIETY_INFO"] = "socialanxiety_info";
    SOCIALANXIETY_MODULE["SOCIALANXIETY_MYT"] = "socialanxiety_myt";
    SOCIALANXIETY_MODULE["SOCIALANXIETY_ATTENTION"] = "socialanxiety_attention";
    SOCIALANXIETY_MODULE["SOCIALANXIETY_RELAXATION"] = "socialanxiety_relaxation";
    SOCIALANXIETY_MODULE["SOCIALANXIETY_EXPOSURE"] = "socialanxiety_exposure";
    SOCIALANXIETY_MODULE["SOCIALANXIETY_SOCIALSKILLS"] = "socialanxiety_socialskills";
    SOCIALANXIETY_MODULE["SOCIALANXIETY_WORKBOOK"] = "socialanxiety_workbook";
})(SOCIALANXIETY_MODULE || (SOCIALANXIETY_MODULE = {}));
