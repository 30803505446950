"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemberIdType = exports.EmailPreference = exports.BillingMethod = exports.ChargeStatus = exports.V2 = void 0;
var tslib_1 = require("tslib");
exports.V2 = tslib_1.__importStar(require("./v2/model"));
var ChargeStatus;
(function (ChargeStatus) {
    ChargeStatus["PENDING"] = "pending";
    ChargeStatus["PAID"] = "paid";
    ChargeStatus["DISPUTED"] = "disputed";
    ChargeStatus["REFUNDED"] = "refunded";
    ChargeStatus["CANCELLED"] = "cancelled";
})(ChargeStatus = exports.ChargeStatus || (exports.ChargeStatus = {}));
var BillingMethod;
(function (BillingMethod) {
    BillingMethod["CREDIT_CARD"] = "credit_card";
    BillingMethod["CHEQUE"] = "cheque";
    BillingMethod["PRE_AUTHORIZED_DEBIT"] = "pre_authorized_debit";
    BillingMethod["ELECTRONIC_FUNDS_TRANSFER"] = "electronic_funds_transfer";
})(BillingMethod = exports.BillingMethod || (exports.BillingMethod = {}));
var EmailPreference;
(function (EmailPreference) {
    EmailPreference["BILINGUAL_ENGLISH_FRENCH"] = "bilingual-english-french";
    EmailPreference["BILINGUAL_FRENCH_ENGLISH"] = "bilingual-french-english";
    EmailPreference["ENGLISH"] = "english";
    EmailPreference["FRENCH"] = "french";
})(EmailPreference = exports.EmailPreference || (exports.EmailPreference = {}));
var MemberIdType;
(function (MemberIdType) {
    MemberIdType["EMAIL"] = "email";
    MemberIdType["EMPLOYEE_ID"] = "employee_id";
})(MemberIdType = exports.MemberIdType || (exports.MemberIdType = {}));
