"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CriteriaToAccessMentalHealthConsults = exports.NumberOfSessions = exports.FamilyAccess = void 0;
// FIXME: API could benefit to be clearer with the intent
// NONE = current user only
var FamilyAccess;
(function (FamilyAccess) {
    FamilyAccess["NONE"] = "none";
    FamilyAccess["SPOUSE_AND_CHILDREN"] = "spouse_and_children";
})(FamilyAccess = exports.FamilyAccess || (exports.FamilyAccess = {}));
var NumberOfSessions;
(function (NumberOfSessions) {
    NumberOfSessions["MORE_THAN_ONE_WHEN_MEDICALLY_INDICATED"] = "more_than_one_when_medically_indicated";
    NumberOfSessions["UNLIMITED_SESSIONS"] = "unlimited_sessions";
    NumberOfSessions["UP_TO_1_SESSION"] = "up_to_1_session";
    NumberOfSessions["UP_TO_2_SESSIONS"] = "up_to_2_sessions";
    NumberOfSessions["UP_TO_4_SESSIONS"] = "up_to_4_sessions";
    NumberOfSessions["UP_TO_6_SESSIONS"] = "up_to_6_sessions";
    NumberOfSessions["UP_TO_8_SESSIONS"] = "up_to_8_sessions";
})(NumberOfSessions = exports.NumberOfSessions || (exports.NumberOfSessions = {}));
var CriteriaToAccessMentalHealthConsults;
(function (CriteriaToAccessMentalHealthConsults) {
    CriteriaToAccessMentalHealthConsults["NO_MD_OR_PRESCRIPTION_RENEWAL_OR_EXISTING_DIAGNOSIS"] = "no_md_or_prescription_renewal_or_existing_diagnosis";
    CriteriaToAccessMentalHealthConsults["NONE"] = "none";
})(CriteriaToAccessMentalHealthConsults = exports.CriteriaToAccessMentalHealthConsults || (exports.CriteriaToAccessMentalHealthConsults = {}));
