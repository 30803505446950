"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScribeV2 = exports.ScribeV2Types = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../../base-service");
exports.ScribeV2Types = tslib_1.__importStar(require("./model"));
var ScribeV2 = /** @class */ (function (_super) {
    tslib_1.__extends(ScribeV2, _super);
    function ScribeV2() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getEligibilityRecords = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/eligibility_records";
                        return [4 /*yield*/, this.api.get(url, params)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.getUsers = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = '/v2/users';
                        return [4 /*yield*/, this.api.get(url, params)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.getUser = function (userId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/users/".concat(userId);
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.getServices = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/services";
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        /**
         * @see https://docs.dev.dialoguecorp.com/scribe/v2.html#operation/Users_list_eligible_intervals
         */
        _this.getUserEligibleIntervals = function (userId, options) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/users/".concat(userId, "/eligible_intervals");
                        return [4 /*yield*/, this.api.get(url, options)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.validateOrganizationEnrolmentCode = function (accessCode) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/organizations/validate_enrolment_code";
                        return [4 /*yield*/, this.api.post(url, {
                                organization_enrolment_code: accessCode,
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, Object.keys(data).length === 0 ? false : true];
                }
            });
        }); };
        return _this;
    }
    return ScribeV2;
}(base_service_1.BaseService));
exports.ScribeV2 = ScribeV2;
