"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Emerald = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../base-service");
var v2_1 = require("./v2");
var Emerald = /** @class */ (function (_super) {
    tslib_1.__extends(Emerald, _super);
    function Emerald() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getPatient = function (patientId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/patients/".concat(patientId);
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.getAppointmentTypes = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/appointments/appointment_types";
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.linkOrCreatePatient = function (patientInfo) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/patients";
                        return [4 /*yield*/, this.api.post(url, patientInfo)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.updatePatient = function (patientId, patientData) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/patients/".concat(patientId);
                        return [4 /*yield*/, this.api.put(url, patientData)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.getAvailableAppointments = function (getAvailabilitiesRequest) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = '/appointments/availabilities';
                        return [4 /*yield*/, this.api.get(url, getAvailabilitiesRequest)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.bookAppointment = function (bookAppointmentRequest) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = '/appointments';
                        return [4 /*yield*/, this.api.post(url, bookAppointmentRequest)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.handleResponse(null, url, res)];
                }
            });
        }); };
        _this.getPatientDocuments = function (dialogueId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/patients/".concat(dialogueId, "/documents");
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.getReferral = function (dialogueId, documentId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/patients/".concat(dialogueId, "/documents/").concat(documentId);
                        return [4 /*yield*/, this.api.get(url, undefined, {
                                responseType: 'blob',
                                timeout: 20000,
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.getAppointments = function (dialogueId, limit, offset) {
            if (limit === void 0) { limit = 5; }
            if (offset === void 0) { offset = 0; }
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var url, params, response;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            url = "/patients/".concat(dialogueId, "/appointments");
                            params = { limit: limit, offset: offset };
                            return [4 /*yield*/, this.api.get(url, params)];
                        case 1:
                            response = _a.sent();
                            return [4 /*yield*/, this.handleResponse(null, url, response)];
                        case 2: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        };
        _this.getScheduleTypes = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/schedule/types";
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        return _this;
    }
    Emerald.V2 = v2_1.EmeraldV2;
    return Emerald;
}(base_service_1.BaseService));
exports.Emerald = Emerald;
