"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentType = exports.ApptType = exports.V2 = void 0;
var tslib_1 = require("tslib");
exports.V2 = tslib_1.__importStar(require("./v2/model"));
var ApptType;
(function (ApptType) {
    ApptType["FOLLOW_UP"] = "follow_up";
    ApptType["APPT"] = "appt";
    ApptType["UNKNOWN"] = "unknown";
})(ApptType = exports.ApptType || (exports.ApptType = {}));
var DocumentType;
(function (DocumentType) {
    DocumentType["FILE"] = "file";
    DocumentType["REFERRAL"] = "referral";
})(DocumentType = exports.DocumentType || (exports.DocumentType = {}));
