"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageType = exports.ParticipantType = void 0;
var ParticipantType;
(function (ParticipantType) {
    ParticipantType["MEMBER"] = "member";
    ParticipantType["PRACTITIONER"] = "practitioner";
    ParticipantType["CLINIC"] = "clinic";
})(ParticipantType = exports.ParticipantType || (exports.ParticipantType = {}));
var MessageType;
(function (MessageType) {
    MessageType["TEXT"] = "text";
    MessageType["CALL"] = "call";
    MessageType["CAREPLAN"] = "careplan";
    MessageType["CHARGE"] = "charge";
    MessageType["ATTACHMENT"] = "attachment";
    MessageType["QUESTION"] = "question";
    MessageType["ANSWER"] = "answer";
})(MessageType = exports.MessageType || (exports.MessageType = {}));
