"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StructuredMedicationComputedStatus = exports.StructuredMedicationStatus = exports.PropertyTypes = exports.QuestionnaireType = exports.TaskPriority = exports.TaskStatus = exports.GenderIdentity = exports.Sex = exports.EncounterStatus = exports.PractitionerStatus = exports.BotStatus = exports.ReminderStatus = exports.CommunicationMethods = exports.EpisodeState = void 0;
var EpisodeState;
(function (EpisodeState) {
    EpisodeState["ACTIVE"] = "active";
    EpisodeState["RESOLVED"] = "resolved";
    EpisodeState["PENDING"] = "pending";
})(EpisodeState = exports.EpisodeState || (exports.EpisodeState = {}));
var CommunicationMethods;
(function (CommunicationMethods) {
    CommunicationMethods["audio"] = "audio";
    CommunicationMethods["video"] = "video";
    CommunicationMethods["chat"] = "chat";
    CommunicationMethods["ccq"] = "ccq";
})(CommunicationMethods = exports.CommunicationMethods || (exports.CommunicationMethods = {}));
var ReminderStatus;
(function (ReminderStatus) {
    ReminderStatus["COMPLETED"] = "completed";
    ReminderStatus["INCOMPLETE"] = "incomplete";
    ReminderStatus["CANCELLED"] = "cancelled";
})(ReminderStatus = exports.ReminderStatus || (exports.ReminderStatus = {}));
var BotStatus;
(function (BotStatus) {
    BotStatus["ACTIVE"] = "active";
    BotStatus["PAUSED"] = "paused";
    BotStatus["INACTIVE"] = "inactive";
})(BotStatus = exports.BotStatus || (exports.BotStatus = {}));
var PractitionerStatus;
(function (PractitionerStatus) {
    PractitionerStatus["NONE"] = "no_status";
    PractitionerStatus["READY"] = "ready";
    PractitionerStatus["BREAK"] = "break";
    PractitionerStatus["ADMIN"] = "admin";
    PractitionerStatus["AV_ENCOUNTER"] = "on_audio_video_call";
    PractitionerStatus["CHAT_ENCOUNTER"] = "on_chat";
})(PractitionerStatus = exports.PractitionerStatus || (exports.PractitionerStatus = {}));
var EncounterStatus;
(function (EncounterStatus) {
    EncounterStatus["PLANNED"] = "planned";
    EncounterStatus["CREATED"] = "created";
    EncounterStatus["REQUESTED"] = "requested";
    EncounterStatus["INPROGRESS"] = "inProgress";
    EncounterStatus["REVIEW"] = "review";
    EncounterStatus["COMPLETED"] = "completed";
    EncounterStatus["CANCELLED"] = "cancelled";
})(EncounterStatus = exports.EncounterStatus || (exports.EncounterStatus = {}));
var Sex;
(function (Sex) {
    Sex["FEMALE"] = "F";
    Sex["MALE"] = "M";
})(Sex = exports.Sex || (exports.Sex = {}));
var GenderIdentity;
(function (GenderIdentity) {
    GenderIdentity["FEMALE"] = "female";
    GenderIdentity["I_DONT_KNOW"] = "i_dont_know";
    GenderIdentity["MALE"] = "male";
    GenderIdentity["NON_BINARY"] = "non_binary";
    GenderIdentity["PREFER_NOT_TO_DISCLOSE"] = "prefer_not_to_disclose";
    GenderIdentity["PREFER_TO_SELF_IDENTIFY"] = "prefer_to_self_identify";
    GenderIdentity["TWO_SPIRIT"] = "two_spirit";
})(GenderIdentity = exports.GenderIdentity || (exports.GenderIdentity = {}));
var TaskStatus;
(function (TaskStatus) {
    TaskStatus["TO_DO"] = "TO_DO";
    TaskStatus["DONE"] = "DONE";
})(TaskStatus = exports.TaskStatus || (exports.TaskStatus = {}));
var TaskPriority;
(function (TaskPriority) {
    TaskPriority[TaskPriority["CRITICAL"] = 1] = "CRITICAL";
    TaskPriority[TaskPriority["HIGH"] = 2] = "HIGH";
    TaskPriority[TaskPriority["NORMAL"] = 3] = "NORMAL";
})(TaskPriority = exports.TaskPriority || (exports.TaskPriority = {}));
var QuestionnaireType;
(function (QuestionnaireType) {
    QuestionnaireType["GAD7"] = "GAD7";
    QuestionnaireType["PHQ4"] = "PHQ4";
    QuestionnaireType["PHQ9"] = "PHQ9";
    QuestionnaireType["WBI"] = "WBI";
    QuestionnaireType["WSAS"] = "WSAS";
    QuestionnaireType["STRESS_ASSESSMENT"] = "STRESS_ASSESSMENT";
    QuestionnaireType["CAGE_AID"] = "CAGE-AID";
})(QuestionnaireType = exports.QuestionnaireType || (exports.QuestionnaireType = {}));
var PropertyTypes;
(function (PropertyTypes) {
    PropertyTypes["QUESTIONNAIRE"] = "questionnaire";
    PropertyTypes["ALLERGIES"] = "allergies";
    PropertyTypes["MEDICATIONS"] = "medications";
    PropertyTypes["MEDICAL_HISTORY"] = "medical_history";
})(PropertyTypes = exports.PropertyTypes || (exports.PropertyTypes = {}));
var StructuredMedicationStatus;
(function (StructuredMedicationStatus) {
    StructuredMedicationStatus["ACTIVE"] = "active";
    StructuredMedicationStatus["INACTIVE"] = "inactive";
    StructuredMedicationStatus["STOPPED"] = "stopped";
    StructuredMedicationStatus["CANCELLED"] = "cancelled";
})(StructuredMedicationStatus = exports.StructuredMedicationStatus || (exports.StructuredMedicationStatus = {}));
// TODO: remove this once computed_status has been cleaned up in CP
var StructuredMedicationComputedStatus;
(function (StructuredMedicationComputedStatus) {
    StructuredMedicationComputedStatus["ACTIVE"] = "active";
})(StructuredMedicationComputedStatus = exports.StructuredMedicationComputedStatus || (exports.StructuredMedicationComputedStatus = {}));
