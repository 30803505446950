"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmeraldV2 = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../../base-service");
var EmeraldV2 = /** @class */ (function (_super) {
    tslib_1.__extends(EmeraldV2, _super);
    function EmeraldV2() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getPractitionerWeekSchedule = function (practitionerId, selected_date) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, params, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/practitioner/".concat(practitionerId, "/schedule/week");
                        params = { selected_date: selected_date };
                        return [4 /*yield*/, this.api.get(url, params)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.getScheduleGroups = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/schedule_groups";
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.getPatientAppointments = function (patientId, status, offset, limit) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, params, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/patient/".concat(patientId, "/appointments");
                        params = { status: status, offset: offset, limit: limit };
                        return [4 /*yield*/, this.api.get(url, params)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.getGroupSchedule = function (groupId, selectedDate) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, params, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/schedule/group/".concat(groupId);
                        params = { date: selectedDate };
                        return [4 /*yield*/, this.api.get(url, params)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.getPharmacy = function (pharmacyId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "v2/pharmacy/".concat(pharmacyId);
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.getPatientNotes = function (patientId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/patient/".concat(patientId, "/notes");
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.createPatientNote = function (patientId, note) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/patient/".concat(patientId, "/notes");
                        return [4 /*yield*/, this.api.post(url, note)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.updatePatientNote = function (patientId, noteId, note) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/patient/".concat(patientId, "/notes/").concat(noteId);
                        return [4 /*yield*/, this.api.put(url, note)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.deletePatientNote = function (patientId, noteId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/patient/".concat(patientId, "/notes/").concat(noteId);
                        return [4 /*yield*/, this.api.delete(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.getPatientMedicalProfile = function (patientId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "v2/patient/".concat(patientId, "/medical-profile");
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.updatePatientMedicalHistory = function (patientId, medicalHistory) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "v2/patient/".concat(patientId, "/medical_history");
                        return [4 /*yield*/, this.api.put(url, medicalHistory)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.updatePatientMedications = function (patientId, medications) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "v2/patient/".concat(patientId, "/medications");
                        return [4 /*yield*/, this.api.put(url, medications)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.updatePatientAllergies = function (patientId, allergies) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "v2/patient/".concat(patientId, "/allergies");
                        return [4 /*yield*/, this.api.put(url, allergies)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.updateAppointmentStatus = function (appointmentId, updateAppointmentStatusAttributes) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "v2/appointment/".concat(appointmentId, "/status");
                        return [4 /*yield*/, this.api.post(url, updateAppointmentStatusAttributes)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.getPractitionerMapping = function (practitionerId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "v2/practitioner/".concat(practitionerId, "/mapping");
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.updatePractitionerMapping = function (practitionerId, email) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "v2/practitioner/mapping";
                        return [4 /*yield*/, this.api.post(url, {
                                practitioners: [
                                    {
                                        dia_id: practitionerId,
                                        email: email,
                                    },
                                ],
                            })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data === null || data === void 0 ? void 0 : data[0]];
                }
            });
        }); };
        _this.createAppointment = function (appointment) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/appointment";
                        return [4 /*yield*/, this.api.post(url, appointment)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.editAppointment = function (appointmentId, updateAppointment) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/appointment/".concat(appointmentId);
                        return [4 /*yield*/, this.api.patch(url, updateAppointment)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.createDraftAppointment = function (newDraftAppointment) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/appointment/draft";
                        return [4 /*yield*/, this.api.post(url, newDraftAppointment)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.updateDraftAppointment = function (draftAppointmentId, newDraftAppointment) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/appointment/draft/".concat(draftAppointmentId);
                        return [4 /*yield*/, this.api.put(url, newDraftAppointment)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.deleteDraftAppointment = function (draftAppointmentId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/appointment/draft/".concat(draftAppointmentId);
                        return [4 /*yield*/, this.api.delete(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.uploadDocCenterFile = function (patientId, documentId, description) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, response, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/v2/patient/".concat(patientId, "/doc-center/").concat(documentId, "/uploadfile");
                        return [4 /*yield*/, this.api.post(url, {
                                description: description,
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, response)];
                    case 2:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        return _this;
    }
    return EmeraldV2;
}(base_service_1.BaseService));
exports.EmeraldV2 = EmeraldV2;
