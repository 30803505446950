"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sort = exports.MetricName = exports.ActivityName = exports.ChallengeType = exports.ChallengeStatus = void 0;
var ChallengeStatus;
(function (ChallengeStatus) {
    ChallengeStatus["UPCOMING"] = "upcoming";
    ChallengeStatus["ONGOING"] = "ongoing";
    ChallengeStatus["COMPLETE"] = "complete";
})(ChallengeStatus = exports.ChallengeStatus || (exports.ChallengeStatus = {}));
var ChallengeType;
(function (ChallengeType) {
    ChallengeType["INDIVIDUAL"] = "individual";
    ChallengeType["TEAM"] = "team";
})(ChallengeType = exports.ChallengeType || (exports.ChallengeType = {}));
var ActivityName;
(function (ActivityName) {
    ActivityName["CYCLING"] = "cycling";
    ActivityName["RUNNING"] = "running";
    ActivityName["SWIMMING"] = "swimming";
    ActivityName["WALKING"] = "walking";
    ActivityName["STEPS"] = "steps";
})(ActivityName = exports.ActivityName || (exports.ActivityName = {}));
var MetricName;
(function (MetricName) {
    MetricName["DURATION"] = "duration";
    MetricName["DISTANCE"] = "distance";
    MetricName["CALORIES"] = "calories";
    MetricName["STEPS"] = "steps";
})(MetricName = exports.MetricName || (exports.MetricName = {}));
var Sort;
(function (Sort) {
    Sort["START_DATE"] = "start_date";
    Sort["START_DATE_ASC"] = "+start_date";
    Sort["START_DATE_DESC"] = "-start_date";
    Sort["END_DATE"] = "end_date";
    Sort["END_DATE_ASC"] = "+end_date";
    Sort["END_DATE_DESC"] = "-end_date";
    Sort["ID"] = "id";
    Sort["ID_ASC"] = "+id";
    Sort["ID_DESC"] = "-id";
    Sort["STATUS"] = "status";
    Sort["STATUS_ASC"] = "+status";
    Sort["STATUS_DESC"] = "-status";
})(Sort = exports.Sort || (exports.Sort = {}));
