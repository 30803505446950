"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MissingRequiredParamError = exports.ApiRequestError = void 0;
var tslib_1 = require("tslib");
var CUSTOM_ERROR_MSGS = {
    /**
     * 0 - Error codes are usually related to failed
     * pre-flight requests, which are most often caused
     * CORS issues, or by a bad gateway on the requested
     * resource.
     */
    0: '0 - Error reaching our services.',
};
/**
 * Custom error class which allows for additional
 * HTTP request related properties to be propagated
 * up the stack trace.
 */
var ApiRequestError = /** @class */ (function (_super) {
    tslib_1.__extends(ApiRequestError, _super);
    function ApiRequestError(code, message, url, additionalInfo) {
        var _this = _super.call(this, message) || this;
        _this.code = code;
        _this.message = message;
        _this.url = url;
        _this.additionalInfo = additionalInfo;
        _this.name = 'ApiRequestError';
        Object.setPrototypeOf(_this, ApiRequestError.prototype);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(_this, ApiRequestError);
        }
        if (!message && code !== undefined)
            _this.message = CUSTOM_ERROR_MSGS[code] || message;
        return _this;
    }
    return ApiRequestError;
}(Error));
exports.ApiRequestError = ApiRequestError;
/**
 * Custom error class for missing params
 */
var MissingRequiredParamError = /** @class */ (function (_super) {
    tslib_1.__extends(MissingRequiredParamError, _super);
    function MissingRequiredParamError(paramName, functionName, message) {
        var _this = _super.call(this) || this;
        _this.name = 'RequiredParamMissingError';
        Object.setPrototypeOf(_this, MissingRequiredParamError.prototype);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(_this, MissingRequiredParamError);
        }
        _this.message = message || "".concat(functionName, " requires param ").concat(paramName);
        return _this;
    }
    return MissingRequiredParamError;
}(Error));
exports.MissingRequiredParamError = MissingRequiredParamError;
