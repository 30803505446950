"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serializeQueryParams = void 0;
var serializeQueryParams = function (params) {
    var query = new URLSearchParams();
    Object.entries(params).forEach(function (_a) {
        var k = _a[0], v = _a[1];
        if (Array.isArray(v)) {
            // Turn array into repeating keys
            v.forEach(function (vv) { return query.append(k, vv); });
        }
        else if (v != null) {
            // Ignore any null or undefined values
            query.append(k, v);
        }
    });
    return query;
};
exports.serializeQueryParams = serializeQueryParams;
