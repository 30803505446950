"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHH = void 0;
var tslib_1 = require("tslib");
var base_service_1 = require("../base-service");
var EHH = /** @class */ (function (_super) {
    tslib_1.__extends(EHH, _super);
    function EHH() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getMemberProfile = function (memberId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/api/".concat(EHH.version, "/members/").concat(memberId, "/profile");
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.handleResponse(null, url, res)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        return _this;
    }
    EHH.version = 'v1';
    return EHH;
}(base_service_1.BaseService));
exports.EHH = EHH;
